<template>
    <div>
        <div class="row">
            <div class="fixwidth hrspacebetween">
                <el-autocomplete
                    class="inline-input fullwidth bold padding2px"
                    v-model="project.projectname"
                    popper-class="morewidth-autocomplete-project"
                    :fetch-suggestions="(query, done)=>{return searchProject(query, done,training)}"
                    @select="selectProject"
                    value-key="projectname"
                    :disabled="setdisable('project')"
                    :placeholder="projectplaceholder == null ? 'Please select Project' : projectplaceholder">
                    <el-button slot="suffix" @click="closeproject" type="text" size="mini">&times;</el-button>
                    <template slot-scope="{ item }">
                        <span style="float: left">{{ item.projectname }}</span>
                        <span style="float: right">{{ item.accessedon | dateformat }}</span>
                    </template>
                </el-autocomplete>
            </div>

            <div class="fixwidth hrspacebetween">
                <el-select
                    v-model="ProjectPartid"
                    filterable
                    placeholder="Project Parts"
                    class="fullwidth padding2px bold"
                    :disabled="setdisable('part')"
                    @change="changepart">
                    <el-option
                        v-for="item in ProjectParts"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </div>

            <div v-bind:style="{ width: sectionwidth + 'px', maxwidth: sectionwidth + 'px', 'float': 'left' }">
                <div class="left hrspacebetween">
                    <el-select
                        v-model="sectionid"
                        filterable
                        placeholder="Section"
                        v-bind:style="{ width: sectionwidth }"
                        class="padding2px bold"
                        :disabled="setdisable('section')"
                        @change="sectionChange">
                        <el-option-group
                            v-for="group in [{id:1,label:'',options:sections},{id:2,label:'',options:getaddddtlist}]"
                            :key="group.id"
                            :label="group.label">
                        <el-option
                            v-for="item in group.options"
                            :key="item.id"
                            :label="item.sectiondisplay"
                            :value="item.id">
                        </el-option>
                    </el-option-group>
                    </el-select>
                </div>
            </div>

            <div v-if="!lastsavedshow" class="left spaceLR margintop5">
                <div class="el-input el-input--mini bold">
                    &nbsp; {{ project.library }}
                </div>
            </div>

            <div v-if="!lastsavedshow" class="left spaceLR margintop5">
                <div class="el-input el-input--mini">
                    <span v-if="lastsaved"><b>Saved:</b></span>
                    <span v-if="lastsavedbyname != null"> {{ lastsavedbyname }}</span>
                    {{ lastsaved }}
                </div>
            </div>

            <!--
            <div  v-if="!lastsavedshow" class="fixwidth hrspacebetween">
                <div  class="left spaceLR">
                    <div class="el-input el-input--mini bold">
                        &nbsp; {{ project.library }}
                    </div>
                </div>

                <div  class="left spaceLR">
                    <div class="el-input el-input--mini">
                        <span v-if="lastsaved"><b>Saved:</b></span>
                        <span v-if="lastsavedbyname != null"> {{ lastsavedbyname }}</span>
                        {{ lastsaved }}
                    </div>
                </div>
            </div>
            -->

            <!-- </component> -->
            <!-- <div v-if="!lastsavedshow">
            <el-col :span="7">
                <div class="el-input el-input--mini bold">
                <span v-if="lastsaved">Last saved</span>
                <span v-if="lastsavedbyname != null"> by {{ lastsavedbyname }}</span>
                {{ lastsaved }}
                </div>
            </el-col>

            <el-col :span="1">
                <div class="el-input el-input--mini bold">
                &nbsp; {{ project.library }}
                </div>
            </el-col>
            </div> -->
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: "projectselectpart",
    props: {
        projectplaceholder: String,
        lastsavedshow: { type: Boolean, default: false },
        remember: { type: Boolean, default: true },
        custom: {
            rowcol: { type: String, default: "el-col" },
            projectsid: { type: Object, default: null },
            projectdisabled:{ type: Boolean, default: false },
            partdisabled:{ type: Boolean, default: false },
            sectiondisabled:{ type: Boolean, default: false }
        },
        training:{type:Boolean,default:false},
        absoluteurl:{type:Boolean,default:false},
        defaultproject: { type: Object, default: null },
        pagename:{type:String,default:""},
    },

    data() {
        return {
            addddtsections:[],
            defaultcustom: {
                rowcol: "el-col",
            },
            sectionwidth: "290px",
            part: {},
            Projectobj: {
                project: {},
                sectionid: null,
                part: {},
            },
            sections: [],
            partsections: [],
            project: {
                id: 0,
                projectname: "",
                library: "",
                section: {},
            },

            ProjectParts: [],
            ProjectPartid: null,
            sectionid: null,
            lastsavedbyname: null,
            projecturl: {
                projectid: 0,
                partid: 0,
                sectionid: 0,
            },
            currentproject: null
        };
    },

    computed: {
        getaddddtlist(){
            if(this.addddtsections.length > 0){
            return this.addddtsections.filter((x)=>{
                    if(x.projectPartId == this.ProjectPartid){
                        return x;
                    }
            });
        }else{
            return [];
        }
        },
        setdisable(){
            return function(data){
               if(this.custom && this.custom[data+'disabled']){
                   return this.custom[data+'disabled'];
               }else{
                   return false;    
               }
            }
        },
        sectionversion: function () {
            var msg = [];
            if (this.project && this.project.section) {
                if (
                this.project.section.document != null &&
                this.project.section.document != this.project.section.sectiondisplay
                ) {
                    msg.push(this.project.section.document);
                }
                if (this.project.section.version) {
                    msg.push(this.project.section.version);
                }
                if (this.project.section.comment) {
                    msg.push(this.project.section.comment);
                }
                if (this.project.section.createdon) {
                    var d = new Date(this.project.section.createdon);
                    msg.push(d.toDateString());
                }
            }
            return msg.join(", ");
        },

        lastsaved: function () {
            if (this.project && this.project.section) {
                if (this.project.section.editedon) {
                var dt = new Date(this.project.section.editedon);
                return moment(dt).format("DD-MMM-YYYY HH:mm");
                // return dt.toLocaleString().replace(/:\d{2}\s/, " ");
                }
            }
        },
    },

    methods: {
        changepart: function (event) {
            this.sectionid = null;
            this.section = [];
            if (event == 0) {
                this.projectPartId = 0;
                this.sections = this.partsections.filter((s) => {
                    if (s.projectPartId == 0) {
                        return s;
                    }
                });
            } else {
                this.sections = this.partsections.filter((s) => {
                    if (s.projectPartId == event) {
                        return s;
                    }
                });
            }
            
            this.projecturl.partid = event;
            this.generateurl(this.projecturl.partid, 'partid');

            var projectpart = this.ProjectParts.find((p) => p.id == event);
            if (projectpart && this.remember == true) {
                this.$store.state.projectpart = projectpart;
                this.$store.state.partname = projectpart.name;
            }

            this.part = projectpart;
            this.$store.state.projectpart = projectpart;
            this.Projectobj.project = this.project;
            this.Projectobj.sectionid = this.sectionid;
            this.Projectobj.part = this.part;
            this.getCurrentSection();
        },
        // searchProject: function (query, done) {
        //   //  console.log(new Date().toJSON() + "Query Fired:" + query);
        //   this.$http
        //     .post("project/search?q=" + query, {})
        //     .then((response) => {
        //       var templates = JSON.parse(response.data);
        //       done(templates);
        //     })
        //     .catch((err) => {
        //       this.$bus.$emit("error", err);
        //       //console.log(err.response.data);
        //       //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
        //     });
        // },

        selectProject: function (item) {
            if (this.remember) {
                this.$store.state.project = JSON.parse(JSON.stringify(item));
            }
            this.project = item;
            if (item.id != undefined) {
                this.projecturl.projectid = item.id;
                this.generateurl(this.projecturl.projectid, 'projectid');                
            }
            
            this.sectionid = null;
            this.getSections();
        },

        generateurl(val, column) {
            if (this.absoluteurl) {
                const baseurl = `${window.location.origin}${window.location.pathname}`;
                let newurl = "";
                if (column == 'projectid') {
                    newurl = `${baseurl}?${column}=${val}`;
                }
                else if (column == 'partid') {
                    newurl = `${baseurl}?projectid=${this.projecturl.projectid}&${column}=${val}`;
                }
                else if (column == 'sectionid') {
                    newurl = `${baseurl}?projectid=${this.projecturl.projectid}&partid=${this.projecturl.partid}&${column}=${val}`;
                }
                else {
                    newurl = baseurl;
                }
                window.history.pushState({ path: newurl }, '', newurl);
            }
        },

        closeproject: function () {
            this.project = {
                id: 0,
                projectname: "",
                library: "",
            };
            this.generateurl(null, "close");
            this.lastsavedbyname = null;
            this.sectionid = null;
            this.sections = [];
            this.part = null;
            this.partsections = [];
            this.ProjectParts = [];
            this.project.section = {};
            this.ProjectPartid = null;
            this.project.projectsections = this.sections;
            this.Projectobj.project = this.project;
            this.Projectobj.sectionid = this.sectionid;
            this.Projectobj.part = this.part;
            this.Projectobj.lastsavedinfo = {};
            this.$emit("projectsectionchange", this.Projectobj);
        },

        getCurrentSection: function () {
            this.sectionid = null;
            if (this.$store.state.sectiondisplay) {
                var section = this.sections.find(
                (s) => s.sectiondisplay == this.$store.state.sectiondisplay
                );
                if (section) {
                    this.sectionid = section.id;
                    this.generateurl(this.sectionid, 'sectionid');
                    this.lastsavedbyname = section.lastsavedbyname;
                }
            }
            if (this.sectionid == null) {
                if (this.$store.state.section) {
                    var section = this.sections.find(
                        (s) => s.sectiondisplay == this.$store.state.section
                    );
                    if (section) {
                        this.sectionid = section.id;
                        this.lastsavedbyname = section.lastsavedbyname;
                    }
                }
            }
            this.project.section = section;
            this.project.projectsections = this.sections;
            this.Projectobj.project = this.project;
            this.Projectobj.sectionid = this.sectionid;
            this.Projectobj.part = this.part;
            this.Projectobj.project.projectpart = this.part;
            if (section) {
                var size = this.measureTextWidth(section.sectiondisplay, 14) + 10;
                size = size < 290 ? 290 : size;
                this.sectionwidth = size.toString() + "px";
            }
            if (this.lastsavedbyname != null) {
                this.Projectobj.lastsavedinfo = {
                    library: this.project.library,
                    lastsavedbyname: this.lastsavedbyname,
                    lastsaved: this.lastsaved
                };
            } else {
                this.Projectobj.lastsavedinfo = {};
            }
            this.$emit("projectsectionchange", this.Projectobj);
        },

        getCurrentPart: function () {
            this.ProjectPartid = null;
            if (this.$store.state.partname) {
                var part = this.ProjectParts.find(
                (s) => s.name == this.$store.state.partname
                );
                if (part) {
                    this.ProjectPartid = part.id;
                    this.part = part;
                }
            }
            if (this.ProjectPartid == null) {
                if (this.$store.state.projectpart) {
                    var part = this.ProjectParts.find(
                        (s) => s.id == this.$store.state.projectpart.id
                    );
                    if (part) {
                        this.ProjectPartid = part.id;
                        this.part = part;
                    }
                }
            }
            //       if(part == undefined){
            //            part = {id : this.ProjectParts[0].id,
            //         name:this.ProjectParts[0].name
            //         }
            //   if(this.project.projectname && part.id == 0){
            //       this.ProjectPartid = 0;
            //   }
            // }

            if (part != undefined) {
                this.changepart(part.id);
                this.Projectobj.project = this.project;
                this.Projectobj.sectionid = this.sectionid;
                this.Projectobj.part = this.part;
            }
        },

        sectionChange: function (value, value1) {
            var section = this.sections.find((s) => s.id == value);
            if (section && this.remember == true) {
                this.$store.state.sectiondisplay = section.sectiondisplay;
                this.$store.state.section = section.section;
            }
            if(!section){
                 section = this.addddtsections.find((s) => s.id == value);
                 if (section && this.remember == true) {
                    this.$store.state.sectiondisplay = section.sectiondisplay;
                    this.$store.state.section = section.section;
                }
            }
            
            this.projecturl.sectionid = value;
            this.generateurl(this.projecturl.sectionid, 'sectionid');

            this.project.section = section;
            this.project.projectsections = this.sections;
            this.lastsavedbyname = section.lastsavedbyname;
            this.Projectobj.project = this.project;
            this.Projectobj.sectionid = this.sectionid;
            this.Projectobj.part = this.part;
            this.Projectobj.project.projectpart = this.part;
            if (section) {
                var size = this.measureTextWidth(section.sectiondisplay, 14) + 10;
                size = size < 290 ? 290 : size;
                this.sectionwidth = size.toString() + "px";
            }
            if (this.lastsavedbyname != null) {
                this.Projectobj.lastsavedinfo = {
                    library: this.project.library,
                    lastsavedbyname: this.lastsavedbyname,
                    lastsaved: this.lastsaved
                };
            } else {
                this.Projectobj.lastsavedinfo = {};
            }
            this.$emit("projectsectionchange", this.Projectobj);
        },

        addprojectbyid(proj) {
            this.sectionid = null;
            if (proj.projectid != null) {
                this.post("project/getprojectbyid", { id: proj.projectid })
                .then((response) => {
                    // this.project = response.data;

                    //   this.post('project/getsections',this.project).then((response)=>{
                    this.$nextTick(() => {
                    this.project = response.data;
                    if (this.remember) {
                        this.$store.state.project = JSON.parse(JSON.stringify(response.data));
                    }
                    this.addddtsections = response.data.addddtsectionsview; 
                    this.project.projectname = response.data.projectname;
                    this.partsections = response.data.projectsectionsview;
                    this.ProjectParts = response.data.projectparts;
                    if (this.ProjectParts.length == 0 && this.project.projectname != "")
                    {
                        this.ProjectParts.unshift({ id: 0, name: "Single Bill" });
                    }
                    if (proj.partid != null) {
                        var part = this.ProjectParts.find((x) => x.id == proj.partid);
                        this.$store.state.partname = part.name;
                        this.getCurrentPart();
                    }
                    if (proj.sectionid != null) {
                        var section = this.partsections.find((x) => x.id == proj.sectionid);
                        this.$store.state.sectiondisplay = section.sectiondisplay;
                    }
                    this.getCurrentSection();
                    this.$store.state.projectids = null;
                    this.currentproject = null;
                    });
                    // })
                });
            }
        },

        getSections: function () {
            if(this.pagename == "projectbill"){
                this.project.projectmetadataview = {};
                this.project.projectmetadataview.useaddddt = true; 
            }else{
                this.project.projectmetadataview = null;
            }
            this.$http
            .post("project/getsections", this.project)
            .then((response) => {
            //   var json = JSON.parse(response.data);
            this.addddtsections = response.data.addddtsectionsview; 
            this.partsections = response.data.projectsectionsview;
            this.ProjectParts = response.data.projectparts;
            if (this.ProjectParts.length == 0 && this.project.projectname != "") {
                this.ProjectParts.unshift({ id: 0, name: "Single Bill" });
            }
            this.getCurrentPart();
            })
            .catch((err) => {
            this.$bus.$emit("error", err);
            this.loading = false;
            });
        },
    },

    destroyed() {
        //  if(this.isemit == true){
        this.$bus.$off("addproject");
        this.$bus.$off("closeproject");
        //  }
    },

    created: function () {
        const params = new URLSearchParams(window.location.search);
        if (params.size == 3 && this.absoluteurl) {
            this.projecturl.projectid = this.$training.checkandparseint(params.get('projectid'));
            this.projecturl.partid = this.$training.checkandparseint(params.get('partid'));
            this.projecturl.sectionid = this.$training.checkandparseint(params.get('sectionid'));
        }
        if (this.defaultproject != null) {
            this.currentproject = this.defaultproject;
        }
        if(this.training == false && this.$store.state.project != undefined && this.$store.state.project.istraining == true){
            this.$store.state.project = "";   
        }
        if (this.custom != undefined) {
            this.defaultcustom = this.custom;
        }
        this.$bus.$on("closeproject", () => {
            this.closeproject();
        });
        this.$bus.$on("addproject", (proj) => {
            if (proj != null) {
                this.defaultcustom.projectid = null;
                this.$store.state.projectids = null;
                this.currentproject = null;
                this.addprojectbyid(proj);
            }
            this.$store.state.project = null;
            if (this.defaultcustom.projectid != null) {
                var data = this.defaultcustom.projectid;
                this.addprojectbyid(data);
                this.defaultcustom.projectid = null;
            }
            if (this.$store.state.projectids != null) {
                var data = this.$store.state.projectids;
                this.addprojectbyid(data);
                this.$store.state.projectids = null;
            }
            if (this.currentproject != null) {
                this.addprojectbyid(this.currentproject);
                this.currentproject = null;
            }
        });

        if (this.defaultcustom.projectid != null) {
            this.addprojectbyid(this.defaultcustom.projectid);
            this.defaultcustom.projectid = null;
        } else {
            if (this.$store.state.projectids != null) {
                this.addprojectbyid(this.$store.state.projectids);
            }
            else if (this.currentproject != null) {
                this.addprojectbyid(this.currentproject);
            }
            else {
                if (this.$store.state.project) {
                    var project = JSON.parse(JSON.stringify(this.$store.state.project));
                    this.selectProject(project);
                }
            }
        }
    },
};
</script>

<style>
.left {
  float: left;
}
.spaceLR {
  margin-right: 1%;
  margin-left: 2%;
}
.right {
  float: right;
}
</style>
