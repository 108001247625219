<template>
    <div>
        <div v-if="!detail">
            <div class="fixdheader">
                <el-row class="width1160px">
                    <el-col :span="12" class="headerstyle">
                        <span>BQ Code Library</span>
                    </el-col>

                    <el-col class="width240px floatright">
                        <span>
                            <el-button type="primary" style="padding: 4px 6px 2px;" @click="archivechange">
                                Show Archive
                                <el-switch v-model="additionalfilter" @change="archivechange"></el-switch>
                            </el-button>
                        </span>

                        <span v-if="!additionalfilter">
                            <el-button type="primary" class="marginleft4px width110" :disabled="!editpermission"
                                @click="createNew()" icon="el-icon-circle-plus">
                                Create New
                            </el-button>
                        </span>
                    </el-col>
                </el-row>
                <div>
                    <span class="left padright5px">
                        <el-select v-model="view.filter[0].value" class="libraryfixwidth" clearable
                            @change="onSearch(0, $event)" @clear="onSearch(0, $event)" placeholder="Library">
                            <el-option v-for="item in libraries" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </span>

                    <span class="left padright5px">
                        <el-input :spellcheck="isspellcheck" class="width220px" v-model="view.filter[1].value"
                            placeholder="BQ Code" @change="onSearch(1, $event)">
                            <el-button slot="append" icon="el-icon-search" class="paddingl13" circle
                                @click="onSearch(1, view.filter[1].value)"></el-button>
                        </el-input>
                    </span>

                    <span class="left padright5px">
                        <el-pagination class="pagesizeswidth textalignlpage" @size-change="rowperpages"
                            @current-change="currentChange" :current-page.sync="view.pageno" :page-sizes="pagesizes"
                            :page-size="view.pagesize" layout="sizes, prev, pager, next" :total="view.count">
                        </el-pagination>
                    </span>

                    <span>
                        <el-select v-model="sectionfilter" @change="sectionfilterchanged" filterable=""
                            placeholder="Section" class="width230px" clearable>
                            <el-option v-for="item in sections" :key="item.section" :label="item.section"
                                :value="item.section">
                            </el-option>
                        </el-select>
                    </span>

                    <span>
                        <el-date-picker v-model="view.filter[2].value" type="date" placeholder="Since Date"
                            class="custom-date-picker width110 marginleft4px" format="dd-MMM-yyyy" align="center"
                            value-format="yyyy-MM-dd" @change="onSearch(2, $event)" clearable>
                        </el-date-picker>
                    </span>

                    <span>
                        <el-select v-model="view.filter[3].value" class="width110 marginleft4px"
                            @change="onSearch(3, $event)" placeholder="Author" clearable>
                            <el-option v-for="item in users" :key="item.id" :label="item.loginname" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                    <span>
                        <el-button v-if="!additionalfilter && isarchiveselect" type="primary"
                            class="marginleft4px floatright" @click="addmultiarchive">
                            Add To Archive
                        </el-button>
                    </span>
                </div>

                <div class="spaceBetween" />
                <hr class="hrstyle" />
            </div>
            <div class="tablepaddinglr5 tabletexttop asc1 asc2 des1 des2">
                <el-table :data="tabledata" :height="height" :default-sort="{ prop: 'bqcode', order: 'ascending' }"
                    size="mini" @sort-change="sortChange" :header-cell-style="headerrowstyleborder" v-loading="loading"
                    :stripe="true" :border="true">
                    <el-table-column prop="library" label="Library" align="center" width="50"></el-table-column>
                    <el-table-column prop="bqcode" label="BQ Code" sortable="custom" width="210"></el-table-column>
                    <el-table-column prop="unit" label="Unit" sortable="custom" width="60"></el-table-column>
                    <el-table-column prop="section" label="Section" sortable="custom" width="240"></el-table-column>
                    <el-table-column prop="group" label="Group" sortable="custom" width="210"></el-table-column>
                    <el-table-column prop="work" label="Item or Work" sortable="custom" width="210"></el-table-column>
                    <el-table-column prop="level1" label="Level 1" sortable="custom" width="300"></el-table-column>
                    <el-table-column prop="level2" label="Level 2" sortable="custom" width="120"></el-table-column>
                    <el-table-column prop="level3" label="Level 3" sortable="custom" width="90"></el-table-column>
                    <el-table-column prop="author.loginname" label="Author" width="60"></el-table-column>
                    <el-table-column label="Actions" align="center" width="75">
                        <template slot-scope="scope">
                            <el-button-group class="paddingright4px">
                                <el-button size="mini" type="primary" class="padding7"
                                    :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                                    @click="handleEdit(scope.$index, scope.row)">
                                </el-button>
                            </el-button-group>

                            <el-button-group class="paddingright4px">
                                <el-button size="mini" type="danger" class="padding7" icon="el-icon-delete"
                                    :disabled="!editpermission" @click="handleDelete(scope.$index, scope.row)">
                                </el-button>
                            </el-button-group>
                        </template>
                    </el-table-column>

                    <el-table-column v-if="additionalfilter == false" width="40">
                        <template slot="header">
                            <span>
                                <i class="glyphicon glyphicon-eye-close bqcodearchiveiconstyle"></i>
                            </span>
                        </template>

                        <template slot-scope="scope">
                            <el-checkbox v-model="scope.row.isarchive" @change="selectarchive(scope.row, scope.$index)"
                                class="archiveborderedtickbox" border></el-checkbox>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div v-if="detail">
            <el-row>
                <el-col><span class="headerstyle">BQ_Code Integrants - Add / Edit</span></el-col>
            </el-row>

            <el-row :style="{ height: (height + 30) + 'px' }">
                <el-col :span="12">
                    <el-form ref="detailform" :model="detailform" label-position="left" label-width="108px" size="mini"
                        v-if="detail">
                        <el-row>
                            <el-form-item label="Library" prop="library" class="labelheight">
                                <el-col :span="20">
                                    <el-select class="libraryfixwidth" v-model="detailform.library" filterable
                                        :id="'library_bqcodelibraryaddedit_library'" placeholder="Library"
                                        @change="libraryChange($event)">
                                        <el-option v-for="item in libraries" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                </el-col>

                                <el-col class="marginl5 center" :span="1">
                                    SIL
                                    <el-popover placement="top" trigger="hover"
                                        content="Specification Insert Level - within Library">
                                        <i slot="reference" class="el-icon-info"></i>
                                    </el-popover>
                                </el-col>
                                <el-col class="marginl5" :span="2"> MoM Code</el-col>
                            </el-form-item>

                            <el-form-item label="BQ Code">
                                <el-row>
                                    <el-col :span="6">
                                        <el-input :spellcheck="isspellcheck" class="padright5px" ref="bqcode"
                                            :id="'library_bqcodelibraryaddedit_bqcode'" v-model="detailform.bqcode"
                                            placeholder="BQ Code"></el-input>
                                    </el-col>
                                    <el-col :span="14">
                                        <el-input :spellcheck="isspellcheck" v-model="detailform.bqcodename"
                                            :id="'library_bqcodelibraryaddedit_bqcodename'"
                                            placeholder="BQ Code Name"></el-input>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Section">
                                <el-row>
                                    <el-col :span="20">
                                        <el-input type="textarea" class="bqtextarea" :spellcheck="isspellcheck"
                                            :autosize="{ minRows: 2, maxRows: 10 }" v-model="detailform.section"
                                            :id="'library_bqcodelibraryaddedit_section'" placeholder="Section">
                                        </el-input>
                                    </el-col>
                                    <el-col class="marginl5" :span="1"></el-col>
                                    <el-col class="marginl5" :span="2">{{ detailform.sectioncode }}</el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Group">
                                <el-row>
                                    <el-col :span="20">
                                        <el-input type="textarea" class="bqtextarea" :spellcheck="isspellcheck"
                                            :autosize="{ minRows: 2, maxRows: 10 }" v-model="detailform.group"
                                            :id="'library_bqcodelibraryaddedit_group'" placeholder="Group">
                                        </el-input>
                                    </el-col>
                                    <el-col class="marginl5" :span="1"></el-col>
                                    <el-col class="marginl5" :span="2">{{ detailform.groupcode }}</el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Item or Work">
                                <el-row>
                                    <el-col :span="20">
                                        <el-input type="textarea" class="bqtextarea" :spellcheck="isspellcheck"
                                            :autosize="{ minRows: 2, maxRows: 10 }" v-model="detailform.work"
                                            :id="'library_bqcodelibraryaddedit_itemorwork'" placeholder="Item or Work">
                                        </el-input>
                                    </el-col>

                                    <el-col class="marginl5 padleft3px" :span="1">
                                        <el-radio v-model="detailform.specification" :label="0">&nbsp;</el-radio>
                                    </el-col>
                                    <el-col class="marginl5" :span="2">{{ detailform.workcode }}</el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Level 1">
                                <el-row>
                                    <el-col :span="20">
                                        <el-input type="textarea" class="bqtextarea" :spellcheck="isspellcheck"
                                            :autosize="{ minRows: 2, maxRows: 10 }" v-model="detailform.level1"
                                            :id="'library_bqcodelibraryaddedit_level1'" placeholder="Level 1">
                                        </el-input>
                                    </el-col>

                                    <el-col class="marginl5 padleft3px" :span="1">
                                        <el-radio v-model="detailform.specification" :label="1">&nbsp;</el-radio>
                                    </el-col>
                                    <el-col class="marginl5" :span="2">{{ detailform.level1code }}</el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Level 2">
                                <el-row>
                                    <el-col :span="20">
                                        <el-input type="textarea" class="bqtextarea" :spellcheck="isspellcheck"
                                            :autosize="{ minRows: 2, maxRows: 10 }" v-model="detailform.level2"
                                            :id="'library_bqcodelibraryaddedit_level2'" placeholder="Level 2">
                                        </el-input>
                                    </el-col>

                                    <el-col class="marginl5 padleft3px" :span="1">
                                        <el-radio v-model="detailform.specification" :label="2">&nbsp;</el-radio>
                                    </el-col>
                                    <el-col class="marginl5" :span="2">{{ detailform.level2code }}</el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Level 3">
                                <el-row>
                                    <el-col :span="20">
                                        <el-input type="textarea" class="bqtextarea" :spellcheck="isspellcheck"
                                            :autosize="{ minRows: 3, maxRows: 10 }" v-model="detailform.level3"
                                            :id="'library_bqcodelibraryaddedit_level3'" placeholder="Level 3">
                                        </el-input>
                                    </el-col>

                                    <el-col class="marginl5 padleft3px" :span="1">
                                        <el-radio v-model="detailform.specification" :label="3">&nbsp;</el-radio>
                                    </el-col>
                                    <el-col class="marginl5" :span="2">{{ detailform.level3code }}</el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Unit">
                                <el-row>
                                    <el-col :span="10">
                                        <el-select v-model="detailform.unit" class="libraryfixwidth"
                                            :id="'library_bqcodelibraryaddedit_unit'" placeholder="Unit">
                                            <el-option v-for="item in units" :key="item" :label="item" :value="item">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col :span="10">
                                        <el-checkbox class="checkboxwithborder floatright"
                                            id="library_bqcodelibraryaddedit_composite" :border="true"
                                            v-model="detailform.composite" label="Composite"></el-checkbox>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Dim Tolerance">
                                <el-row>
                                    <el-col :span="4" class="padright5px">
                                        <el-checkbox class="checkboxwithborder fullwidth"
                                            id="library_bqcodelibraryaddedit_dimtolerance_na" :border="true"
                                            v-model="detailform.istolerance" label="N/A"></el-checkbox>
                                    </el-col>

                                    <el-col :span="8">
                                        <el-input :spellcheck="isspellcheck" class="padright5px rightimp"
                                            v-model="detailform.lowertolerance" placeholder="Minimum"
                                            :id="'library_bqcodelibraryaddedit_minimum'"
                                            @change="toleranceChange"></el-input>
                                    </el-col>

                                    <el-col :span="8">
                                        <el-input :spellcheck="isspellcheck" class="rightimp"
                                            v-model="detailform.uppertolerance" placeholder="Maximum"
                                            :id="'library_bqcodelibraryaddedit_maximum'"
                                            @change="toleranceChange"></el-input>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Author">
                                <el-row>
                                    <el-col :span="20">
                                        <div>
                                            <span>
                                                {{ detailform.author.loginname }}
                                            </span>
                                            <span class="marginleft20px fontweight500">
                                                {{ detailform.editedonstr }}
                                            </span>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="">
                                <el-row>
                                    <el-col :span="20">
                                        <div class="floatright">
                                            <el-checkbox class="checkboxwithborder marginright4px"
                                                id="library_bqcodelibraryaddedit_keepbqcode" :border="true"
                                                v-model="detailform.keepbqcode" label="Keep"></el-checkbox>

                                            <el-button :disabled="!editpermission"
                                                @click="cellchanged('library_bqcodelibraryaddedit_save', () => { save() }, false)"
                                                type="success" icon="el-icon-circle-check">Save</el-button>

                                            <el-button :disabled="!editpermission" v-if="detailform.id"
                                                @click="cellchanged('library_bqcodelibraryaddedit_saveas', () => { saveas() }, false)"
                                                type="success" icon="el-icon-circle-check">Save as</el-button>
                                            <el-button icon="el-icon-back" type="info" @click="back">Back</el-button>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                        </el-row>
                    </el-form>

                    <div class="margintop20px" v-if="detailform.id > 0">
                        <el-row size="mini">
                            <el-col :span="21" class="commongrey borderradiusunset cursorpointer"
                                style="width: 85.80%;">
                                <div class="bold center" @click="openviewreport">Advanced</div>
                            </el-col>
                        </el-row>

                        <div v-if="openreport == true">
                            <el-row>
                                <el-col :span="21" class="headerstyle widthinpercentage">
                                    <span>Usage Report</span>
                                    <div>
                                        <hr class="margintop5 marginbunset groove" />
                                    </div>
                                </el-col>
                            </el-row>

                            <el-form ref="detailform" :model="detailform" label-position="left" label-width="108px"
                                size="mini" v-if="detail">
                                <el-row>
                                    <el-row>
                                        <el-col :span="21" class="widthinpercentage">
                                            <div class="flexbox">
                                                <span class="left">
                                                    <el-button type="success" style="padding: 7px 11px;"
                                                        :disabled="!editpermission" @click="getsummarydata">Project
                                                        Usage</el-button>
                                                </span>

                                                <span class="left" style="margin-left: 118px;">
                                                    <div class="width125px">
                                                        <span class="bold">Last</span>
                                                        <span class="marginl5">
                                                            <el-date-picker :disabled="true"
                                                                v-model="bqcodeview.lastusedate" type="date"
                                                                placeholder="Last use"
                                                                class="darkgrey custom-date-picker width80px"
                                                                format="dd-MMM-yyyy" align="center"
                                                                value-format="yyyy-MM-dd" :clearable="false">
                                                            </el-date-picker>
                                                        </span>
                                                    </div>
                                                </span>

                                                <span class="left marginright5px">
                                                    <div class="width100px">
                                                        <span class="bold">&lt; 2 Yrs</span>
                                                        <span class="marginl5 widthlabel40px">
                                                            <el-input :disabled="true" class="darkgrey rightimp"
                                                                v-model="bqcodeview.last2yearsuse"></el-input>
                                                        </span>
                                                    </div>
                                                </span>

                                                <span class="left">
                                                    <div class="maxcontent">
                                                        <span class="bold">Total</span>
                                                        <span class="marginl5 widthlabel40px">
                                                            <el-input :disabled="true" class="darkgrey rightimp"
                                                                v-model="bqcodeview.totaluse"></el-input>
                                                        </span>
                                                    </div>
                                                </span>
                                            </div>
                                            <div>
                                                <hr class="margintop5 marginbottom2 bordercolorgray" />
                                                <hr class="margintop5 marginbottom2 bordercolorgray" />
                                            </div>
                                        </el-col>
                                    </el-row>

                                    <el-row>
                                        <el-col :span="21" class="widthinpercentage">
                                            <div class="flexbox">
                                                <span class="marginr10px">
                                                    <el-button :disabled="!editpermission" type="success"
                                                        class="padding7" @click="createreport">Detailed
                                                        Report</el-button>
                                                </span>
                                                <span class="left marginr10px">
                                                    <el-select v-model="bqcodeview.reporttype" :filterable="true"
                                                        @change="changereporttype" :style="{ width: '97px' }"
                                                        placeholder="Report Type">
                                                        <el-option v-for="item in reporttypes" :key="item" :label="item"
                                                            :value="item">
                                                        </el-option>
                                                    </el-select>
                                                </span>

                                                <span class="left">
                                                    <div class="width130px">
                                                        <span class="bold">Start</span>
                                                        <span class="marginl5">
                                                            <el-date-picker v-model="bqcodeview.startdate" type="date"
                                                                placeholder="Date" class="custom-date-picker width80px"
                                                                format="dd-MMM-yyyy" align="center"
                                                                value-format="yyyy-MM-dd" :clearable="false">
                                                            </el-date-picker>
                                                        </span>
                                                    </div>
                                                </span>

                                                <span class="left">
                                                    <div class="datewidth135">
                                                        <span class="bold">Finish</span>
                                                        <span class="marginl5">
                                                            <el-date-picker v-model="bqcodeview.enddate" type="date"
                                                                placeholder="Date" class="custom-date-picker width80px"
                                                                format="dd-MMM-yyyy" align="center"
                                                                value-format="yyyy-MM-dd" :clearable="false">
                                                            </el-date-picker>
                                                        </span>
                                                    </div>
                                                </span>
                                            </div>
                                            <div>
                                                <hr class="margintop5 marginbottom2" />
                                            </div>
                                        </el-col>
                                    </el-row>

                                    <el-row>
                                        <el-col :span="21" class="widthinpercentage">
                                            <span class="fontsize14px bold">REGEX Report Tools</span>
                                            <span class="floatright">
                                                <span class="marginright4px">Exclude From Find Results</span>
                                                <span class="marginright4px"><el-checkbox
                                                        v-model="bqcodeview.isexcluded"></el-checkbox></span>
                                                <span><i class="el-icon-info"></i></span>
                                            </span>
                                            <div>
                                                <hr class="margintop5 marginbottom2 groove" />
                                            </div>
                                        </el-col>
                                    </el-row>

                                    <el-form-item label="Specification">
                                        <el-row>
                                            <el-col :span="20">
                                                <el-input type="textarea" class="bqtextarea" :spellcheck="isspellcheck"
                                                    @change="validateregex()"
                                                    @input="$training.checkregex(bqcodeview.specification, 'bqcodeviewspecificationinput')"
                                                    :autosize="{ minRows: 2, maxRows: 10 }"
                                                    v-model="bqcodeview.specification" placeholder="Specification">
                                                </el-input>
                                                <div class="padleft10px alert-danger" style="display: none;"
                                                    id="bqcodeviewspecificationinput">
                                                    <strong>Invalid Regular Expression.</strong>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-form-item>

                                    <el-form-item label="Asterisk *">
                                        <el-row>
                                            <el-col :span="20">
                                                <el-input type="textarea" class="bqtextarea" :spellcheck="isspellcheck"
                                                    @change="validateregex()"
                                                    @input="$training.checkregex(bqcodeview.asterisk, 'bqcodeviewasteriskinput')"
                                                    :autosize="{ minRows: 2, maxRows: 10 }"
                                                    v-model="bqcodeview.asterisk" placeholder="Asterisk *">
                                                </el-input>
                                                <div class="padleft10px alert-danger" style="display: none;"
                                                    id="bqcodeviewasteriskinput">
                                                    <strong>Invalid Regular Expression.</strong>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-form-item>

                                    <el-form-item label="Endnotes">
                                        <el-row>
                                            <el-col :span="20">
                                                <el-input type="textarea" class="bqtextarea" :spellcheck="isspellcheck"
                                                    @change="validateregex()"
                                                    @input="$training.checkregex(bqcodeview.other, 'bqcodeviewotherinput')"
                                                    :autosize="{ minRows: 2, maxRows: 10 }" v-model="bqcodeview.other"
                                                    placeholder="Endnotes">
                                                </el-input>
                                                <div class="padleft10px alert-danger" style="display: none;"
                                                    id="bqcodeviewotherinput">
                                                    <strong>Invalid Regular Expression.</strong>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-form-item>
                                </el-row>
                            </el-form>
                        </div>
                    </div>
                    <div>
                        <el-dialog v-draggable :title="'Detailed Report: ' + bqcodeview.reporttype"
                            :visible.sync="showdialogue" :before-close="mediadialogClose" :close-on-click-modal="false"
                            class="dialogboxpadding" width="70%">
                            <div class="tablepaddinglr3 tabletexttop sortingbotton">
                                <el-table :height="height - 20" v-if="bqcodeview.reporttype === 'Project Use'"
                                    :data="reportdata" :header-cell-style="reportcellstyle" :resizable="true"
                                    :border="true" :stripe="true">
                                    <el-table-column label="Project Use" header-align="center">
                                        <el-table-column header-align="center" prop="p.projectname" label="Project"
                                            width="190"></el-table-column>

                                        <el-table-column header-align="center" prop="pp.name" label="Part"
                                            width="120"></el-table-column>

                                        <el-table-column header-align="center" prop="ps.sectiondisplay"
                                            label="Section Display">
                                            <template slot-scope="scope">
                                                <div class="UR-Link" @click="gotoprojectpage(scope.row)">
                                                    {{ scope.row.ps.sectiondisplay }}</div>
                                            </template>
                                        </el-table-column>
                                    </el-table-column>

                                    <el-table-column header-align="center" label="Date">
                                        <el-table-column align="center" prop="ps.editedon" :formatter="dateformatter"
                                            label="Entry" width="75"></el-table-column>
                                    </el-table-column>

                                    <el-table-column label="NRM Sections" header-align="center">
                                        <el-table-column header-align="center" prop="ptd.specification"
                                            label="Specification"></el-table-column>
                                        <el-table-column header-align="center" prop="ptd.asterisk"
                                            label="Asterisk *"></el-table-column>
                                        <el-table-column header-align="center" prop="ptd.other"
                                            label="Endnotes"></el-table-column>
                                    </el-table-column>
                                </el-table>
                            </div>

                            <div class="tablepaddinglr3 tabletexttop sortingbotton">
                                <el-table :height="height - 20" v-if="bqcodeview.reporttype === 'MC_T Use'"
                                    :data="reportdata" :header-cell-style="reportcellstyle" :border="true"
                                    :stripe="true">
                                    <el-table-column header-align="center" prop="t.templatename" label="MC_T Name Use">
                                        <template slot-scope="scope">
                                            <div class="UR-Link" @click="gotomctemplate(scope.row)">
                                                {{ scope.row.t.templatename }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column header-align="center" prop="td.specification"
                                        label="Specification"></el-table-column>
                                    <el-table-column header-align="center" prop="td.asterisk"
                                        label="Asterisk *"></el-table-column>
                                    <el-table-column header-align="center" prop="td.other"
                                        label="Endnotes"></el-table-column>
                                </el-table>
                            </div>
                        </el-dialog>
                    </div>
                </el-col>

                <el-col :span="12" :style="{ height: (height + 199) + 'px' }" class="verticalline">
                    <div>
                        <span>
                            <el-button @click="levelUpClick()" class="padding7 floatright"
                                icon="glyphicon glyphicon-arrow-up" type="primary">One Level Up</el-button>
                        </span>
                        <span>
                            <div><b>Measurement Library</b></div>
                            <div>Current Level : {{ currentlevelname }}</div>
                        </span>
                    </div>

                    <div class="tablepaddinglr3 sortingbotton">
                        <el-table :data="library" :height="height - 15" :header-cell-style="tableheaderpadding"
                            v-loading="loadinglibrary" @row-dblclick="librarydblclick" :stripe="true">
                            <el-table-column prop="code" label="Code" align="left" sortable
                                width="40"></el-table-column>
                            <el-table-column prop="unit" label="Unit" align="left" sortable
                                width="40"></el-table-column>
                            <el-table-column prop="description" label="Description(Click to drill down)"
                                sortable></el-table-column>
                        </el-table>
                    </div>
                </el-col>
            </el-row>
        </div>


        <el-dialog v-if="showerror" title="Error" :visible.sync="showerror" width="80%">
            <span v-html="errormessage" />
        </el-dialog>
        <customdialog v-if="validationerror" :err="errorobject"></customdialog>
    </div>
</template>

<script>
import customdialogbox from './customdialogbox.vue';
import DraggableDirective from "./DraggableDirective"; // Adjust the path as needed

import moment from "moment";
var timeout;
export default {
    directives: {
        draggable: DraggableDirective,
    },
    data() {
        return {
            errorobject: {
                message: [],
                type: "",
                visible: false,
                sourcereq: "",
                sourcewarn: ""
            },
            validationerror: false,
            detail: false,
            tabledata: [],
            grid: {},
            detailform: {},
            showerror: false,
            errormessage: "",
            units: this.$store.state.units,
            libraries: this.$store.state.libraries,
            loading: false,
            library: [],
            loadinglibrary: false,
            level: 1,
            currentlevelname: 'Section',
            groupid: { 1: 0 },
            additionalfilter: false,
            users: [],
            view: {
                pagesize: 20,
                filter: [
                    { table: 'bq', column: 'library', value: "" },
                    { table: 'bq', column: 'bqcode', value: "" },
                    { table: "bq", column: "editedon", operation: ">=", value: "" },
                    { table: "bq", column: "editedby", operation: "=", value: "" }
                ],
                sortcol: "bq.bqcode",
                sortorder: "ascending",
                defaultfilter: ""
            },
            height: (document.documentElement.clientHeight - 130),
            pagesizes: this.$store.state.pagesizes,
            archivearray: [],
            isarchiveselect: false,
            openreport: false,
            showdialogue: false,
            reporttypes: ["Project Use", "MC_T Use"],
            reportdata: [],
            bqcodeview: { reporttype: 'Project Use' },
            sections: this.$store.state.sections,
            sectionfilter: "",
            newcodes: {
                l1: "",
                l2: ""
            }
        }
    },

    components: {
        'customdialog': customdialogbox,
    },

    methods: {
        getsection() {
            // Get NRM section for each page
            if (false) {
                // return this.detailform.section;
            } else {
                return "";
            }
        },
        cellchanged(cellaction, callback, cancall) {
            const app = this;
            this.$directionutility.setcomponent(app);
            if (cancall) {
                callback();
                this.canmatchcellid(() => { }, cellaction, this.getsection());
            } else {
                this.canmatchcellid(callback, cellaction, this.getsection());
            }
        },
        tableheaderpadding({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                return 'padding:0px;';
            }
        },
        archivechange() {
            this.view.pageno = 1;
            this.additionalfilter = this.additionalfilter == false ? true : false;
            this.refresh();
        },
        headerrowstyleborder({ row, column, rowIndex, columnIndex }) {
            if (rowIndex == 0) {
                return { "color": "white", "background-color": "#BBBBBB", "border-right": "1px solid #878787" }
            }
        },
        focusEdit: function () {
            setTimeout(() => {
                this.$refs.bqcode.focus();
            }, 100)
        },

        createNew: function () {
            this.detailform = {
                library: 'SMM7',
                bqcode: 'M00.00.00.00.00',
                bqcodename: '',
                section: '',
                specification: 0,
                work: '',
                group: '',
                unit: null,
                level1: '',
                level2: '',
                level3: '',
                lowertolerance: null,
                uppertolerance: null,
                author: {},
                composite: false,
                istolerance: false,
                keepbqcode: false
            }
            if (this.$store.state.project.library) {
                this.detailform.library = this.$store.state.project.library;
            }
            this.detail = true;
            this.focusEdit();
            this.level = 1;
            this.currentlevelname = 'Section';
            this.loadlibrary();
        },

        libraryChange: function (event) {
            //clear selected levels
            this.detailform.bqcode = event == 'ROADWORK' ? '' : "M00.00.00.00.00";
            this.detailform.bqcodename = '';
            this.detailform.section = null;
            this.detailform.sectioncode = null;
            this.detailform.group = null;
            this.detailform.groupcode = null;
            this.detailform.work = null;
            this.detailform.workcode = null;
            this.detailform.level1 = null;
            this.detailform.level1code = null;
            this.detailform.level2 = null;
            this.detailform.level2code = null;
            this.detailform.level3 = null;
            this.detailform.level3code = null;
            this.detailform.unit = null;
            this.detailform.composite = false;
            this.detailform.istolerance = false;
            this.detailform.keepbqcode = false;

            this.level = 1;
            this.currentlevelname = 'Section';
            this.loadlibrary();
        },

        loadlibrary: function () {
            this.loadinglibrary = true;
            if (this.level == 1) {
                this.groupid[this.level] = 0;
            }
            let url = "library/" + this.detailform.library + "/" + this.groupid[this.level];
            this.$http.get(url, {})
                .then(response => {
                    this.library = JSON.parse(response.data)
                    this.loadinglibrary = false;
                })
                .catch(err => {
                    this.loadinglibrary = false;
                    this.$bus.$emit('error', err);
                });
        },

        levelUpClick: function () {
            let newevn = this.detailform.bqcode.split(".");
            if (this.level > 1) {
                if (this.detailform.library == 'SMM7' || this.detailform.library == "NRM2") {
                    if (this.level == 2) {
                        let val = this.detailform.sectioncode == null ? 'M' : this.detailform.sectioncode.replace(/[^0-9a-z]/gi, '');
                        this.detailform.bqcode = `${val}00.${this.setcode(newevn, 1)}.${this.setcode(newevn, 2)}.${this.setcode(newevn, 3)}.${this.setcode(newevn, 4)}`;
                        this.currentlevelname = 'Section';
                    }
                    else {
                        if (this.level == 3) {
                            this.detailform.bqcode = `${this.setcode(newevn, 0)}.00.${this.setcode(newevn, 2)}.${this.setcode(newevn, 3)}.${this.setcode(newevn, 4)}`;
                            this.currentlevelname = 'Group';

                        }
                        if (this.level == 4) {
                            this.detailform.bqcode = `${this.setcode(newevn, 0)}.${this.setcode(newevn, 1)}.00.${this.setcode(newevn, 3)}.${this.setcode(newevn, 4)}`;
                            this.currentlevelname = 'Item or Work';
                        }
                        if (this.level == 5) {
                            this.detailform.bqcode = `${this.setcode(newevn, 0)}.${this.setcode(newevn, 1)}.${this.setcode(newevn, 2)}.00.${this.setcode(newevn, 4)}`;
                            this.currentlevelname = 'Level 1';
                        }
                        if (this.level == 6) {
                            this.detailform.bqcode = `${this.setcode(newevn, 0)}.${this.setcode(newevn, 1)}.${this.setcode(newevn, 2)}.${this.setcode(newevn, 3)}.00`;
                            this.currentlevelname = 'Level 2';
                        }
                    }
                }
                this.level -= 1;
            }
            else {
                this.detailform.bqcode = `M00.${this.setcode(newevn, 1)}.${this.setcode(newevn, 2)}.${this.setcode(newevn, 3)}.${this.setcode(newevn, 4)}`;
                this.currentlevelname = 'Section';
            }
            this.loadlibrary();
        },
        setcode(array, ind) {
            if (!array) {
                return "00";
            }
            if (array[ind] != undefined) {
                return array[ind];
            } else {
                return "00";
            }
        },
        librarydblclick: function (row) {
            let newevn = this.detailform.bqcode.split(".");
            let checkcode = row.code == null ? '' : row.code.replace(/[^0-9a-z]/gi, '');
            this.detailform.unit = null;
            if (this.detailform.library == 'SMM7' || this.detailform.library == "NRM2") {
                if (this.level != 1) {
                    checkcode = checkcode == "" ? "00" : checkcode;
                    checkcode = checkcode.length == 1 ? `0${checkcode}` : checkcode;
                }
            }
            switch (this.level) {
                case 1:
                    if (this.detailform.library == 'SMM7' || this.detailform.library == "NRM2") {
                        // let split_string = this.setcode(newevn, 0).split(/(\d+)/);
                        // this.detailform.bqcode = `${checkcode + split_string[1]}.${this.setcode(newevn, 1)}.${this.setcode(newevn, 2)}.${this.setcode(newevn, 3)}.${this.setcode(newevn, 4)}`;

                        let secondcode = this.detailform.groupcode == null ? '' : this.detailform.groupcode.replace(/[^0-9a-z]/gi, '');
                        secondcode = secondcode == "" ? "00" : secondcode;
                        secondcode = secondcode.length == 1 ? `0${secondcode}` : secondcode;
                        this.detailform.bqcode = `${checkcode + secondcode}.${this.setcode(newevn, 1)}.${this.setcode(newevn, 2)}.${this.setcode(newevn, 3)}.${this.setcode(newevn, 4)}`;
                        this.newcodes.l1 = checkcode;
                    }
                    this.detailform.section = row.description;
                    this.detailform.sectioncode = row.code;
                    this.currentlevelname = 'Group';
                    break;
                case 2:
                    if (this.detailform.library == 'SMM7' || this.detailform.library == "NRM2") {
                        // let split_string = this.setcode(newevn, 0).split(/(\d+)/);
                        // this.detailform.bqcode = `${split_string[0]}${checkcode}.${this.setcode(newevn, 1)}.${this.setcode(newevn, 2)}.${this.setcode(newevn, 3)}.${this.setcode(newevn, 4)}`;
                        this.detailform.bqcode = `${this.newcodes.l1}${checkcode}.${this.setcode(newevn, 1)}.${this.setcode(newevn, 2)}.${this.setcode(newevn, 3)}.${this.setcode(newevn, 4)}`;
                        this.newcodes.l2 = checkcode;
                    }
                    this.detailform.group = row.description;
                    this.detailform.groupcode = row.code;
                    this.currentlevelname = 'Item or Work';
                    break;
                case 3:
                    if (this.detailform.library == 'SMM7' || this.detailform.library == "NRM2") {
                        this.detailform.bqcode = `${this.setcode(newevn, 0)}.${checkcode}.${this.setcode(newevn, 2)}.${this.setcode(newevn, 3)}.${this.setcode(newevn, 4)}`;
                    }
                    this.detailform.work = row.description;
                    this.detailform.workcode = row.code;
                    if (row.unit != null && this.units.indexOf(row.unit) >= 0) {
                        this.detailform.unit = row.unit;
                    }
                    this.currentlevelname = 'Level 1';
                    break;
                case 4:
                    if (this.detailform.library == 'SMM7' || this.detailform.library == "NRM2") {
                        this.detailform.bqcode = `${this.setcode(newevn, 0)}.${this.setcode(newevn, 1)}.${checkcode}.${this.setcode(newevn, 3)}.${this.setcode(newevn, 4)}`;
                    }
                    this.detailform.level1 = row.description;
                    this.detailform.level1code = row.code;
                    if (row.unit != null && this.units.indexOf(row.unit) >= 0) {
                        this.detailform.unit = row.unit;
                    }
                    this.currentlevelname = 'Level 2';
                    break;
                case 5:
                    if (this.detailform.library == 'SMM7' || this.detailform.library == "NRM2") {
                        this.detailform.bqcode = `${this.setcode(newevn, 0)}.${this.setcode(newevn, 1)}.${this.setcode(newevn, 2)}.${checkcode}.${this.setcode(newevn, 4)}`;
                    }
                    this.detailform.level2 = row.description;
                    this.detailform.level2code = row.code;
                    this.currentlevelname = 'Level 3';
                    if (row.unit != null && this.units.indexOf(row.unit) >= 0) {
                        this.detailform.unit = row.unit;
                    }
                    break;
                case 6:
                    if (this.detailform.library == 'SMM7' || this.detailform.library == "NRM2") {
                        this.detailform.bqcode = `${this.setcode(newevn, 0)}.${this.setcode(newevn, 1)}.${this.setcode(newevn, 2)}.${this.setcode(newevn, 3)}.${checkcode}`;
                    }
                    this.detailform.level3 = row.description;
                    this.detailform.level3code = row.code;
                    if (row.unit != null && this.units.indexOf(row.unit) >= 0) {
                        this.detailform.unit = row.unit;
                    }
                    break;
            }
            if (this.level < 6 && this.level >= 1) {
                this.level += 1;
                this.groupid[this.level] = row.nextgroupid;
            }
            this.loadlibrary();
        },
        save: function (item, event) {
            if (this.isvalid()) {
                this.detailform.bqcode = this.detailform.bqcode.concat(this.detailform.bqcodename);
                this.detailform.lowertolerance = this.detailform.lowertolerance == null ? 0 : this.detailform.lowertolerance;
                this.detailform.uppertolerance = this.detailform.uppertolerance == null ? 0 : this.detailform.uppertolerance;
                this.$http.post("bqcodelibrary/save?isarchive=" + this.additionalfilter, this.detailform)
                    .then(response => {
                        this.$message({
                            showClose: true,
                            type: 'success',
                            message: response.data
                        });
                        this.detail = false;
                        this.openreport = false;
                        this.refresh();
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    });
            }
        },
        saveas: function (item, event) {
            this.$prompt('Please enter new BQ Code', 'Tip', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputValue: this.detailform.bqcodename + " - Copy"
            }).then(value => {
                this.detailform.id = 0;
                this.detailform.bqcodename = value.value;
                this.save();
            });
        },

        handleSelect(item, value) {
            item.bqcodelibraryid = value.id;
            item.bqcodelibrary = value;
        },

        querySearch: function (query, done) {
            if (timeout) {
                clearTimeout(timeout)
            }

            timeout = setTimeout(() => {

                this.$http.post('bqcodelibrary/search?q=' + query, {})
                    .then(response => {
                        this.bqcodes = JSON.parse(response.data);
                        done(this.bqcodes)
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    })

            }, 200);
        },
        splitbqcode(row) {
            let value = row.bqcode.trim();
            let firstunderscore = value.indexOf('_');
            if (firstunderscore >= 0) {
                row.bqcode = value.slice(0, firstunderscore);
                row.bqcodename = value.slice(firstunderscore, value.length);
            } else {
                row.bqcode = value;
                row.bqcodename = "";
            }
        },
        handleEdit: function (scope, row) {
            let url = 'bqcodelibrary/' + row.id + "?isarchive=" + this.additionalfilter;
            this.$http.get(url, {})
                .then(response => {
                    let json = JSON.parse(response.data);
                    json.editedonstr = moment(json.editedon).format("DD-MMM-YYYY")
                    this.splitbqcode(json);
                    this.detailform = json;
                    this.detail = true;

                    this.level = 1;
                    this.currentlevelname = 'Section';
                    this.loadlibrary();
                    this.toleranceChange();

                }).catch(err => {
                    this.$bus.$emit('error', err);
                });
        },

        handleDelete: function (scope, row) {
            this.$confirm('This will permanently delete ' + row.bqcode + '. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$http.post("bqcodelibrary/delete?isarchive=" + this.additionalfilter, row)
                    .then(response => {
                        this.refresh();
                        this.$message({
                            showClose: true,
                            type: 'success',
                            message: 'Delete completed'
                        });
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    })
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        onSearch: function (currentfilter, value) {
            if (value) {
                this.view.filter[currentfilter].value = value.toString();
            } else {
                this.view.filter[currentfilter].value = "";
            }

            this.view.pageno = 1;
            this.refresh();
        },
        currentChange: function (val) {
            this.view.pageno = val;
            this.refresh();
        },

        rowperpages: async function (val) {
            try {
                this.usergridrow.ugv.rowperpage = val;
                this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                this.view.pageno = 1;
                this.view.pagesize = val;
                this.refresh();
            } catch (error) {
                this.$bus.$emit('error', error);
            }
        },

        sortChange: function (sort) {
            this.view.sortcol = "bq." + sort.prop;
            this.view.sortorder = sort.order;
            this.refresh()
        },
        refresh: function () {
            if (this.view.filter[3].value) {
                this.view.filter[3].value = this.view.filter[3].value.toString();
            }

            this.view.defaultfilter = this.sectionfilter;

            // this.loading = true;
            this.$store.state.isLoading = true;

            this.$http.post("bqcodelibrary/getgrid?isarchive=" + this.additionalfilter, this.view)
                .then(response => {
                    var json = response.data;
                    this.tabledata = json.data;
                    this.view = json.view;

                    if (this.view.filter[3].value) {
                        let authorid = parseInt(this.view.filter[3].value);
                        this.view.filter[3].value = authorid;
                    }

                    // this.loading = false;
                    this.$store.state.isLoading = false;

                    if (this.users.length == 0) {
                        this.getAllUsers();
                    }

                }).catch(err => {
                    // this.loading = false;
                    this.$store.state.isLoading = false;
                    this.$bus.$emit('error', err);
                });
        },
        toleranceChange: function () {
            var lower = parseFloat(this.detailform.lowertolerance);
            var upper = parseFloat(this.detailform.uppertolerance);
            window.setTimeout(() => {
                this.detailform.lowertolerance = (isNaN(lower)) ? null : lower.toFixed(3);
                this.detailform.uppertolerance = (isNaN(upper)) ? null : upper.toFixed(3);
            }, 100)
        },
        isvalid: function () {
            var msg = [];
            if (!this.detailform.bqcode || !this.detailform.bqcode.trim())
                msg.push("BQ Code must not be empty");
            if (!this.detailform.bqcodename || this.detailform.bqcodename.trim() == '')
                msg.push("BQ Code Name must not be empty");
            if (this.detailform.bqcodename != undefined || this.detailform.bqcodename.trim() != '') {
                let firstletter = this.detailform.bqcodename.trim()[0];
                if (firstletter != '_') {
                    msg.push("BQ Code Name must begin with an underscore.");
                }
                if (this.detailform.bqcodename.length < 5) {
                    msg.push("BQ Code Name must be greater than 5 characters.");
                }
            }
            if (!this.detailform.section)
                msg.push("Section must not be empty");
            if (!this.detailform.group)
                msg.push("Group must not be empty");
            if (!this.detailform.work)
                msg.push("Item or work must not be empty");
            if (!this.detailform.level1)
                msg.push("Level1 must not be empty");
            if (this.detailform.level1 != undefined && this.detailform.level1.length > 255)
                msg.push("Your data within level1 exceed 255 characters limitation. Reduce unnecessary information or, if required, separate to below levels");
            if (this.detailform.level2 != undefined && this.detailform.level2.length > 255)
                msg.push("Your data within level2 exceed 255 characters limitation. Reduce unnecessary information or, if required, separate to below levels");
            if (this.detailform.level3 != undefined && this.detailform.level3.length > 255)
                msg.push("Your data within level3 exceed 255 characters limitation. Reduce unnecessary information or, if required, separate to below levels");

            if (!this.detailform.unit)
                msg.push("Unit must not be empty");


            if (this.detailform.work && !this.detailform.workcode)
                msg.push("Item or Work code is empty, please select it from library");

            if (this.detailform.level1 && !this.detailform.level1code)
                msg.push("Level 1 code is empty, please select it from library");

            if (msg.length > 0) {
                this.errorobject = {
                    message: msg, type: "required", visible: true,
                };
                this.validationerror = true;
                return false;
            }
            return true;
        },

        getheight() {
            this.height = (document.documentElement.clientHeight - 120);
        },

        getAllUsers() {
            this.$http.get("account/GetUsers")
                .then((response) => {
                    this.users = JSON.parse(response.data);
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                })
        },

        addmultiarchive() {
            var newarray = [];
            this.archivearray.forEach(e => {
                newarray.push(this.tabledata[e]);
            });
            this.$http.post('bqcodelibrary/movemultiplebqcodestoarchive', newarray)
                .then(response => {
                    this.$message({
                        showClose: true,
                        message: response.data, type: "success"
                    });
                    this.archivearray = [];
                    this.isarchiveselect = false;
                    this.$nextTick(() => {
                        this.refresh();
                    })
                })
                .catch(err => {
                    this.$bus.$emit('error', err);
                });
        },

        selectarchive(data, index) {
            if (data.isarchive == true) {
                this.archivearray.push(index);
            } else {
                var ind = this.archivearray.findIndex(x => x == index);
                this.archivearray.splice(ind, 1);
            }
            if (this.archivearray.length == 0) {
                this.isarchiveselect = false;
            } else {
                this.isarchiveselect = true;
            }
        },

        back() {
            this.detail = false;
            this.openreport = false;
        },

        openviewreport() {
            this.openreport = this.openreport == true ? false : true;
            this.showdialogue = false;
            let itm = {
                isexcluded: false,
                reporttype: "Project Use",
                bqcodelibraryid: 0,
                totaluse: null,
                lastusedate: null,
                last2yearsuse: null,
                asterisk: null,
                other: null,
                specification: null,
                startdate: null,
                enddate: null
            };
            itm.bqcodelibraryid = this.detailform.id;
            this.bqcodeview = itm;
            this.getcurrentmonth();
        },

        getcurrentmonth() {
            let currentdate = new Date();
            let day = currentdate.getDate();
            let month = currentdate.getMonth();
            let year = currentdate.getFullYear() - 1;
            let previousmonth = new Date(year, month, day);
            this.bqcodeview.startdate = moment(previousmonth).format("YYYY-MM-DD").toString();
            this.bqcodeview.enddate = moment(new Date()).format("YYYY-MM-DD").toString();
        },

        changereporttype() {
            this.reportdata = [];
        },

        dateformatter: function (row, column, cellValue) {
            if (cellValue) {
                return moment(cellValue).format("DD-MMM-YY");
            }
            return null;
        },

        getsummarydata() {
            this.showloading();
            let data = { id: this.detailform.id };
            this.$http.post('bqcodelibrary/getbqcodesummary', data)
                .then(response => {
                    this.bqcodeview.totaluse = response.data.totaluse;
                    this.bqcodeview.lastusedate = response.data.lastusedate;
                    this.bqcodeview.last2yearsuse = response.data.last2yearsuse;
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        validateregex() {
            let specification = true;
            let asterisk = true;
            let other = true;
            specification = this.$training.checkregex(this.bqcodeview.specification, 'bqcodeviewspecificationinput');
            asterisk = this.$training.checkregex(this.bqcodeview.asterisk, 'bqcodeviewasteriskinput');
            other = this.$training.checkregex(this.bqcodeview.other, 'bqcodeviewotherinput');
            if (!specification || !asterisk || !other) {
                let style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert("Invalid Regular Expression." + style, "Validation", {
                    dangerouslyUseHTMLString: true,
                });
                return false;
            } else {
                return true;
            }
        },

        createreport() {
            if (this.validateregex()) {
                this.showloading();
                this.reportdata = [];
                this.$http.post('bqcodelibrary/getbqcodeviewreport', this.bqcodeview)
                    .then(response => {
                        this.reportdata = response.data;
                        this.showdialogue = true;
                        this.hideloading();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    });
            }
        },

        gotoprojectpage(row) {
            var partid = row.pp.id == null ? 0 : row.pp.id;
            this.$store.state.projectids = {
                projectid: row.p.id,
                partid: partid,
                sectionid: row.ps.id
            };
            let data = this.$store.state.projectids;
            this.$bus.$emit("addproject", data);
            let parambq = `${this.detailform.bqcode}${this.detailform.bqcodename}`;
            let p = {
                name: 'project',
                path: '/projectpage',
                params: { bqcodeusageproject: parambq }
            };
            this.$router.push(p);
        },

        gotomctemplate(row) {
            let mctemplate = {
                name: 'template',
                path: '/templatelibrary',
                params: { bqcodeusagetemplate: row.t }
            };
            this.$router.push(mctemplate);
        },

        reportcellstyle({ row, column, rowIndex }) {
            let firstrow = {
                "background-color": "rgb(105, 104, 104)",
                "font-weight": "bold",
                "color": "white",
                "padding": "3px"
            };
            if (rowIndex == 0) {
                return firstrow;
            }
            let secondrow = {
                "background-color": "#BBBBBB",
                "font-weight": "bold",
                "color": "black",
                "padding": "3px"
            }
            if (rowIndex == 1) {
                return secondrow;
            }
        },

        mediadialogClose(done) {
            this.showdialogue = false;
            this.reportdata = [];
            done();
        },

        getallsection() {
            this.$http.get('project/GetSectionsProjectCreate')
                .then(response => {
                    this.sections = response.data;
                    this.$store.state.sections = this.sections;
                }).catch(err => {
                    this.$bus.$emit('error', err);
                });
        },

        sectionfilterchanged() {
            this.view.pageno = 1;
            this.refresh();
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.getheight);
    },
    mounted: function () {
        window.addEventListener('resize', this.getheight);
    },
    created: function () {
        if (this.$store.state.project.library) {
            this.view.filter[0].value = this.$store.state.project.library;
        }
        let gridrow = this.$training.getusergridrow(this.$route);
        this.view.pagesize = gridrow.ugv.rowperpage;
        this.usergridrow = gridrow;

        this.refresh(); //not require here because default refersh is called from sortChange

        if (this.$store.state.sections == undefined || this.$store.state.sections.length == 0) {
            this.getallsection();
        } else {
            this.sections = this.$store.state.sections;
        }
    },
}
</script>
