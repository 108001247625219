<template>
  <div>
    <transition name="fade" mode="in-out">
      <div
        ref="whiteBackground"
        id="whiteBackground"
        draggable="true"
        class="draggable"
        v-if="boxdata.visible"
      >
        <div>
          <div>
            <button @click="dlgClose" class="close-button">&times;</button>
            <br />
            <div>
              <div>
                <div class="marginleft10px">
                  <b class="heading">
                    The dropdown below will add <span class="marroncolor">the same</span> BQ_Code<br>
                    for all rows under this column headings(CH)
                    <!-- <br />&nbsp;&nbsp; section up to next SN/CH row. -->
                  </b>
                </div>
                
                <div class="marginttopbottom10px margintleftright15px">
                  <el-autocomplete
                    class="inline-input fullwidth"
                    popper-class="morewidth-autocomplete"
                    v-model="bqcode"
                    :fetch-suggestions="
                      (query, done) => {
                        querySearchBQCode(
                          query,
                          done,
                          boxdata.data.pa,
                          boxdata.data.pad,
                          boxdata.data.index
                        );
                      }
                    "
                    placeholder=""
                    @select="handleSelectBQCodemane(boxdata.data.pa, $event)"
                    :select-when-unmatched="true"
                    value-key="bqcode"
                    :trigger-on-focus="true"
                    :clearable="false"
                    @Clear="handleSelectBQCodemane(boxdata.data.pa, $event)"
                  >
                    <template slot-scope="{ item }">
                      <div v-if="item.id > 0">                        
                        <div style="white-space: normal">
                          <span
                          :style="item.fromamt ? 'font-weight: bold;' : ''"
                          >{{ item.bqcode }}</span
                          >
                        </div>

                        <div style="white-space: normal; font-size: x-small">
                          <span :style="item.fromamt ? 'font-weight: bold;' : ''"
                          >{{ item.section }} | {{ item.group }} |
                          {{ item.work }} | {{ item.level1 }} |
                          {{ item.level2 }} | {{ item.level3 }}</span>
                        </div>
                      </div>
                    </template>
                    <el-button
                      :disabled="!bqcode"
                      slot="suffix"
                      icon="el-icon-edit"
                      @click="
                        bqcodelibraryid = boxdata.data.pad.bqcodelibrary.id;
                        projectbqcode = true;
                      "
                      type="text"
                      size="mini"
                    >
                    </el-button>
                  </el-autocomplete>
                  
                  <el-button
                  class="floatright margintop10px"
                  type="success"
                  plain
                  @click="confirm()"
                  :disabled="!bqcode.trim()"
                    >Confirm</el-button>
                </div>
                <!-- <div>
                  <span class="warnfooter">
                    <br />Do you confirm that all items within this section
                    <br />&nbsp;&nbsp; are of similar nature and suit selected
                    BQ code?
                  </span>
                </div> -->
                <br />
              </div>
              <!--<el-button
                style="float: right"
                type="success"
                plain
                @click="confirm"
                >Confirm</el-button
              >-->
            </div>
          </div>
        </div>
      </div>

      
      <div id="whiteBackground"
        ref="whiteBackground"
        draggable="true"
        class="draggable width1142px padding_TB5px_LR10px"
        v-if="boxdata.visibleCHSettingBox">
        <div class="amoschboxheadingstyle">
          <span class="bold fontsize12">
             Please select which choices to copy from the previously selected
              <!-- The BQ_Code matches the CH View below do you want to use these settings again? -->
          </span>
          
          <span class="bold fontsize12 marginright35px">
              Tick here<el-checkbox v-model="tickallchcolumns" @change="changedallcolumnstick" class="black-checkbox"></el-checkbox>for all, or Select as tickboxes below, or 'x' to return to AMOS
          </span>

          <span>
            <el-button
              @click="confirmCHSettingBox()"
              class="padding6px15px"
              type="success"
              plain>Confirm
            </el-button>

            <button class="fontsize14px bold marginLeft6px" @click="dlgClose">&times;</button>
          </span>
        </div>

        <div>
          <el-table :data="CHRowData" :key="tableKey" ref="dataTable" style="width: 100%; border: 1px solid;">
            <el-table-column prop="" label="AM_T" width="100">
              <template slot="header">
                <el-popover placement="top" trigger="hover" content="Automated Measurement Template">
                  <span slot="reference">
                    <el-button type="info" plain><b>AM_T</b></el-button>
                    <el-checkbox v-model="CHRowData[0].isAMTTicked" @change="changedcolumnheadertick($event, 'isAMTTicked')" class="marginl5"></el-checkbox>
                  </span>
                </el-popover>
              </template>

              <template slot-scope="scope">
                <el-select v-model="scope.row.amtemplateid" class="disinput" disabled>
                  <el-option v-for="item in scope.row.amtoptions"
                              :key="item.id"
                              :label="item.amtemplatename"
                              :value="item.id">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column prop="" label="NRM Sort" width="180">
              <template slot="header">
                <el-button class="nrmslot" type="info" plain><b>NRM Sort</b></el-button>
                <el-checkbox v-model="CHRowData[0].isNRMSortTicked" @change="changedcolumnheadertick($event, 'isNRMSortTicked')" class="marginl5" :disabled="!CHRowData[0].isAMTTicked"></el-checkbox>
              </template>

              <template slot-scope="scope">
                <el-select v-model="scope.row.nrmsortingref" class="fullwidth disinput" disabled>
                    <el-option v-for="item in scope.row.nrmsortoptions"
                                :key="item.id"
                                :label="item.description"
                                :value="item.description">
                    </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column prop="" label="BQ_Code" width="220">
              <template slot="header">
                <el-button type="info" plain><b>BQ_Code</b></el-button>
                <!-- <el-checkbox v-model="CHRowData[0].isBQCodeTicked" @change="changedcolumnheadertick($event, 'isBQCodeTicked')" class="marginl5"></el-checkbox> -->
              </template>

              <template>
                <!-- <el-autocomplete
                    class="inline-input fullwidth"
                    popper-class="morewidth-autocomplete"
                    v-model="bqcode"
                    :fetch-suggestions="
                      (query, done) => {
                        querySearchBQCode(
                          query,
                          done,
                          boxdata.data.pa,
                          boxdata.data.pad,
                          boxdata.data.index
                        );
                      }
                    "
                    placeholder=""
                    @select="handleSelectBQCodemane(boxdata.data.pa, $event)"
                    :select-when-unmatched="true"
                    value-key="bqcode"
                    :trigger-on-focus="true"
                    :clearable="false"
                    @Clear="handleSelectBQCodemane(boxdata.data.pa, $event)"
                  >
                    <template slot-scope="{ item }">
                      <div v-if="item.id > 0">                        
                        <div style="white-space: normal">
                          <span
                          :style="item.fromamt ? 'font-weight: bold;' : ''"
                          >{{ item.bqcode }}</span
                          >
                        </div>

                        <div style="white-space: normal; font-size: x-small">
                          <span :style="item.fromamt ? 'font-weight: bold;' : ''"
                          >{{ item.section }} | {{ item.group }} |
                          {{ item.work }} | {{ item.level1 }} |
                          {{ item.level2 }} | {{ item.level3 }}</span>
                        </div>
                      </div>
                    </template>
                    <el-button
                      :disabled="!bqcode"
                      slot="suffix"
                      icon="el-icon-edit"
                      @click="
                        bqcodelibraryid = boxdata.data.pad.bqcodelibrary.id;
                        projectbqcode = true;
                      "
                      type="text"
                      size="mini"
                    >
                    </el-button>
                  </el-autocomplete> -->


                <el-input v-model="chbqcode" class="inline-input fullwidth" readonly></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="" label="Qty" width="60" align="center">
              <template slot="header">
                <b>Qty</b>
                <el-checkbox v-model="CHRowData[0].isQtyTicked" @change="changedcolumnheadertick($event, 'isQtyTicked')" class="marginl5"></el-checkbox>
              </template>

              <template slot-scope="scope">
                <el-select v-model="scope.row.qty" class="disinput" filterable placeholder="Qty" disabled>
                    <template slot="prefix">
                        <el-tag v-if="scope.row.qty" style="color: #909399; margin: 4px 0px 2px 0px;">{{scope.row.qty}}</el-tag>
                    </template>
                    <el-option v-for="item in scope.row.qtycolumns"
                                :key="item.key"
                                :label="item.key"
                                :value="item.key">
                    </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column label="Specification" width="180">
              <template slot="header">
                <el-button type="info" plain><b>Specification</b></el-button>
                <el-checkbox v-model="CHRowData[0].isSpecificationTicked" @change="changedcolumnheadertick($event, 'isSpecificationTicked')" class="marginl5"></el-checkbox>
              </template>

              <template slot-scope="scope">
                <el-select v-model="scope.row.lstspecification" multiple placeholder="Specification" class="selecttag selectinputleft5 disinput" style="width:177px;" disabled>
                  <el-option v-for="item in scope.row.specolumns"
                              :key="item.key"
                              :label="item.key"
                              :value="item">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column prop="" label="Asterisk" width="180">
              <template slot="header">
                <el-button type="info" plain><b>Asterisk</b></el-button>
                <el-checkbox v-model="CHRowData[0].isAsteriskTicked" @change="changedcolumnheadertick($event, 'isAsteriskTicked')" class="marginl5"></el-checkbox>
              </template>

              <template slot-scope="scope">
                <el-select v-model="scope.row.lstasterisk" multiple class="selecttag selectinputleft5 disinput" placeholder="Asterisks" style="width:177px;" disabled>
                  <el-option v-for="item in scope.row.astcolumns"
                              :key="item.key"
                              :label="item.key"
                              :value="item">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column prop="" label="End Notes" width="255">
              <template slot="header">
                <el-button type="info" plain><b>End Notes</b></el-button>
                <el-checkbox v-model="CHRowData[0].isEndNotesTicked" @change="changedcolumnheadertick($event, 'isEndNotesTicked')" class="marginl5"></el-checkbox>
              </template>

              <template slot-scope="scope">
                <el-input :spellcheck="isspellcheck" v-model="scope.row.endnotes" readonly></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="margintop5">
          <span class="bold fontsize12">
            Tick here<el-checkbox v-model="CHRowData[0].ischrowcopytoall" @change="changedtickofcopyCHTOAllRows" class="black-checkbox"></el-checkbox>to copy selection for all upcoming CH rows
          </span>
        </div>
      </div>
    </transition>
    <el-dialog
      v-if="projectbqcode"
      title="Project BQCode"
      :visible.sync="projectbqcode"
      width="90%"
    >
      <projectbqcode
        :projectsectionid.sync="boxdata.sectionid"
        :bqcodelibraryid.sync="this.newBQCode.bqcodelibrary.id"
        :bqcodelibrary="false"
        @closedialogue="closedialogue"
      >
      </projectbqcode>
    </el-dialog>
  </div>
</template>
<script>
import projectbqcode from "./projectbqcode.vue";
export default {
  props: { boxdata: Object },
  data() {
    return {
      verticalcenter: false,
      bqcode: "",
      newBQCode: {},
      bqcodelibraryid: 0,
      projectbqcode: false,
      amoschsettingbox: { visible: false },
      tickallchcolumns: false,
      CHRowData: [{
          isAMTTicked: false,
          isNRMSortTicked: false,
          isQtyTicked: false,
          isSpecificationTicked: false,
          isAsteriskTicked: false,
          isEndNotesTicked: false,
          ischrowcopytoall: false
      }],
      tableKey: 0,
      ischrowcopytoall: false,
      chbqcode: "",
      chnewBQCode: {}
    };
  },

  watch: {
    boxdata: {
      deep: true,
      handler(newVal) {
        if (newVal.visibleCHSettingBox) {
          this.copyCHSetting();
        }
      }
    }
  },
  
  methods: {
    closedialogue: function () {
      this.projectbqcode = false;
      if (this.boxdata.changed) {
        // this.save();
        this.$emit("save");
      } else {
        // this.refresh();
        this.$emit("refresh");
      }
    },
    confirm() {
      if (this.CHRowData.length > 0) {
        // this.boxdata.visible = false;
        this.tickallchcolumns = false;
        this.CHRowData[0].isAMTTicked = false;
        this.CHRowData[0].isNRMSortTicked = false;
        // this.CHRowData[0].isBQCodeTicked = false;
        this.CHRowData[0].isQtyTicked = false;
        this.CHRowData[0].isSpecificationTicked = false;
        this.CHRowData[0].isAsteriskTicked = false;
        this.CHRowData[0].isEndNotesTicked = false;

        if (this.CHRowData[0].ischrowcopytoall) {
          this.CHRowData[0].ischrowcopytoall = false;
        }

        // this.boxdata.data.previousCHRow = this.CHRowData[0];
        // this.amoschsettingbox.visible = true;
        // return;
      } 
      // else if (!isCHSettingDialogue) {
      //   this.CHRowData = [{isBQCodeTicked: true}];
      //   this.boxdata.data.previousCHRow = this.CHRowData[0];
      // }
      
      this.$emit("handleSelectBQCodeall", this.boxdata, this.newBQCode);
      this.boxdata.data = {};
      this.newBQCode = {};
      this.bqcode = "";
      this.boxdata.visible = false;
      this.boxdata.visibleCHSettingBox = false;
      // this.amoschsettingbox.visible = false;
    },
    querySearchBQCode: function (query, done, pa, pad, index) {
      this.$http
        .post(
          "bqcodelibrary/search?library=" +
            this.boxdata.data.library +
            "&q=" +
            query,
          {}
        )
        .then((response) => {
          var newBQ = JSON.parse(response.data);
         var bqcodes = [];
                        if (query == "") {
                            if (pad.bqcodeoptions.length > 0) {
                                var ids = new Set(pad.bqcodeoptions.map(d => d.id));
                                    bqcodes = [...pad.bqcodeoptions, ...newBQ.filter(d => !ids.has(d.id))];
                             }
                             else {
                                 bqcodes = newBQ;
                            }
                        }
                        else {
                            bqcodes = newBQ;
                        }
                        done(bqcodes);
        })
        .catch((err) => {
          var bqcodes = [];
          done(bqcodes);
          this.$bus.$emit("error", err);
        });
    },
    handleSelectBQCodemane: function (item, value) {
      this.newBQCode.id = value.id;
      this.newBQCode.bqcodelibrary = value;
      this.newBQCode.unit = value.unit;

      this.chnewBQCode = JSON.parse(JSON.stringify(this.newBQCode))
      this.chbqcode = this.bqcode;
      this.copyCHSetting();
    },

    changedallcolumnstick: function(event) {
      this.CHRowData[0].isAMTTicked = event;
      this.CHRowData[0].isNRMSortTicked = event;
      // this.CHRowData[0].isBQCodeTicked = event;
      this.CHRowData[0].isQtyTicked = event;
      this.CHRowData[0].isSpecificationTicked = event;
      this.CHRowData[0].isAsteriskTicked = event;
      this.CHRowData[0].isEndNotesTicked = event;

      this.tableKey = this.tableKey == 0 ? 1 : 0;
      
      // this.$nextTick(() => {
      //   this.$refs.dataTable.doLayout(); // Call doLayout() on the el-table instance to re-render the table
      // });
    },

    changedcolumnheadertick: function(event, columnname) {
      this.CHRowData[0][columnname] = event;

      if (!this.CHRowData[0].isAMTTicked) {
        this.CHRowData[0].isNRMSortTicked = false;
      }

      if (this.CHRowData[0].isAMTTicked && this.CHRowData[0].isNRMSortTicked && this.CHRowData[0].isQtyTicked &&
          this.CHRowData[0].isSpecificationTicked && this.CHRowData[0].isAsteriskTicked && this.CHRowData[0].isEndNotesTicked) {
        this.tickallchcolumns = true;
      } else {
        this.tickallchcolumns = false;
      }

      this.tableKey = this.tableKey == 0 ? 1 : 0;
    },

    copyCHSetting: function() {
      let indices = [];
      let previousCHRowIndex = -1;
      let ispreviousCHRowFound = false;

      for (let i = 0; i < this.boxdata.data.pa.details.length; i++) {
        let currentrow = this.boxdata.data.pa.details[i];
        if (currentrow.predicate == "CH" && i != this.boxdata.data.index) {
          indices.push(i);

          if (!ispreviousCHRowFound) {
            previousCHRowIndex = i;
          }
        } else if (currentrow.predicate == "CH" && previousCHRowIndex !== -1) {
          const previousIndexPosition = indices.indexOf(previousCHRowIndex);
    
          if (previousIndexPosition !== -1) {
            indices.splice(previousIndexPosition, 1);
          }
          
          indices.unshift(previousCHRowIndex);
          ispreviousCHRowFound = true;
        }
      }

      let foundbqcodes = [], ismatchpreviousrowbqcode = false;
      for (let index of indices) {

        if (ismatchpreviousrowbqcode) {
          break;
        }

        for (let i = index + 1; i < this.boxdata.data.pa.details.length; i++) {
          let rowdetail = this.boxdata.data.pa.details[i];
          
          if (rowdetail.predicate == "Pre" && rowdetail.bqcodelibraryid) {
            let chrow = this.boxdata.data.pa.details[index];
            foundbqcodes.push(chrow);

            if (index == previousCHRowIndex && foundbqcodes.length > 0) {
              ismatchpreviousrowbqcode = true;
            }
            break;
          } 
          // else if (rowdetail.predicate == "CH") {
          //   break;
          // }

        }
      }
      
      // let foundbqcodes = this.boxdata.data.pa.details.filter(x => x.bqcodelibraryid == this.newBQCode.id && x.predicate == "CH");
      const propertiesToCheck = ['amtemplateid', 'nrmsortingref', 'bqcodelibraryid', 'lstspecification', 'lstasterisk', 'endnotes'];

      if (foundbqcodes.length == 1) {
        this.CHRowData = [foundbqcodes[0]];
      } 
      else if (foundbqcodes.length > 1) {
        let mostNonEmptyBQCode = foundbqcodes.reduce((acc, curr) => {
          let currNonEmptyCount = 0;
          let accNonEmptyCount = 0;

          // Iterate over the properties and count the number of non-empty values
          propertiesToCheck.forEach(property => {
            if (property === 'lstspecification' || property === 'lstasterisk') {
              
              // Check if specification is an array and contains elements
              if (Array.isArray(curr[property]) && curr[property].length > 0) {
                currNonEmptyCount++;
              }

              if (Array.isArray(acc[property]) && acc[property].length > 0) {
                accNonEmptyCount++;
              }

            } 
            else {
              // Check if the property is non-empty
              if (curr[property]) {
                currNonEmptyCount++;
              }

              if (acc[property]) {
                accNonEmptyCount++;
              }
            }
          });

          // Compare the non-empty counts and return the bqcode object with more non-empty properties
          return (currNonEmptyCount > accNonEmptyCount) ? curr : acc;
        });

        this.CHRowData = [mostNonEmptyBQCode];
      }
      else {
        this.CHRowData = [{}];
      }
    },

    confirmCHSettingBox: function(){
      if (this.CHRowData.length > 0) {
        this.boxdata.data.previousCHRow = this.CHRowData[0];
      }

      this.$emit("handleSelectBQCodeall", this.boxdata, this.chnewBQCode);

      if (this.CHRowData.length > 0) {
        // this.boxdata.visible = false;
        this.tickallchcolumns = false;
        this.CHRowData[0].isAMTTicked = false;
        this.CHRowData[0].isNRMSortTicked = false;
        // this.CHRowData[0].isBQCodeTicked = false;
        this.CHRowData[0].isQtyTicked = false;
        this.CHRowData[0].isSpecificationTicked = false;
        this.CHRowData[0].isAsteriskTicked = false;
        this.CHRowData[0].isEndNotesTicked = false;

        if (this.CHRowData[0].ischrowcopytoall) {
          this.CHRowData[0].ischrowcopytoall = false;
        }

      }

      this.boxdata.data = {};
      this.newBQCode = {};
      this.bqcode = "";
      this.boxdata.visible = false;
      this.boxdata.visibleCHSettingBox = false;
    },

    disableConfirm: function() {
      if (this.CHRowData[0].isAMTTicked || this.CHRowData[0].isNRMSortTicked || this.CHRowData[0].isSpecificationTicked 
          || this.CHRowData[0].isAsteriskTicked || this.CHRowData[0].isEndNotesTicked) {
        return false;
      } 
      else {
        return true;
      }
    },

    dlgClose: function () {
      this.boxdata.visible = false;
      this.newBQCode = {};
      this.bqcode = "";
      // this.amoschsettingbox.visible = false;
      this.boxdata.visibleCHSettingBox = false;
    },

    drag_start(event) {
      var style = window.getComputedStyle(event.target, null);
      event.dataTransfer.setData(
        "text/plain",
        parseInt(style.getPropertyValue("left"), 10) -
          event.clientX +
          "," +
          (parseInt(style.getPropertyValue("top"), 10) - event.clientY)
      );
    },
    drag_over(event) {
      event.preventDefault();
      return false;
    },
    drop(event) {
      if (!event.dataTransfer) {
        return false;
      }
      var offset = event.dataTransfer.getData("text/plain").split(",");
      //var dm = this.$refs.whiteBackground;
      if (this.$refs.whiteBackground) {
      this.$refs.whiteBackground.style.left =
        event.clientX + parseInt(offset[0], 10) + "px";
      this.$refs.whiteBackground.style.top =
        event.clientY + parseInt(offset[1], 10) + "px";
      }
      event.preventDefault();
      return false;
    },
    makeDraggable() {
      var dm = this.$refs.whiteBackground;
      if (dm) {
        dm.addEventListener("dragstart", this.drag_start, false);
        document.body.addEventListener("dragover", this.drag_over, false);
        document.body.addEventListener("drop", this.drop, false);
      }
    },
    heightCount() {
      //   this.height = this.$refs.content.clientHeight;
      if (this.height <= 318.5 && this.height != 0) {
        this.verticalcenter = true;
      } else {
        this.verticalcenter = false;
      }
    },

    changedtickofcopyCHTOAllRows(event) {
      this.CHRowData[0].ischrowcopytoall = event;
    }
  },
  updated() {
    if (this.boxdata.visible || this.boxdata.visibleCHSettingBox) {
      this.$nextTick(this.makeDraggable);
      this.$nextTick(this.heightCount);
    }
  },
  created() {
    this.$nextTick(this.makeDraggable);
    this.$nextTick(this.heightCount);
  },
  components: {
    projectbqcode: projectbqcode,
  },
};
</script>
<style scoped>
#whiteBackground {
  /* width: 50%;
  height: 75%; */
  border: 2px solid darkgrey;
  background-color: #ececec;
  width: 390px;
  padding-bottom: 15px;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 1;
  align-items: center;
}

#dlgbox {
  position: fixed;
  width: 90%;
  height: 50%;
  margin: auto;
  background-color: lightgray;
  z-index: 1;
  /* border: 2px solid red; */
  border-radius: 25px;
}

.dlg-body {
  background-color: lightgray;
  color: black;
  font-size: 14px;
  padding-left: 58px;
  border: 4px solid black;
  min-height: 90%;
  /* border: 2px solid red; */
  border-radius: 25px;
}

.newimg {
  bottom: 0;
  position: absolute;
  left: 0;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 38%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.el-checkbox.is-bordered.el-checkbox--mini {
  padding: 4px 8px 7px 8px;
  border-radius: 3px;
  height: 26px;
  border: 1px solid black;
}

.warnfooter {
  font-size: 18px;
  color: darkred;
  font-weight: bold;
}

.close-button {
  float: right;
  position: sticky;
  /* top: 3px;
  right: 3px; */
  margin: 6px;
  font-weight: bold;
}

p {
  margin: 0 0 0px;
}

.heading {
  font-family: "Calibri", "Comic Sans MS", "Times New Roman", "Arial";
  font-size: 16px;
  font-weight: bold;
}

.fade-enter-active {
  animation: fadein 0.5s;
  animation-iteration-count: 1;
}

.fade-leave-active {
  animation: fadeout 0.7s;
  animation-iteration-count: 1;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>
