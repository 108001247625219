<template>
    <div>
        <div class="fixdheader">
            <div class="headerstyle">
                <span>Project</span>
                <span v-show="detail" class="floatright">
                    <el-button type="primary" 
                        class="marginleft4px padding7" 
                        @click="getfilehistory">File History
                    </el-button>

                    <el-button
                        slot=""
                        @click="cellchanged('save_project_project',()=>{save()},false)"
                        type="success"
                        :disabled="!editpermission"
                        v-if="detailform.section && !detailform.section.locked && iseditornot">
                        Save
                    </el-button>

                    <el-button
                        class="marginleft4px padding7px6px"
                        @click="showcreatebaseversiondialogue"
                        type="success"
                        :disabled="!editpermission"
                        v-if="detailform.section && !detailform.section.locked && iseditornot">
                        Create Base Version
                    </el-button>

                    <el-button type="primary"
                               class="padding7" 
                               @click="findbot" 
                               :disabled="!editpermission" 
                               v-if="detailform.section && !detailform.section.locked && iseditornot">
                               FIND</el-button>

                    <!-- <el-button type="primary"
                               class="padding7" 
                               @click="findbotview" 
                               :disabled="!editpermission" 
                               v-if="detailform.section && !detailform.section.locked && iseditornot">
                               View FIND</el-button> -->

                    <el-button type="primary"
                               class="padding7" 
                               @click="viewheader" 
                               :disabled="!editpermission" 
                               v-if="detailform.section && !detailform.section.locked && iseditornot">
                               View Header</el-button>
                </span>
            </div>

            <el-row>
                <!-- <el-col :span="20"> -->
                <projectselectpart :training="true" :absoluteurl="true" @projectsectionchange="projectsectionchange"></projectselectpart>
                <!-- </el-col> -->
            </el-row>

            <div class="row" v-show="detail">
                <div class="fixwidth">
                    <el-pagination
                        small
                        layout="prev, pager, next"
                        @current-change="pageCurrentChange"
                        :page-size="pagesize"
                        :total="total">
                    </el-pagination>
                </div>
                <div v-if="detailform && detailform.section">
                <el-tooltip  class="item" effect="dark" :content="getxmlstring(detailform)" placement="bottom-start">
                <div  class="el-input el-input--mini ellipsiswords xmldetailwidth  margintop3left8px">
                    <!-- style="font-family: Comic Sans MS, Times, serif" -->
                    <!-- <span
                        class="bold"
                        v-if="(detailform && detailform.section && detailform.section.lastimportfilename) ||
                                (detailform && detailform.section && detailform.section.amossavedtoprojecton)">
                                {{ "Data in use" }}
                    </span> -->


                    <span class="bold"
                        v-if="(detailform && detailform.section && detailform.section.lastimportfilename) ||
                        detailform.section.xmlimportedon">
                        {{ "XML:" }}
                    </span>
                    <span>
                        {{getxmlstring(detailform)}}
                    </span>
                    <!-- <span
                        v-if="detailform && detailform.section && detailform.section.xmlbyloginname">
                        by {{ detailform.section.xmlbyloginname }}
                    </span>

                    <span
                        v-if="(detailform && detailform.section && detailform.section.lastimportfilename) || detailform.section.xmlimportedon">
                        {{ detailform.section.lastimportfilename }}
                        {{ detailform.section.xmlimportedon | datetimeformat }}
                    </span> -->
                   </div>
                     </el-tooltip>
                </div>
                <div  v-if="detailform && detailform.section">
                     <el-tooltip  class="item" effect="dark" :content="getamosstring(detailform)" placement="bottom-start">
                         <div class=" el-input el-input--mini fixwidth ellipsiswords margintop3left8px">
                    <span class="bold"
                        v-if="detailform && detailform.section && detailform.section.amossavedtoprojecton">
                        {{ "AMOS:" }}
                    </span>
                        <span>
                            {{getamosstring(detailform)}}
                        </span>
                    <!-- <span
                        v-if="detailform && detailform.section && detailform.section.amosbyloginname">
                        by {{ detailform.section.amosbyloginname }}
                    </span>

                    <span
                        v-if="detailform && detailform.section && detailform.section.amossavedtoprojecton">
                        {{ detailform.section.amossavedtoprojecton | datetimeformat }}
                    </span> -->
                         </div>
                     </el-tooltip>
                </div>
            </div>

            <el-row v-show="detail" class="topbotmrowborder">
                <el-col>
                    <!-- <div class="width33px left"> -->
                        <el-button
                            class="padding7px8px mr-3 borderwidth1px bold"
                            type="info"
                            @click="expandcollapse">
                            {{ expandcollapsetext }}
                        </el-button>
                    <!-- </div> -->

                    <el-upload
                        ref="upload"
                        action=""
                        :disabled="!editpermission"
                        :http-request="addAttachment"
                        :on-remove="deleteAttachment"
                        :file-list="attachments"
                        accept=".xml,.xlsx"
                        class="inline"
                        v-if="detailform.section && !detailform.section.locked && iseditornot">
                            <el-button
                                class="padding7px0px width68px"
                                slot="trigger"
                                type="primary"
                                :disabled="!editpermission"
                                icon="el-icon-document"
                                v-if="detailform.section && !detailform.section.locked && iseditornot">
                                Import
                            </el-button>
                    </el-upload>

                    <el-button
                        :disabled="!editpermission"
                        icon="glyphicon glyphicon-transfer"
                        type="primary"
                        @click="projecttemplate = true"
                        v-if="detailform.section && !detailform.section.locked && iseditornot"
                        class="padding5px0px7px width92px marginleft3px">
                        MC_T Lists
                    </el-button>

                <!-- </el-col>

                <el-col :span="12"> -->
                    <!-- <div style="width:60px; float:left">
                        <el-button style="padding-bottom:-40px; border-width: 3px; font-weight: bold;" type="info" @click="expandcollapse">{{expandcollapsetext}}</el-button>
                    </div>

                    <div style="display: inline-block ">
                        <el-select v-model="amosxml">
                            <el-option v-for="item in amosxmlfilter"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div> -->

                    <div class="inline width150px marginleft3px">
                        <el-input
                            v-model="ppFilters.searchTemplateName"
                            clearable
                            :spellcheck="isspellcheck"
                            placeholder="Template / MC Name"
                            @keyup.enter.native="handleFilterChanges()"
                            @clear="handleFilterChanges()"
                           >
                           <el-button slot="append" class="paddingl13" circle @click="handleFilterChanges()">Q</el-button>
                        </el-input>
                    </div>

                    <div class="inline width150px marginleft3px">
                        <el-input
                            v-model="ppFilters.searchSubject"
                            clearable
                            :spellcheck="isspellcheck"
                            placeholder="Subject"
                            @keyup.enter.native="handleFilterChanges()"
                            @clear="handleFilterChanges()"
                           >
                           <el-button slot="append" class="paddingl13" circle @click="handleFilterChanges()">Q</el-button>
                        </el-input>
                    </div>

                    <div class="inline width150px marginleft3px">
                        <el-input
                            v-model="ppFilters.searchBQCode"
                            clearable
                            @change="searchbqcodechange"
                            :spellcheck="isspellcheck"
                            placeholder="BQ Code"
                            @keyup.enter.native="handleFilterChanges()"
                            @clear="handleFilterChanges()"
                            >
                            <el-button slot="append" class="paddingl13" circle @click="handleFilterChanges()">Q</el-button>
                        </el-input>
                    </div>
                <!-- </el-col>

                <el-col :span="12"> -->
                    <!-- <div style="width: 193px; display: inline-block"></div> -->

                    <div class="width205px inline marginleft3px">
                        <el-input
                        v-model="ppFilters.searchAstSpeOther"
                        clearable
                        @change="searchastspeotherchange"
                        :spellcheck="isspellcheck"
                        placeholder="Specification/Asterisk//End Notes"
                        @keyup.enter.native="handleFilterChanges()"
                        @clear="handleFilterChanges()"
                       >
                            <el-button slot="append" class="paddingl13" circle @click="handleFilterChanges()">Q</el-button>
                        </el-input>
                    </div>

                     <div class="width110 inline marginleft3px">
                        <el-select v-model="amosxml" class="pficnslctspace width110">
                            <template #prefix><i class="el-icon-s-tools prefixiconselect" /></template>
                            <el-option
                                v-for="item in amosxmlfilter"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="inline marginleft3px">
                        <el-select v-model="completept" class="pficnslctspace width170px">
                            <template #prefix><i class="el-icon-s-tools prefixiconselect" /></template>
                            <el-option
                                v-for="item in completefilter"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>

                    <el-select
                        v-if="detail && detailform.section && !detailform.section.locked && iseditornot"
                        slot=""
                        ref="status"
                        v-model="detailform.section.status"
                        filterable
                        placeholder="Status"
                        @change="updatestatus('')"
                        class="width100px marginleft4px margintop4px">
                            <el-option  :disabled="!editpermission"
                            v-for="item in statuses"
                            :key="item.status"
                            :label="item.display"
                            :value="item.status"
                            placeholder="Status">
                            </el-option>
                    </el-select>
                     <div class="inline marginleft4px">
                         <el-button
                          v-if="detailform && detailform.section && detailform.section.amossavedtoprojecton"
                         type="primary"
                         @click="$router.push('/projectamos')">
                         Edit in AMOS
                         </el-button>
                    </div>
                </el-col>
            </el-row>


            <el-row v-show="detail && openheader" class="toprowborder">
                <el-col :span="22" class="width92percentage">
                    <el-input v-if="detailform && detailform.section" 
                        placeholder="Header"
                        type="textarea" class="bqtextarea" 
                        :autosize="{ minRows: 2, maxRows: 5 }"
                        v-model="detailform.section.description"
                        maxlength="300"
                        show-word-limit
                        @input="charcount($event, 'sectiondecriptionheader')">
                    </el-input>
                </el-col>
            </el-row>

            <!-- <span style="font-size:30px">Project</span>
            <projectselectpart @projectsectionchange="projectsectionchange"> </projectselectpart>
            <el-row type="flex" justify="space-around">
                <el-col :span="8">
                    <div style="margin-left: 5px; font-family: Comic Sans MS, Times, serif;" v-if="detailform && detailform.section && detailform.section.lastimportfilename">
                        {{ 'Last imported file: ' +  detailform.section.lastimportfilename }} {{ detailform.section.createdon | datetimeformat }}
                    </div>
                </el-col>
                <el-col :span="16" style="text-align:left; color:red; font-weight:bold;">
                    <div v-if="detailform && detailform.section && detailform.section.lastimportfilename && detailform.section.locked">
                        <span style="font-family: Comic Sans MS, Times, serif;">View Only - You Do Not Currently Have Access Rights to Edit Work in this Section</span>
                    </div>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="24" v-show="detail">
                    <div>
                        <el-pagination
                            layout="prev, pager, next"
                            @current-change="pageCurrentChange"
                            :page-size="pagesize"
                            :total=total>
                        </el-pagination>
                    </div>
                </el-col>
            </el-row>
            <hr style="margin-top:5px; margin-bottom:5px" />
            <div class="centerscreen" v-loading="loading"></div> -->

            <!-- <el-row v-show="detail">
                <el-col :span="10" class="rightimp">
                    <el-button icon="glyphicon glyphicon-transfer" type="primary" @click="projecttemplate=true;" :disabled="detailform.section && detailform.section.locked" class="btnwidth" style="margin-right: 5px;">MC_T Lists</el-button>
                        <el-upload ref="upload"
                                action=""
                                :http-request="addAttachment"
                                :on-remove="deleteAttachment"
                                :file-list="attachments"
                                accept=".xml,.xlsx"
                                style="display:inline-block"
                                :disabled="detailform.section && detailform.section.locked">
                            <el-button class="btnwidth" slot="trigger" type="primary" icon="el-icon-document" :disabled="detailform.section && detailform.section.locked">Import Take-Off</el-button>
                        </el-upload>
                    <el-button class="btnwidth" slot="" @click="save()" type="success" icon="el-icon-circle-check">Save</el-button>

                    <el-select v-if="detail" slot="" ref="status" v-model="detailform.section.status" filterable placeholder="Status"
                            @change="updatestatus('')" :disabled="detailform.section && detailform.section.locked">
                        <el-option
                            v-for="item in statuses"
                            :key="item.status"
                            :label="item.display"
                            :value="item.status" placeholder="Status">
                        </el-option>
                    </el-select>
                </el-col>

                <el-col :span="4">
                    &nbsp;<el-button @click="showcreatebaseversiondialogue" type="success" icon="el-icon-circle-check" :disabled="detailform.section && detailform.section.locked">Create Base Version</el-button>
                </el-col>
            </el-row> -->

            <el-row v-show="detail" v-if="showheader==false && istemplateheader == true">
                <!-- <el-col :span="22" style="width: 92.6666%"> -->
                    <div class="tablepaddinglr5">
                        <el-table :header-cell-style="headerrowstyleborder" @header-dragend="resizecolumnswidth" class="hdrtabledata" border>
                            <el-table-column label="123" width="35" header-align="center" ref="col1"></el-table-column>
                            <el-table-column label="Work Classification" width="160" ref="col2"></el-table-column>
                            <el-table-column label="BQ Code" width="210" ref="col3"></el-table-column>

                            <el-table-column align="center" width="200" ref="col4">
                                <template slot="header" slot-scope="{}">
                                    <span class="left paddingleft20px">Dim</span>
                                    <span class="p-L5-R15">Qty</span>
                                    <span class="floatright paddingright14px">Unit</span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Specification" min-width="150" ref="col5">
                                <template slot="header" slot-scope="{}">
                                    <span>Specification</span>
                                    <span class="table-header-resize-icon" @click="adjusttextareaheight('project_project_table_specification')"></span>
                                </template>
                            </el-table-column>

                            <el-table-column label="Asterisk" min-width="150" ref="col6">
                                <template slot="header" slot-scope="{}">
                                    <span>Asterisk</span>
                                    <span class="table-header-resize-icon" @click="adjusttextareaheight('project_project_table_asterisk')"></span>
                                </template>
                            </el-table-column>

                            <el-table-column min-width="100" ref="col7" :resizable="false">
                                <template slot="header" slot-scope="{}">
                                    <span>End Notes</span>
                                    <span class="table-header-resize-icon" @click="adjusttextareaheight('project_project_table_endnotes')"></span>
                                    <span class="right">Rows</span>
                                </template>
                            </el-table-column>

                            <el-table-column width="125" ref="col8" :resizable="false">
                                <template slot="header" slot-scope="{}">
                                    <div class="left">
                                        <el-button
                                            class="acdhideshowbtnstyle"
                                            type="info"
                                            @click="acdbtnrowhideshow">
                                            {{ acdbtnexpandtext }}
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                <!-- </el-col> -->

                <!-- <el-col :span="1" class="customexpandbtn">
                    <div style="float: left;">
                        <el-button
                            style="padding: 4px 7px; border-width: 1px; font-weight: bold;"
                            type="info"
                            @click="acdbtnrowhideshow">
                            {{ acdbtnexpandtext }}
                        </el-button>
                    </div>
                </el-col> -->
            </el-row>
        </div>
        
        <div v-show="detail">
            <el-form ref="detailform" :model="detailform" label-width="120px" size="mini" v-if="detail" v-loading="saving || loading">
                <div>
                    <el-row>
                        <el-col :span="24">
                            <div class="tablepaddinglr5">
                                <el-table
                                    ref="templatetable"
                                    v-show="showtemplates"
                                    :data="getprojecttemplates"
                                    class="fullwidth"
                                    :expand-row-keys="expandedrows"
                                    cell-class-name="expandcolumnrow"
                                    row-key="id"
                                    @expand-change="expandchange"
                                    v-loading="loading || saving"
                                    row-class-name="boldrow"
                                    :show-header="true"
                                    default-expand-all>
                                    <el-table-column type="expand" width="40">
                                        <template slot-scope="pt" class="fullwidth">
                                            <div class="tabletextpadd5top">
                                                <el-table
                                                    :data="getprojecttemplatedetails( pt.row.projecttemplatedetails, pt.row )"
                                                    class="tableBox fullwidth"
                                                    :height="ptDetailsTableRowStyle(pt.row)"
                                                    v-el-table-infinite-scroll="() => { return load(pt.row); }"
                                                    :cell-class-name="tablecellclassname"
                                                    row-key="id"
                                                    :ref="pt.row.id"
                                                    :id="'projecttemplatedetailtable'+pt.row.id"
                                                    :show-header="false" border>
                                                    <el-table-column label="123" property="srno" header-align="center" :width="resizecolwidth.col1">
                                                        <!-- :render-header="appendTip" -->
                                                        <template slot="header">
                                                            <el-popover
                                                                placement="top"
                                                                trigger="hover"
                                                                content="Renumber rows to change order">
                                                                <span slot="reference">123</span>
                                                            </el-popover>
                                                        </template>

                                                        <template slot-scope="scope">
                                                            <el-input
                                                                :style="pt.row.origin == 1 ? 'font-style: italic;' : ''"
                                                                class="hvrinputsrno"
                                                                :spellcheck="isspellcheck"
                                                                v-model="scope.row.srno"
                                                                :id="`project_project_table_srno_${pt.$index}_${scope.$index}`"
                                                                @change="srnochanged(pt.row, pt.$index, scope.row)"
                                                                :disabled="!editpermission  ||  !pt.row.active"
                                                                :readonly="!editpermission  ||  pt.row.origin == 1">
                                                            </el-input>
                                                        </template>
                                                    </el-table-column>

                                                    <el-table-column prop="category" label="Work Classification" :width="resizecolwidth.col2">
                                                        <template slot-scope="scope">
                                                            <el-input
                                                                v-if="pt.row.origin != 1"
                                                                type="textarea" :autosize="{ minRows: 2, maxRows: 7 }"
                                                                :style="pt.row.origin == 1 ? 'font-style: italic;' : ''"
                                                                class="hvrtextarea"
                                                                :spellcheck="isspellcheck"
                                                                v-model="scope.row.category"
                                                                :id="`project_project_table_workclassification_${pt.$index}_${scope.$index}`"
                                                                :disabled="!editpermission  || !pt.row.active"
                                                                @change="changed(pt.row, scope.row, scope.column)"
                                                                :maxlength="32" @input='charcount($event, "workclassification")'
                                                                :readonly="pt.row.origin == 1 || !editpermission">
                                                            </el-input>
                                                        </template>
                                                    </el-table-column>

                                                    <el-table-column prop="template" label="BQ Code" :width="resizecolwidth.col3">
                                                        <template slot-scope="scope">
                                                            <el-autocomplete
                                                                size="mini" type="textarea" :maxlength="50"
                                                                :autosize="{ minRows: 2, maxRows: 7}"
                                                                class="inline-input fullwidth hvrtextarea paddingright15px"
                                                                popper-class="morewidth-autocomplete-projectbqcode"
                                                                :style="pt.row.origin == 1 ? 'font-style: italic;' : ''"
                                                                v-model="scope.row.bqcodelibrary.bqcode"
                                                                :id="`project_project_table_bqcode_${pt.$index}_${scope.$index}`"
                                                                :fetch-suggestions="querySearchBQCode"
                                                                placeholder=""
                                                                @select="handleSelectBQCode(scope.row, $event)"
                                                                :select-when-unmatched="true"
                                                                value-key="bqcode"
                                                                :trigger-on-focus="pt.row.origin != 1"
                                                                :disabled="!pt.row.active || !editpermission"
                                                                :clearable="false"
                                                                @blur="bqcodeblur(scope.row, $event)"
                                                                @Clear="handleSelectBQCode(scope.row, $event)"
                                                                @change="bqcodechanged"
                                                                @input='charcount($event, "bqcode", scope.row)'
                                                                :readonly="pt.row.origin == 1 || !editpermission">
                                                                <template slot-scope="{ item }">
                                                                    <div class="wrapColumnTexttwonormal">
                                                                        {{ item.bqcode }}
                                                                    </div>
                                                                    <div class="wrapColumnTexttwonormal small-font">
                                                                        {{ item.section }} | {{ item.group }} |
                                                                        {{ item.work }} | {{ item.level1 }} |
                                                                        {{ item.level2 }} |
                                                                        {{ item.level3 }}
                                                                    </div>
                                                                </template>
                                                            </el-autocomplete>
                                                            <span class="iconintextarea">
                                                                <el-button
                                                                    class="fontsize14px"
                                                                    v-if="pt.row.origin != 1"
                                                                    slot="suffix"
                                                                    icon="el-icon-edit"
                                                                    :disabled="!editpermission || scope.row.bqcodelibrary.id == 0 || scope.row.bqcodelibrary.bqcode.trim() == ''"
                                                                    @click="bqcodelibraryid = scope.row.bqcodelibrary.id; projectbqcode = true;"
                                                                    type="text"
                                                                    size="mini">
                                                                </el-button>
                                                            </span>
                                                        </template>
                                                    </el-table-column>

                                                    <el-table-column property="dim" :width="resizecolwidth.col4" align="center">
                                                        <template slot="header" slot-scope="{}">
                                                            <span class="left padleft22">Dim</span>
                                                            <span class="p-L5-R30">Qty</span>
                                                            <span>Unit</span>
                                                        </template>

                                                        <template slot-scope="scope">
                                                            <div class="flex">
                                                                <div class="flexcontent inputright dimunit">
                                                                    <el-input-number
                                                                        :style="pt.row.origin == 1 ? 'font-style: italic;' : ''"
                                                                        v-model.number="scope.row.dim"
                                                                        @change="dimchange(scope.row, pt.row, 'dim')"
                                                                        :disabled="!scope.row.isrequired || scope.row.ptddimcalculations.length > 0 || !editpermission"
                                                                        :controls="false"
                                                                        :precision="6"
                                                                        :id="`project_project_table_dim_${pt.$index}_${scope.$index}`"
                                                                        :readonly="pt.row.origin == 1">
                                                                    </el-input-number>
                                                                </div>

                                                                <div class="flexlabel">
                                                                    <el-popover placement="bottom-start" title="Dim Pad Calculator" width="500" trigger="click">
                                                                        {{ scope.row.bqcodelibrary.bqcode }}
                                                                        <el-table
                                                                            :data="scope.row.ptddimcalculations"
                                                                            :show-summary="scope.row.ptddimcalculations.length > 1"
                                                                            :summary-method="(param) => { return getDimsTotal(scope.row, param); }">
                                                                            <el-table-column prop="comment" label="Comment">
                                                                                <template slot-scope="tdd">
                                                                                    <el-input :spellcheck="isspellcheck" v-model="tdd.row.comment" @change="dimchange(scope.row, pt.row, 'comment')"></el-input>
                                                                                </template>
                                                                            </el-table-column>

                                                                            <el-table-column prop="nr" label="Nr" width="70" align="right">
                                                                                <template slot-scope="tdd">
                                                                                    <el-input-number
                                                                                        v-model.number="tdd.row.nr"
                                                                                        @change="dimchange(scope.row, pt.row, 'nr')"
                                                                                        :controls="false"
                                                                                        :precision="6"
                                                                                        class="input-number-width">
                                                                                    </el-input-number>
                                                                                </template>
                                                                            </el-table-column>

                                                                            <el-table-column prop="length" label="Length" width="70" align="right">
                                                                                <template slot-scope="tdd">
                                                                                    <el-input-number
                                                                                        v-model.number="tdd.row.length"
                                                                                        @change="dimchange(scope.row, pt.row, 'length')"
                                                                                        :controls="false"
                                                                                        :precision="6"
                                                                                        class="input-number-width">
                                                                                    </el-input-number>
                                                                                </template>
                                                                            </el-table-column>

                                                                            <el-table-column prop="width" label="Width" width="70" align="right">
                                                                                <template slot-scope="tdd">
                                                                                    <el-input-number
                                                                                        v-model.number="tdd.row.width"
                                                                                        @change="dimchange(scope.row, pt.row,'width')"
                                                                                        :controls="false"
                                                                                        :precision="6"
                                                                                        class="input-number-width">
                                                                                    </el-input-number>
                                                                                </template>
                                                                            </el-table-column>

                                                                            <el-table-column prop="height" label="Height" width="70" align="right">
                                                                                <template slot-scope="tdd">
                                                                                    <el-input-number
                                                                                        v-model.number="tdd.row.height"
                                                                                        @change="dimchange(scope.row, pt.row, 'height')"
                                                                                        :controls="false"
                                                                                        :precision="6"
                                                                                        class="input-number-width">
                                                                                    </el-input-number>
                                                                                </template>
                                                                            </el-table-column>

                                                                            <el-table-column prop="action" label="Action" width="70" align="right">
                                                                                <template slot-scope="tdd">
                                                                                    <el-button icon="el-icon-delete" type="danger" @click="deldim(pt.row, scope.row, tdd.$index)"></el-button>
                                                                                </template>
                                                                            </el-table-column>
                                                                        </el-table>

                                                                        <el-button icon="el-icon-circle-plus" type="primary" @click="adddim(scope.row)">Add</el-button>
                                                                        <el-button
                                                                            slot="reference"
                                                                            icon="el-icon-edit"
                                                                            type="text"
                                                                            size="mini"
                                                                            :disabled="!scope.row.isrequired || detailform.section.locked || !editpermission || !iseditornot">
                                                                        </el-button>
                                                                    </el-popover>
                                                                </div>

                                                                <div class="flexleft padleft7px width55px">
                                                                    {{scope.row.qty}}
                                                                </div>

                                                                <div class="width55px">
                                                                    <el-select
                                                                    v-model="scope.row.unit"
                                                                    placeholder="Unit"
                                                                    :id="`project_project_table_unit_${pt.$index}_${scope.$index}`"
                                                                    :disabled="!pt.row.active"
                                                                    @change="dimchange(scope.row, pt.row, 'active')"
                                                                    :style="pt.row.origin == 1 ? 'font-style: italic;' : ''">
                                                                        <el-option
                                                                            v-for="item in units"
                                                                            :key="item"
                                                                            :label="item"
                                                                            :value="item"
                                                                            :disabled="pt.row.origin == 1 || !editpermission">
                                                                        </el-option>
                                                                    </el-select>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </el-table-column>

                                                    <!-- <el-table-column label="Qty" property="qty" width="60" :formatter="to2decimalformatter" align="right" :style=" pt.row.origin == 'amos' ? 'font-style: italic;' : '' ">
                                                    </el-table-column>

                                                    <el-table-column prop="unit" label="Unit" width="60">
                                                        <template slot-scope="scope">
                                                            <el-select
                                                                v-model="scope.row.unit"
                                                                placeholder="Unit"
                                                                :disabled="!pt.row.active"
                                                                @change="dimchange(scope.row, pt.row, 'active')"
                                                                :style="pt.row.origin == 'amos' ? 'font-style: italic;' : ''">
                                                                    <el-option
                                                                        v-for="item in units"
                                                                        :key="item"
                                                                        :label="item"
                                                                        :value="item"
                                                                        :disabled="pt.row.origin == 'amos' || !editpermission">
                                                                    </el-option>
                                                            </el-select>
                                                        </template>
                                                    </el-table-column> -->

                                                    <el-table-column label="Specification" property="specification" :width="columnsASEWidth('col5')" min-width="150">
                                                        <template slot-scope="scope">
                                                            <el-popover placement="bottom-start" width="400" trigger="focus" :open-delay="500">
                                                                <el-input
                                                                    type="textarea"
                                                                    :spellcheck="isspellcheck"
                                                                    :autosize="{ minRows: 2, maxRows: 7 }"
                                                                    v-model="scope.row.specification"
                                                                    @input="AstSpeEndInputChanges(scope.row)"
                                                                    @change="changed(pt.row, scope.row, scope.column)"
                                                                    :disabled="!pt.row.active || !editpermission"
                                                                    :readonly="pt.row.origin == 1"
                                                                    :style="pt.row.origin == 1 ? 'font-style: italic;' : ''">
                                                                </el-input>

                                                                <el-input
                                                                    type="textarea" class="hvrresizetxtarea"
                                                                    :rows="2"
                                                                    :style="pt.row.origin == 1 ? 'font-style: italic;' : ''"
                                                                    :spellcheck="isspellcheck"
                                                                    slot="reference"
                                                                    v-model="scope.row.specification"
                                                                    @input="AstSpeEndInputChanges(scope.row)"
                                                                    @change="changed(pt.row, scope.row, scope.column)"
                                                                    :id="`project_project_table_specification_${pt.$index}_${scope.$index}`"
                                                                    :disabled="!pt.row.active || !editpermission"
                                                                    :readonly="pt.row.origin == 1">
                                                                </el-input>
                                                            </el-popover>
                                                        </template>
                                                    </el-table-column>

                                                    <el-table-column label="Asterisk" property="asterisik" :width="columnsASEWidth('col6')" min-width="150">
                                                        <template slot-scope="scope">
                                                            <el-popover placement="bottom-start" width="400" trigger="focus" :open-delay="500">
                                                                <el-input
                                                                    type="textarea"
                                                                    :spellcheck="isspellcheck"
                                                                    :autosize="{ minRows: 2, maxRows: 7 }"
                                                                    v-model="scope.row.asterisk"
                                                                    @input="AstSpeEndInputChanges(scope.row)"
                                                                    @change="changed(pt.row, scope.row, scope.column)"
                                                                    :disabled="!pt.row.active  || !editpermission"
                                                                    :readonly="pt.row.origin == 1"
                                                                    :style="pt.row.origin == 1 ? 'font-style: italic;' : ''">
                                                                </el-input>

                                                                <el-input
                                                                    type="textarea" class="hvrresizetxtarea"
                                                                    :rows="2"
                                                                    :style="pt.row.origin == 1 ? 'font-style: italic;' : ''"
                                                                    :spellcheck="isspellcheck"
                                                                    slot="reference"
                                                                    :ref="scope.row.id"
                                                                    v-model="scope.row.asterisk"
                                                                    @input="AstSpeEndInputChanges(scope.row)"
                                                                    @change="changed(pt.row, scope.row, scope.column)"
                                                                    :id="`project_project_table_asterisk_${pt.$index}_${scope.$index}`"
                                                                    :disabled="!pt.row.active || !editpermission"
                                                                    :readonly="pt.row.origin == 1">
                                                                </el-input>
                                                            </el-popover>
                                                        </template>
                                                    </el-table-column>

                                                    <el-table-column label="End Notes" property="other" :width="columnsASEWidth('col7')" min-width="100">
                                                        <template slot-scope="scope">
                                                            <el-popover placement="bottom-start" width="400" trigger="focus" :open-delay="500">
                                                                <el-input
                                                                    type="textarea"
                                                                    :spellcheck="isspellcheck"
                                                                    :autosize="{ minRows: 2, maxRows: 7 }"
                                                                    v-model="scope.row.other"
                                                                    @input="AstSpeEndInputChanges(scope.row)"
                                                                    @change="changed(pt.row, scope.row, scope.column)"
                                                                    :disabled="!pt.row.active || !editpermission"
                                                                    :readonly="pt.row.origin == 1"
                                                                    :style="pt.row.origin == 1 ? 'font-style: italic;' : ''">
                                                                </el-input>

                                                                <el-input
                                                                type="textarea" class="hvrresizetxtarea"
                                                                :rows="2"
                                                                :style="pt.row.origin == 1 ? 'font-style: italic;' : ''"
                                                                slot="reference"
                                                                :spellcheck="isspellcheck"
                                                                v-model="scope.row.other"
                                                                @input="AstSpeEndInputChanges(scope.row)"
                                                                @change="changed(pt.row, scope.row, scope.column)"
                                                                :id="`project_project_table_endnotes_${pt.$index}_${scope.$index}`"
                                                                :disabled="!pt.row.active || !editpermission"
                                                                :readonly="pt.row.origin == 1">
                                                                </el-input>
                                                            </el-popover>
                                                        </template>
                                                    </el-table-column>

                                                    <el-table-column v-if="showacdbtn == true" width="125">
                                                        <template slot-scope="scope">
                                                            <el-button title="RFI"
                                                                @click="openRFI(scope.row)"
                                                                class="ml-2 padding6px2px"
                                                                type="primary"
                                                                :disabled="!pt.row.active || detailform.section.locked || !editpermission || !iseditornot"
                                                                v-if="pt.row.origin != 1">
                                                                RFI
                                                            </el-button>
                                                                <!-- :disabled="!pt.row.active || detailform.section.locked || !editpermission || !iseditornot" -->
                                                            <!-- RFI -->

                                                            <el-button title="Add New"
                                                                icon="el-icon-circle-plus"
                                                                class="ml-2 p-6"
                                                                type="primary"
                                                                @click="addRow(pt.row, false, scope.row, scope.$index)"
                                                                :disabled="!pt.row.active || detailform.section.locked || !editpermission || !iseditornot"
                                                                v-if="pt.row.origin != 1">
                                                            </el-button>
                                                            <!-- Add New -->

                                                            <el-button
                                                                title="Copy Row"
                                                                icon="glyphicon glyphicon-duplicate"
                                                                class="ml-2 padding5px6px6px"
                                                                type="primary"
                                                                @click="addRow(pt.row, true, scope.row, scope.$index)"
                                                                :disabled="!pt.row.active || detailform.section.locked || !editpermission || !iseditornot"
                                                                v-if="pt.row.origin != 1">
                                                            </el-button>
                                                            <!-- Copy Row -->

                                                            <el-button
                                                                title="Delete Row"
                                                                icon="el-icon-delete"
                                                                class="ml-2 p-6"
                                                                type="danger"
                                                                @click="delRow(pt.row, scope.$index)"
                                                                :disabled="!pt.row.active || detailform.section.locked || !editpermission || !iseditornot"
                                                                v-if="pt.row.origin != 1">
                                                            </el-button>
                                                            <!-- Delete Row -->
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </div>
                                            <!-- <div class="spaceBetween" /> -->
                                                <!-- <div v-if="pt.row.projecttemplatedetails.length >= 40">
                                                    <el-pagination layout="prev, pager, next"
                                                        @current-change="pageCurrentChangedetail($event,pt.row)"
                                                        :page-size="pt.row.pagesize"
                                                        :current-page="pt.row.page"
                                                        :total=pt.row.total>
                                                    </el-pagination>
                                                </div> -->

                                            <!-- <div style="margin-left: 55px; ">
                                                <el-button-group>
                                                    <el-button title="Add New"
                                                        icon="el-icon-circle-plus"
                                                        type="primary"
                                                        @click="addRow(pt.row, false)"
                                                        :disabled="!pt.row.active || detailform.section.locked || !editpermission"
                                                        v-if="pt.row.origin != 'amos'">
                                                    </el-button>
                                                </el-button-group> -->

                                                <!-- Add New -->
                                                <!-- <el-button-group>
                                                    <el-button
                                                        title="Copy Row"
                                                        icon="glyphicon glyphicon-duplicate "
                                                        type="primary"
                                                        @click="addRow(pt.row, true)"
                                                        :disabled="!pt.row.active || detailform.section.locked || !editpermission"
                                                        v-if="pt.row.origin != 'amos'">
                                                    </el-button>
                                                </el-button-group> -->
                                                    <!-- Copy Row -->

                                                <!-- <el-button-group>
                                                    <el-button
                                                        title="Delete Row"
                                                        icon="el-icon-delete"
                                                        type="danger"
                                                        @click="delRow(pt.row)"
                                                        :disabled="!pt.row.active || detailform.section.locked || !editpermission"
                                                        v-if="pt.row.origin != 'amos'">
                                                    </el-button>
                                                </el-button-group> -->
                                                <!-- Delete Row -->
                                            <!-- </div> -->
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Reload" property="other" width="255">
                                        <template slot-scope="scope" >
                                            <el-button
                                            type="primary"
                                            title="Reload"
                                            class="padding_TB5px_LR10px"
                                            icon="el-icon-refresh"
                                            @click="reloadTemplate(scope.row)"
                                            :disabled="detailform.section.locked || !iseditornot"
                                            v-if="scope.row.origin != 1"></el-button>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="TO-MC_T Match" prop="" width="180">
                                        <template slot-scope="scope">
                                            <span
                                                :style=" scope.row.origin == 1 ? 'font-style : italic; text-decoration: underline;' : ''">
                                                {{
                                                scope.row.matchedprojecttemplate
                                                ? "Matched from Project &#10004;"
                                                : scope.row.matchedtemplatethis
                                                ? "Library Match (This Import) &#10004;"
                                                : scope.row.matchedtemplate
                                                ? "Library Match &#10004;"
                                                : "Not Matched &#10007;"}}
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Qty" prop="qty" width="55" align="right">
                                        <template slot-scope="scope">
                                            <span
                                                :style="scope.row.origin == 1 ? 'font-style : italic; text-decoration: underline;' : ''">
                                                {{ scope.row.qty | to2decimal }}
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Unit" prop="template.unit" width="83" align="center">
                                        <template slot-scope="scope">
                                            <span 
                                                :style=" scope.row.origin == 1 ? 'font-style : italic; text-decoration: underline;' : ''">
                                                {{ scope.row.unit }}
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="MC_T Name" prop="label">
                                        <template slot-scope="scope">
                                            <div>
                                                <!-- <span :style="(scope.row.origin == 'amos') ? 'font-style : italic; text-decoration: underline;' : ''">{{scope.row.template ? scope.row.template.templatename : scope.row.label}}</span> -->
                                                <span
                                                    :style="scope.row.origin == 1 ? 'font-style : italic; text-decoration: underline;' : ''">
                                                    {{ scope.row.label }}
                                                    <span class="fontsize25px vertical-rl-writing">&#8226;</span>
                                                    {{ scope.row.subject }}
                                                </span>

                                                <div
                                                    v-if="scope.row.unit != scope.row.measuredunit"
                                                    class="red">
                                                    The unit does not match to measured unit
                                                    {{ scope.row.measuredunit }}
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <!-- <el-table-column width="25"><span style='font-size:25px;'>&#8226;</span></el-table-column>

                                    <el-table-column label="Subject" prop="subject" min-width="150" class="italic" >
                                        <template slot-scope="scope">
                                            <div>
                                                <span
                                                    :style="scope.row.origin == 'amos' ? 'font-style : italic; text-decoration: underline;' : ''">
                                                    {{ scope.row.subject }}
                                                </span>
                                            </div>
                                        </template>
                                    </el-table-column> -->

                                    <el-table-column label="Complete" prop="readytobill" width="90">
                                        <template slot-scope="scope">
                                            <span
                                                :style="scope.row.origin == 1 ? 'font-style : italic; text-decoration: underline;': ''">
                                                Complete
                                            </span>

                                            <el-checkbox
                                            style="z-index: 0"
                                            v-model="scope.row.readytobill"
                                            @change="changed(scope.row, null)"
                                            :disabled="scope.row.origin == 1">
                                            </el-checkbox>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-col>
                    </el-row>

                    <el-table
                        v-show="showschedules"
                        :data="getprojectschedules"
                        class="fullwidth"
                        :expand-row-keys="expandedrows"
                        row-key="id"
                        @expand-change="expandchange"
                        v-loading="saving || loading"
                        ref="scheduletable">
                        <el-table-column type="expand" :default-expand-all="true">
                            <template slot-scope="pt">
                                <el-table
                                    :data="getprojectscheduledetails(pt.row.projectscheduledetails)"
                                    class="fullwidth"
                                    row-key="id"
                                    ref="scheduledetailtable">
                                    <el-table-column label="Item Name" property="itemname">
                                    </el-table-column>

                                    <el-table-column prop="bqcodelibrary.specification" label="SP" width="30" align="center">
                                    </el-table-column>

                                    <el-table-column label="BQ Code" property="bqcode">
                                    </el-table-column>

                                    <el-table-column prop="template" label="BQ Code Mapped" width="360">
                                        <template slot-scope="scope">
                                            <el-autocomplete
                                                size="mini"
                                                class="inline-input fullwidth"
                                                popper-class="morewidth-autocomplete"
                                                v-model="scope.row.bqcodelibrary.bqcode"
                                                :fetch-suggestions="querySearchBQCode"
                                                :clearable="false"
                                                placeholder=""
                                                @select="handleSelectBQCode(scope.row, $event)"
                                                :select-when-unmatched="true"
                                                value-key="bqcode"
                                                :trigger-on-focus="true"
                                                @blur="bqcodeblur(scope.row, $event)"
                                                @clear="handleSelectBQCode(scope.row, $event)"
                                                @change="bqcodechanged"
                                                :disabled="!editpermission">
                                                <template slot-scope="{ item }">
                                                    <div class="wrapColumnTexttwonormal">
                                                        {{ item.bqcode }}
                                                    </div>
                                                    <div class="wrapColumnTexttwonormal small-font">
                                                        {{ item.section }} | {{ item.group }} |
                                                        {{ item.work }} | {{ item.level1 }} |
                                                        {{ item.level2 }} |
                                                        {{ item.level3 }}
                                                    </div>
                                                </template>
                                                <el-button
                                                    slot="suffix"
                                                    icon="el-icon-edit"
                                                    @click="bqcodelibraryid = scope.row.bqcodelibrary.id; projectbqcode = true;"
                                                    :disabled="!editpermission"
                                                    type="text"
                                                    size="mini">
                                                </el-button>
                                            </el-autocomplete>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Qty" property="qty" width="80">
                                    </el-table-column>

                                    <el-table-column label="unit" property="unit" width="100">
                                    </el-table-column>

                                    <el-table-column label="Specification" property="specification">
                                        <template slot-scope="scope">
                                            <el-popover placement="bottom-start" width="400" trigger="focus" :open-delay="500">
                                                <el-input
                                                    type="textarea"
                                                    :spellcheck="isspellcheck"
                                                    :disabled="!editpermission"
                                                    :autosize="{ minRows: 2 }"
                                                    v-model="scope.row.specification"
                                                    @change="changed(pt.row, scope.row)">
                                                </el-input>
                                                <el-input
                                                    slot="reference"
                                                    :spellcheck="isspellcheck"
                                                    :disabled="!editpermission"
                                                    v-model="scope.row.specification"
                                                    @change="changed(pt.row, scope.row)">
                                                </el-input>
                                            </el-popover>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Asterisk" property="asterisik">
                                        <template slot-scope="scope">
                                            <el-popover placement="bottom-start" width="400" trigger="focus" :open-delay="500">
                                                <el-input
                                                type="textarea"
                                                :spellcheck="isspellcheck"
                                                :disabled="!editpermission"
                                                :autosize="{ minRows: 2 }"
                                                v-model="scope.row.asterisk"
                                                @change="changed(pt.row, scope.row)">
                                                </el-input>
                                                <el-input
                                                :spellcheck="isspellcheck"
                                                :ref="scope.row.id"
                                                slot="reference"
                                                :disabled="!editpermission"
                                                v-model="scope.row.asterisk"
                                                @change="changed(pt.row, scope.row)">
                                                </el-input>
                                            </el-popover>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="End Notes" property="other">
                                        <template slot-scope="scope">
                                            <el-popover placement="bottom-start" width="400" trigger="focus" :open-delay="500">
                                                <el-input
                                                type="textarea"
                                                :spellcheck="isspellcheck"
                                                :disabled="!editpermission"
                                                :autosize="{ minRows: 2 }"
                                                v-model="scope.row.other"
                                                @change="changed(pt.row, scope.row)">
                                                </el-input>
                                                <el-input
                                                slot="reference"
                                                :spellcheck="isspellcheck"
                                                :disabled="!editpermission"
                                                v-model="scope.row.other"
                                                @change="changed(pt.row, scope.row)">
                                                </el-input>
                                            </el-popover>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>

                        <el-table-column label="MC Name" prop="mcname"> </el-table-column>

                        <el-table-column prop="template" label="NRM Section">
                            <template slot-scope="scope">
                                <el-autocomplete
                                    size="mini"
                                    class="inline-input fullwidth"
                                    popper-class="morewidth-autocomplete"
                                    v-model="scope.row.billcompilecode.description"
                                    :fetch-suggestions="querySearchBCC"
                                    clearable
                                    :disabled="!editpermission"
                                    placeholder="NRM Section"
                                    @select="handleSelectBCC(scope.row, $event)"
                                    :select-when-unmatched="true"
                                    value-key="description"
                                    :trigger-on-focus="true"
                                    @blur="blur(itm, $event)"
                                    @change="bccchanged">
                                    <template slot-scope="{ item }">
                                        <div class="wrapColumnTexttwonormal">
                                        {{ item.description }}
                                        </div>
                                    </template>
                                </el-autocomplete>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-form>
        </div>

        <el-dialog v-if="projectbqcode" title="Project BQCode" :visible.sync="projectbqcode" width="90%">
            <projectbqcode
                :projectsectionid.sync="detailform.section.id"
                :bqcodelibraryid.sync="bqcodelibraryid"
                :bqcodelibrary="false"
                @closedialogue="closedialogue">
            </projectbqcode>
        </el-dialog>

        <el-dialog v-if="projecttemplate" title="Project Templates - Add/Remove" :visible.sync="projecttemplate" class="dialogboxpadding" width="560px">
            <div slot="title">
                <span class="fontsize18px">Project Templates - Add/Remove</span>
                <span class="floatright marginright40px"><el-button 
                    @click="cellchanged('save_project_mctlists',()=>{$bus.$emit('saveprojecttemplatedata')},false)"
                     
                    type="success" icon="el-icon-circle-check">Save</el-button></span>
            </div>

            <projecttemplate
                :project.sync="detailform"
                @closedialogue="closedialogue">
            </projecttemplate>
        </el-dialog>

        <el-dialog title="Import Take-off Changes" :visible.sync="confirmimportdialog" width="40%">
            <div class="bold" v-if="importcheck.missing.length > 0">
                These templates are Missing
            </div>

            <div class="blue marginb5" v-for="item in importcheck.missing" :key="item.label">
                <span> <el-checkbox v-model="item.selected" @change="checkmissingtemplate"></el-checkbox></span>
                <span> {{ item.label }} </span>
                <span>
                    <el-select v-model="item.unit" placeholder="Unit" class="width60px">
                        <el-option
                            v-for="item in units"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select>
                </span>
            </div>

            <div class="bold" v-if="importcheck.todelete.length > 0">
                These templates will be deleted
            </div>

            <div class="red" v-for="item in importcheck.todelete" :key="item">
                {{ item }}
            </div>

            <div class="bold" v-if="importcheck.toadd.length > 0">
                These templates will be Added
            </div>

            <div class="green" v-for="item in importcheck.toadd" :key="item">
                {{ item }}
            </div>

            <div class="bold" v-if="importcheck.toupdate.length > 0">
                These templates will be updated
            </div>

            <div v-for="item in importcheck.toupdate" :key="item">
                {{ item }}
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button v-if="isaddtoproject" class="left" type="success" @click="addbasetemplatetoproject">Add to project</el-button>
                <el-button v-if="importcheck.missing.length == 0" @click="confirmimportclose(true)">Confirm</el-button>
                <el-button @click="confirmimportclose(false)">Cancel</el-button>
            </span>
        </el-dialog>

        <el-dialog title="Create Base Version" :visible.sync="showcreatebaseversion" width="40%">
            <el-form label-position="left" label-width="120px" :model="baseversion">
                <el-form-item label="Section Display">
                <el-input :id="'project_project_createbaseversion_sectiondisplay'" v-model="baseversion.sectiondisplay" :spellcheck="isspellcheck"> </el-input>
                </el-form-item>
                <el-form-item label="Version">
                <el-input :id="'project_project_createbaseversion_version'" v-model="baseversion.version" :spellcheck="isspellcheck"> </el-input>
                </el-form-item>
                <el-form-item label="Purpose">
                <el-input :id="'project_project_createbaseversion_purpose'" v-model="baseversion.comment" :spellcheck="isspellcheck"> </el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button v-loading.body="saving" @click="saveversion">Create Base Version</el-button>
                <el-button @click="showcreatebaseversion = false">Cancel</el-button>
            </span>
        </el-dialog>

         <el-dialog  width="30%" title="warning" :visible.sync="warningdilog">
                <!-- {{warningdilogstring}} -->
             <div class="breakword">
                 <div v-html="warningdilogstring"></div>
             </div>
                 <span slot="footer" class="dialog-footer">
                    <!-- <el-button @click="dialogVisible = false">Cancel</el-button> -->
                    <el-button type="primary" @click="warningdilog = false">Ok</el-button>
                </span>
            </el-dialog>
              <!-- <el-dialog title="warning" :visible.sync="savewarningdilog">

                <div v-html="savewarningdilogstring"></div>
                 <span slot="footer" class="dialog-footer">

                    <el-button type="primary" @click="savewarningdilog = false">Cancel</el-button>
                    <el-button type="success" @click="save(),savewarningdilog = false,issave = false">save</el-button>
                </span>
            </el-dialog> -->

        <!-- <el-dialog title="Invalid data" :visible.sync="validationerror" width="80%">
            <div v-for="msg in message">
                {{msg}}
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="validationerror = false">Ok</el-button>
            </span>
        </el-dialog> -->

        <customdialog v-if="validationerror" @warnerror="warnerror" :projectsection="detailform.section" @movespecificpage="movecheckingpage" :err="errorobject"></customdialog>

        <div id="dragme" draggable="true" class="centermiddle draggable bgwhitesmoke width350px padding5px height240px" v-if="showbillbqcode">
            <el-button style="float: right" @click="hidebillbqcode">&times;</el-button>
            <div style="clear: both"> There are {{ bqcodesfound.length }} of same Bq code within same NRM component of Project sheet</div>
            <el-table
                :data="bqcodesfound"
                :show-header="false"
                class="fullwidth height120px overflowYscroll"
                highlight-current-row
                @current-change="handleCurrentChange">
                <el-table-column prop="template" label="Template">
                <template slot-scope="scope" class="fullwidth">
                    <b>{{ scope.row.template }}</b>
                    <div>{{ scope.row.bqcode }}</div>
                </template>
                </el-table-column>
            </el-table>
            <div>&nbsp;</div>
        </div>
        <!-- <el-dialog v-if="intimechecklist" 
                   :visible.sync="intimechecklist"
                   :modal-append-to-body="false"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   :append-to-body="true"
                   width="63%"> -->
                   <CustomPageDraggable :isvisible="intimechecklist" class="chklisectionpopupstyle">
                        <intimechecklist :ismsg="true" @messageclose="intimechecklist = false" :pageaction="pageaction"></intimechecklist>
                    </CustomPageDraggable>
        <!-- </el-dialog> -->
        <CustomPageDraggable style="width: 640px;height:550px;" :isvisible="showrfi">
                            <i @click="showrfi = false" class="el-icon-close right"></i>
            <rfientryform :projectamosdetailid="null" :projecttemplatedetailid="projecttemplatedetailid"></rfientryform>
        </CustomPageDraggable> 

        <div v-if="visiblemedia">
            <displaydropboxmedia :isvisible="visiblemedia" :filehistory="filehistory" @dialogclosed="handledialogclose">
            </displaydropboxmedia>
        </div>
    </div>
</template>

<script>
import projectbqcode from "./projectbqcode.vue";
import projecttemplate from "./projecttemplate.vue";
import projectselectpart from "./projectselectpart.vue";
import customdialogbox from "./customdialogbox.vue";
import elTableInfiniteScroll from "el-table-infinite-scroll";
import intimechecklist from './checklistsection.vue'
import CustomPageDraggable from './CustomPageDraggable.vue'
import rfientryform from './rfientryform.vue'
import displaydropboxmedia from './displaydropboxmedia.vue';
export default {
    data() {
        return {
            filehistory: {},
            visiblemedia: false,
            showrfi:false,
            projecttemplatedetailid:null,
            pageaction:{},
            intimechecklist:false,
            warningdilog:false,
            warningdilogstring:'',
            iseditornot:true,
            miSectionList:[],
            otherSectionList:[],
            CurentUser:{},
            issave:false,
            // scrollheight: window.innerHeight - 270,
            search: "",
            searchbqcode: "",
            searchsubject: "",
            searchastspeother: "",
            amosxml: "all",
            amosxmlfilter: [
                { label: "Amos & Xml", value: "all" },
                { label: "Amos", value: "amos" },
                { label: "Xml", value: "xml" },
            ],
            completept: "all",
            completefilter: [
                { label: "Complete & Incomplete", value: "all" },
                { label: "Complete", value: true },
                { label: "Not Complete", value: false },
            ],
            detail: false,
             role:this.$store.state.role,
            user: this.$store.state.user,
            form: {},
            detailform: { id: 0, projectname: "", section: null },
            tabledata: [],
            grid: {},
            attachments: [],
            headerInfo: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            sectionid: null,
            statuses: this.$store.state.statuses,
            units: this.$store.state.units,
            loading: false,
            saving: false,
            showtemplates: false,
            showschedules: false,
            bqcodelibraryid: 0,
            projectbqcode: false,
            projecttemplate: false,
            expandedrows: [],
            expandcollapsetext: "+",
            acdbtnexpandtext:"+",
            showacdbtn: true,
            selectrow: 0,
            bqcodesfound: [],
            showbillbqcode: false,
            showheader: true,
            confirmimportdialog: false,
            importconfirmed: false,
            showcreatebaseversion: false,
            baseversion: {},
            importcheck: {
                missing: [],
                toadd: [],
                todelete: [],
                toupdate: [],
            },
            validationerror: false,
            message: [],
            page: 1,
            pagesize: 12,
            total: 0,
            errorobject: {
                message: [],
                type: "",
                visible: false,
            },
            warncheck: false,
            resizecolwidth: {},
            istemplateheader: true,
            isaddtoproject: false,
            openheader: false,
            directionids:[
                    "project_project_table_srno",
                    "project_project_table_workclassification",
                    "project_project_table_bqcode",
                    "project_project_table_dim",
                    "project_project_table_unit",
                    "project_project_table_specification",
                    "project_project_table_asterisk",
                    "project_project_table_endnotes"
                ],
            heightchangedcellids: [
            ],
            ppFilters: {
                searchTemplateName: "",
                searchSubject: "",
                searchBQCode: "",
                searchAstSpeOther: ""
            },
            ppFiltersEnabled: true,
            ptDetailsFilteredIds: []
        };
    },

    directives: {
        "el-table-infinite-scroll": elTableInfiniteScroll,
    },

    methods: {
        viewheader() {
            this.openheader = true;
        },

        findbot() {
            this.$confirm('This feature is work in under development.', 'Warning', {
                confirmButtonText: 'OK',
                showCancelButton: false,
                dangerouslyUseHTMLString: true,
                type: 'warning'
            });
            // var data = {
            //     id: this.detailform.id,
            //     section: { id: this.sectionid },
            // };
            // this.showloading();
            // let url = "project/exportprojectforfindbot";
            // this.$http.post(url, data, { responseType: 'blob' })
            //     .then((response) => {
            //         this.hideloading();
            //         let blob = response.data;
            //         // let currentdate = moment(new Date()).format("DD-MMM-YYYY HH:mm").toString();
            //         let filename = `Dashboard - Comparatives.xlsx`;
            //         let link = document.createElement('a');
            //         link.href = window.URL.createObjectURL(blob);
            //         link.download = filename;
            //         link.click();
            //     })
            //     .catch((err) => {
            //         this.hideloading();
            //         this.$bus.$emit('error', err);
            //     });
        },

        findbotview() {
            this.$confirm('This feature is work in under development.', 'Warning', {
                confirmButtonText: 'OK',
                showCancelButton: false,
                dangerouslyUseHTMLString: true,
                type: 'warning'
            });
        },

        changeheightstextareaandtable() {
        var ptdtableelements = document.querySelectorAll(`[id^="projecttemplatedetailtable"]`);

        if (ptdtableelements.length > 0) {
            for (let index = 0; index < ptdtableelements.length; index++) {
                const ptdtelement = ptdtableelements[index];
                
                if (ptdtelement) {
                    var textareaElementsArray = [];

                    const textareaElements1 = ptdtelement.querySelectorAll("[id^='project_project_table_asterisk']");
                    const textareaElements2 = ptdtelement.querySelectorAll("[id^='project_project_table_specification']");
                    const textareaElements3 = ptdtelement.querySelectorAll("[id^='project_project_table_endnotes']");

                    textareaElementsArray = [...textareaElements1, ...textareaElements2, ...textareaElements3];

                    if (textareaElements1.length > 0) {

                        for (let i = 0; i < textareaElementsArray.length; i++) {
                            const textareaElement = textareaElementsArray[i];

                            textareaElement.style.height = 'auto';
                            textareaElement.style.height = textareaElement.scrollHeight + 'px'; //`${textareaElement.scrollHeight}px !important`;
                            // textareaElement.style.cssText += 'height: 200px !important';

                            var cc = textareaElement.style.height;
                        }


                        if (textareaElements1.length == 1) {
                            const maxScrollHeight = Math.max(textareaElements1[0]?.scrollHeight || 0, textareaElements2[0]?.scrollHeight || 0, textareaElements3[0]?.scrollHeight || 0);

                            if (maxScrollHeight > 51) {
                                ptdtelement.style.height = 'auto';
                                ptdtelement.style.height = maxScrollHeight + 'px';  //`${maxScrollHeight}px !important`;
                                // ptdtelement.style.cssText += 'height: 200px !important';

                                var dd = ptdtelement.style.height;
                            }
                        }
                    }
                }
            }
        }
        },


        ptDetailsTableRowStyle(ptrow) {
            // var ptdtableelements = document.querySelectorAll(`[id^="projecttemplatedetailtable"]`);
            // var ptdtelement = document.getElementById("projecttemplatedetailtable" + ptrow.id);

            // if (ptdtableelements.length > 0) {
            //     for (let index = 0; index < ptdtableelements.length; index++) {
            //         const ptdtelement = ptdtableelements[index];
            /*
            if (ptrow.projecttemplatedetails.length == 1) {
                ptrow.height = 0;
                ptrow.height = this.$set(ptrow, 'height', ptrow.height);
            }*/

            const ptdtelement = document.getElementById("projecttemplatedetailtable" + ptrow.id);

            if (!ptdtelement || ptdtelement.isheightchanged) {
                return ptrow.height;
            }

            const textareaSelectors = [
                "[id^='project_project_table_asterisk']",
                "[id^='project_project_table_specification']",
                "[id^='project_project_table_endnotes']"
            ];

            const textareaElementsArray = textareaSelectors.reduce((acc, selector) => {
                return acc.concat(Array.from(ptdtelement.querySelectorAll(selector)));
            }, []);

            if (textareaElementsArray.length === 0) {
                return ptrow.height;
            }

            ptdtelement.isheightchanged = true;

            textareaElementsArray.forEach(textarea => {
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            });

            if (ptrow.projecttemplatedetails.length === 1) {
                const maxScrollHeight = Math.max(...textareaElementsArray.map(el => el.scrollHeight), 56);
                ptdtelement.style.height = `${maxScrollHeight}px`;
                this.$set(ptrow, 'height', maxScrollHeight);
            }

            return ptrow.height;
                    // if (textareaElements.length == 1) {
                    //     let taelement = textareaElements[0];
                    //     ptdtelement.style.height = 'auto';
                    //     ptdtelement.style.height = taelement.scrollHeight + 'px';
                    // }

            //     }
            // }
            // return ({ row, rowIndex }) => {
            //     var ptdelement = document.getElementById("projecttemplatedetailtable" + ptrow.id);
            //     // if (ptdtelement) {
            //         // const id1 = "project_project_table_specification";
            //         // const id2 = "project_project_table_asterisk";
            //         // const id3 = "project_project_table_endnotes";
                    
            //         // var textareaElements = ptdtelement.querySelectorAll("project_project_table_specification");

            //         if (ptdelement) {
            //             var textareaElements1 = document.querySelectorAll("[id^='project_table_specification']");
            //             var textareaElements2 = document.querySelectorAll("[id^='project_table_asterisk']");
            //             var textareaElements3 = document.querySelectorAll("[id^='project_table_endnotes']");

            //             // var textareaElements = ptdtelement.querySelectorAll(`[id^="${id1}"], [id^="${id2}"], [id^="${id3}"]`);
            //         }
                    

                //     for (let index = 0; index < textareaElements.length; index++) {
                //         const taelement = textareaElements[index];

                //         taelement.style.height = 'auto';
                //         taelement.style.height = taelement.scrollHeight + 'px';
                //     }

                //     if (ptrow.projecttemplatedetails.length == 1) {
                //         ptdtelement.style.height = 'auto';
                //         ptdtelement.style.height = taelement.scrollHeight + 'px';
                //     }    
                // }

                

                // if (extraParameter === 'highlight') {
                //     if (row.age > 25) {
                //         return { background: 'lightblue' };
                //     }
                // }
                // // Return default style if no condition matches
                // return {};
            // };
            //return ptrow.height;
        },

        clearElementsHeights() {
            var ptdtelements = document.querySelectorAll("[id^='projecttemplatedetailtable']");

            for (let i = 0; i < ptdtelements.length; i++) {
                const ptdtelement = ptdtelements[i];

                if (ptdtelement) {
                    var textareaElementsArray = [];

                    const textareaElements1 = ptdtelement.querySelectorAll("[id^='project_project_table_asterisk']");
                    const textareaElements2 = ptdtelement.querySelectorAll("[id^='project_project_table_specification']");
                    const textareaElements3 = ptdtelement.querySelectorAll("[id^='project_project_table_endnotes']");

                    textareaElementsArray = [...textareaElements1, ...textareaElements2, ...textareaElements3];

                    if (textareaElements1.length > 0) {

                        for (let i = 0; i < textareaElementsArray.length; i++) {
                            const textareaElement = textareaElementsArray[i];
                            textareaElement.style.height = '';
                        }

                        if (textareaElements1.length == 1) {
                            ptdtelement.style.height = '';
                        }
                    }
                }
            }
        },

        getsection(){
                // Get NRM section for each page
                if(false){
                    // return this.detailform.section;
                }else{
                    return "";
                }
            },
            cellchanged(cellaction,callback,cancall){
            if(cancall){
                callback();
                this.canmatchcellid(()=>{},cellaction,this.getsection(),true,(array1)=>{this.validatedirection(array1,callback)} );
            }else{
                this.canmatchcellid(callback,cellaction,this.getsection(),true,(array1)=>{this.validatedirection(array1,callback)});
            }
        },

        getformat(dr){
           const data = {};
           var inc_pattern = null;
           var exc_pattern = null;
           var format_pattern = null;
           if(dr.includerule != null && dr.includerule != ""){
               inc_pattern = new RegExp(dr.includerule,"i");
           }
           if(dr.excluderule != null && dr.excluderule != ""){
               exc_pattern = new RegExp(dr.excluderule,"i");
           }
           if(dr.formatrule != null && dr.formatrule != ""){
               format_pattern = new RegExp(dr.formatrule,"i");
           }
           data.format_pattern = format_pattern;
           data.inc_pattern = inc_pattern;
           data.exc_pattern = exc_pattern;
           return data;
         },

         getCellValueAndRegexRules(data, cellvalue) {
            if (data.includerule != null && data.includerule != "") {
                data.inc_pattern = new RegExp(data.includerule, "i");
            }

            if (data.excluderule != null && data.excluderule != "") {
                data.exc_pattern = new RegExp(data.excluderule, "i");
            }

            if (data.formatrule != null && data.formatrule != "") {
                data.format_pattern = new RegExp(data.formatrule, "i");
            }

            data.enteredvalue = cellvalue;

            return data;
         },

         checkrdmatch(data,dr){
            return this.$training.RG_Direction_Match(data,dr.inc_pattern,dr.exc_pattern,dr.format_pattern)
        },

        findPageNumber(index, pagesize, total) {
                var currentPage = Math.ceil((index + 1) / pagesize);
                var totalPages = Math.ceil(total / pagesize);
                
                if (currentPage > totalPages) {
                    currentPage = totalPages;
                }
                
                return currentPage;
            },
        getPropertyByString(obj, propString) {
             const propArray = propString.split('.');
             let currentObj = obj;
             for (let i = 0; i < propArray.length; i++) {
                 const prop = propArray[i];
                 currentObj = currentObj[prop];
                 if (currentObj === undefined) {
                 return undefined;
                 }
             }
             return currentObj;
         },

        validatedirectionsingle(data){
            if(this.expandedrows.length <= 0){
                this.expandcollapse();
            }
            var page =  this.findPageNumber(data.fileindx,this.pagesize,this.detailform.projecttemplates.length);
                if(this.page != page){
                    this.page = page; 
                    // this.detailform.projecttemplates = JSON.parse(JSON.stringify(this.detailform.projecttemplates));
                    this.$forceUpdate();
                }
             var newcheckval = this.getPropertyByString(this.detailform.projecttemplates[data.fileindx].projecttemplatedetails[data.dataindex],data.checkstr);
             if(newcheckval != undefined){
                 data.value = newcheckval;
             }
        },

        getdirectionoption(FormData, uniqueid, ptindex, ptdindex, dr, checkstr, pageindex) {
            const option = JSON.parse(JSON.stringify({
                visible: true,
                sectionname: "",
                isedit: false,
                id: FormData.id,
                boxtype: "Small 2 Part Pop up",
                content: FormData.message,
                mediacontent: FormData.mediacontent,
                link: FormData.link,
                offercloseoption: FormData.offercloseoption,
                heading: FormData.directionname,
                directiondegree: FormData.directiondegree,
                direction: FormData,
                usedata: true,
                enteredvalue: FormData.enteredvalue,
                celltype: FormData.celltype,
                objectpath: FormData.objectpath,
                checkvalue: FormData.checkvalue,
                operation: FormData.operation,

            }));
            this.$set(option, "uniqueid", uniqueid)
            this.$set(option, "fileindx", ptindex)
            this.$set(option, "dataindex", ptdindex)
            Object.assign(option, dr);
            this.$set(option, 'checkstr', checkstr)
            option.validatedirectionsingle = (data) => { this.validatedirectionsingle(data) }
            this.$set(option, "elementid", `${FormData.pagecell.cellname}_${ptindex}_${ptdindex}`);
            return option;
        },

        getcellmatcheddirection(direction, directionidvalue) {
            let matcheddirection = direction.directioncelldetails.find(x => x.pagecell.cellname == directionidvalue);
            let dir = JSON.parse(JSON.stringify(direction));
            if (matcheddirection) {
                dir.includerule = matcheddirection.includerule;
                dir.excluderule = matcheddirection.excluderule;
                dir.formatrule = matcheddirection.formatrule;
                dir.pagecell = matcheddirection.pagecell;
                dir.pagecellid = matcheddirection.pagecellid;

                dir.checkvalue = matcheddirection.checkvalue;
                dir.operation = matcheddirection.operation;
                dir.celltype = matcheddirection.pagecell.celltype;
                dir.objectpath = matcheddirection.pagecell.objectpath;

                return dir;
            }
        },

        validatedirection(directions,callback){
            var errors = [];
            var uniqueid = 1;
            var pageindex = 0;

            for (let i = 0; i < directions.length; i++) {
                const direction = directions[i];

               var srdr = this.getcellmatcheddirection(direction, this.directionids[0]); // direction.find(x => x.pagecell.cellname == this.directionids[0]);
               var wcdr = this.getcellmatcheddirection(direction, this.directionids[1]); // direction.find(x => x.pagecell.cellname == this.directionids[1]);
               var cbqcodedr = this.getcellmatcheddirection(direction, this.directionids[2]); // direction.find(x => x.pagecell.cellname == this.directionids[2]);
               var dimdr = this.getcellmatcheddirection(direction, this.directionids[3]); // direction.find(x => x.pagecell.cellname == this.directionids[3]);
               var unitdr = this.getcellmatcheddirection(direction, this.directionids[4]); // direction.find(x => x.pagecell.cellname == this.directionids[4]);
               var specdr = this.getcellmatcheddirection(direction, this.directionids[5]); // direction.find(x => x.pagecell.cellname == this.directionids[5]);
               var astdr = this.getcellmatcheddirection(direction, this.directionids[6]); // direction.find(x => x.pagecell.cellname == this.directionids[6]);
               var enddr = this.getcellmatcheddirection(direction, this.directionids[7]); // direction.find(x => x.pagecell.cellname == this.directionids[7]);
               for (let ptindex = 0; ptindex < this.detailform.projecttemplates.length; ptindex++) {
                const pt = this.detailform.projecttemplates[ptindex];
                if(pageindex > this.pagesize - 1){
                    pageindex = 0;
                 }
                    if(pt.projecttemplatedetails != undefined){
                        for (let ptdindex = 0; ptdindex < pt.projecttemplatedetails.length; ptdindex++) {
                            const ptd = pt.projecttemplatedetails[ptdindex];

                            let cellobjarray = [
                                { cellname: "sr", srdr: srdr },
                                { cellname: "wc", wcdr: wcdr },
                                { cellname: "bqcode", cbqcodedr: cbqcodedr },
                                { cellname: "dim", dimdr: dimdr },
                                { cellname: "unit", unitdr: unitdr },
                                { cellname: "specification", specdr: specdr },
                                { cellname: "asterisk", astdr: astdr },
                                { cellname: "endnotes", enddr: enddr },
                            ];
                            let iscellrulesmatched = false;

                            if(srdr){
                                iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, ptd);

                                 let dr = this.getformat(srdr);
                                 let ddata = this.getCellValueAndRegexRules(srdr, ptd.srno);

                                 if(ptd.srno != null && this.$directionutility.directionvaluematch(ddata) == false){
                                  const option = this.getdirectionoption(srdr,uniqueid,ptindex,ptdindex,dr,'srno',pageindex);

                                    if (iscellrulesmatched == false) {
                                        option.ismatchedrulecell = false;
                                    }else{
                                        option.ismatchedrulecell = true;
                                    }

                                  errors.push(option);
                                  uniqueid ++;
                                 }
                            }
                            if(wcdr){
                                iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, ptd);

                                let dr = this.getformat(wcdr);
                                let ddata = this.getCellValueAndRegexRules(wcdr, ptd.category);

                                if(ptd.category != null && this.$directionutility.directionvaluematch(ddata) == false){
                                    const option = this.getdirectionoption(wcdr,uniqueid,ptindex,ptdindex,dr,'category',pageindex);

                                    if (iscellrulesmatched == false) {
                                        option.ismatchedrulecell = false;
                                    }else{
                                        option.ismatchedrulecell = true;
                                    }

                                    errors.push(option);
                                    uniqueid ++;
                                }
                            }
                            if(cbqcodedr){
                                iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, ptd);

                                let dr = this.getformat(cbqcodedr);
                                let ddata = this.getCellValueAndRegexRules(cbqcodedr, ptd.bqcodelibrary.bqcode);

                                if(ptd.bqcodelibrary != null && this.$directionutility.directionvaluematch(ddata) == false){
                                    const option = this.getdirectionoption(cbqcodedr,uniqueid,ptindex,ptdindex,dr,'bqcodelibrary.bqcode',pageindex);

                                    if (iscellrulesmatched == false) {
                                        option.ismatchedrulecell = false;
                                    }else{
                                        option.ismatchedrulecell = true;
                                    }

                                    errors.push(option);
                                    uniqueid ++;
                                }
                            }
                            if(dimdr){
                                iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, ptd);

                                let dr = this.getformat(dimdr);
                                let ddata = this.getCellValueAndRegexRules(dimdr, ptd.dim);

                                if(ptd.dim != null && this.$directionutility.directionvaluematch(ddata) == false){
                                    const option = this.getdirectionoption(dimdr,uniqueid,ptindex,ptdindex,dr,'dim',pageindex);

                                    if (iscellrulesmatched == false) {
                                        option.ismatchedrulecell = false;
                                    }else{
                                        option.ismatchedrulecell = true;
                                    }

                                    errors.push(option);
                                    uniqueid ++;
                                }
                            }
                            if(unitdr){
                                iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, ptd);

                                let dr = this.getformat(unitdr);
                                let ddata = this.getCellValueAndRegexRules(unitdr, ptd.unit);

                                if(ptd.unit != null && this.$directionutility.directionvaluematch(ddata) == false){
                                    const option = this.getdirectionoption(unitdr,uniqueid,ptindex,ptdindex,dr,'unit',pageindex);

                                    if (iscellrulesmatched == false) {
                                        option.ismatchedrulecell = false;
                                    }else{
                                        option.ismatchedrulecell = true;
                                    }

                                    errors.push(option);
                                    uniqueid ++;
                                }
                            }
                            if(specdr){
                                iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, ptd);

                                let dr = this.getformat(specdr);
                                let ddata = this.getCellValueAndRegexRules(specdr, ptd.specification);

                                if(ptd.specification != null && this.$directionutility.directionvaluematch(ddata) == false){
                                    const option = this.getdirectionoption(specdr,uniqueid,ptindex,ptdindex,dr,'specification',pageindex);

                                    if (iscellrulesmatched == false) {
                                        option.ismatchedrulecell = false;
                                    }else{
                                        option.ismatchedrulecell = true;
                                    }

                                    errors.push(option);
                                    uniqueid ++;
                                }
                            }
                            if(astdr){
                                iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, ptd);

                                let dr = this.getformat(astdr);
                                let ddata = this.getCellValueAndRegexRules(astdr, ptd.asterisk);

                                if(ptd.asterisk != null && this.$directionutility.directionvaluematch(ddata) == false){
                                    const option =  this.getdirectionoption(astdr,uniqueid,ptindex,ptdindex,dr,'asterisk',pageindex);

                                    if (iscellrulesmatched == false) {
                                        option.ismatchedrulecell = false;
                                    }else{
                                        option.ismatchedrulecell = true;
                                    }

                                    errors.push(option);
                                    uniqueid ++;
                                }
                            }
                            if(enddr){
                                iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, ptd);

                                let dr = this.getformat(enddr);
                                let ddata = this.getCellValueAndRegexRules(enddr, ptd.other);

                                if(ptd.other != null && this.$directionutility.directionvaluematch(ddata) == false){
                                    const option = this.getdirectionoption(enddr,uniqueid,ptindex,ptdindex,dr,'other',pageindex);

                                    if (iscellrulesmatched == false) {
                                        option.ismatchedrulecell = false;
                                    }else{
                                        option.ismatchedrulecell = true;
                                    }

                                    errors.push(option);
                                    uniqueid ++;
                                    
                                }
                            }
                            
                        }
                    }
                    pageindex++;
               }
            }

                errors = errors.filter(x => x.ismatchedrulecell == true);
               if(errors.length > 0){
                   if(this.expandedrows.length <= 0){
                        this.expandcollapse();
                    }
                    this.$store.state.directionsdialogue = errors;
                    this.$store.state.currentdirectionindex = 0;
                    this.$store.state.currentdirection = errors[0];
                }else{
                    callback();
                }
        },

        openRFI(data){
            this.projecttemplatedetailid = data.id;
            this.showrfi = true;
        },

        getpageactionmessage(page,action,pro){
            this.intimechecklist = false;
            let obj = {
                elt:{formtype:pro.library,nrmelementid:pro.section.section},
                pa:{pagename:page,actionname:action},
                reminder:{projectsectionid:pro.section.id,pagename:page,actionname:action,userid:this.user.id}
            }
            this.post("ElementList/sendmessage",obj).then((res)=>{
                var newdata = res.data;  
                if(newdata == true){
                    this.pageaction = {pagename:page,actionname:action};
                    this.intimechecklist = true;
                }
            });
        },

         searchbqcodechange(data){
                if(data == "" || data == null){
                    if(this.$store.state.billbqcode != ""){
                        this.$store.state.billbqcode = "";
                    }
                }
            },
             searchastspeotherchange(data){
                if(data == "" || data == null){
                    if(this.$store.state.billasterisk != ""){
                        this.$store.state.billasterisk = "";
                    }
                    if(this.$store.state.billspecification != ""){
                        this.$store.state.billspecification = "";
                    }
                }
            },
            
        onScroll2(e, row) {
            if (e.target.scrollTop == 0) {
                row.start = row.start - 6;
                row.end = row.end - 5;
                row.projecttemplatedetails = row.projecttemplatedetails.filter(
                    (p) => {
                        return p;
                    }
                );
                if (row.start != 0) {
                    e.target.scrollTop = e.target.scrollTop + 5;
                }
            }
        },

        acdbtnrowhideshow: function(){
            this.showacdbtn = this.showacdbtn == true ? false : true;
            var bigdash = new Option();
            bigdash.innerHTML = "&mdash;";
            this.acdbtnexpandtext = this.showacdbtn == false ? "+" : bigdash.text;
        },

        // setprjpageheight: function() {
        //     this.scrollheight = window.innerHeight - 270;
        //     return this.scrollheight;
        // },

        charcount: function(val, colname, row) {
            if (/^\s+$/.test(val) || val == undefined) {
                val = "";
            }
            if (val.length >= 32 && colname == "workclassification") {
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert("You have reached the maximum limit of 32 characters."+style, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }
            if (val.length >= 50 && colname == "bqcode") {
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert("You have reached the maximum limit of 50 characters."+style, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }

            if (colname == "bqcode") {
                if (!this.ptDetailsFilteredIds.includes(row.id)) {
                    this.ptDetailsFilteredIds.push(row.id)
                }

                if (this.ppFiltersEnabled == true) {
                    this.ppFiltersEnabled = false;
                }
            }

            /*
            if (val.length >= 255 && (colname == "specification" || colname == "asterisk" || colname == "endnotes")) {
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert("You have reached the maximum limit of 255 characters."+style, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }
            */

            if (val.length > 300 && colname == 'sectiondecriptionheader') {
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert("You have reached the maximum limit of 300 characters."+style, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }
        },

        resizecolumnswidth: function(newWidth, oldWidth, column, event){
            this.$set(this.resizecolwidth, 'col1', this.$refs.col1.columnConfig.width);
            this.$set(this.resizecolwidth, 'col2', this.$refs.col2.columnConfig.width);
            this.$set(this.resizecolwidth, 'col3', this.$refs.col3.columnConfig.width);
            this.$set(this.resizecolwidth, 'col4', this.$refs.col4.columnConfig.width);
            this.$set(this.resizecolwidth, 'col5', this.$refs.col5.columnConfig.width);
            this.$set(this.resizecolwidth, 'col6', this.$refs.col6.columnConfig.width);
            this.$set(this.resizecolwidth, 'col7', this.$refs.col7.columnConfig.width);
        },

        load: function (row) {
            if (row.isevent == undefined) {
                row.isevent = true;
                this.$refs[row.id].bodyWrapper.addEventListener(
                    "scroll",
                    (parm) => {
                        return this.onScroll2(parm, row);
                    }
                );
            } else {
                //    this.$refs[row.id].bodyWrapper.scrollTop = this.$refs[row.id].bodyWrapper.scrollTop - 150;
            }
            row.start = row.start + 5;
            row.end = row.end + 6;
            row.projecttemplatedetails = row.projecttemplatedetails.filter(
                (p) => {
                    return p;
                }
            );
        },
        //Methods for SignalR
       async openSignalR(project){
            var item = {
             projectname :project.project.projectname,
             projectid :project.project.id,
             partname :project.part.name,
             partid :project.part.id,
             sectionname :project.project.section.sectiondisplay,
             sectionid:project.project.section.id,
             username :this.user.fullname,
             userid :this.user.id,
            };
            await this.$signalr.SentSectionOpen(item).then(()=>{
                this.refresh();
                }).catch(()=>{
                this.refresh();
                });
            this.iseditornot = true;
            this.CurentUser = {};
            this.CurentUser = item;
             this.warningdilogstring = "";
              window.clearInterval(this.interval);
               this.interval = window.setInterval(this.autosave, 10 * 60000);
         },
        //Methods for first page
       async projectsectionchange(Projectobj) {
            this.search = "";
            this.clearFiltersValue();
            this.sectionid = Projectobj.sectionid;
            this.detailform = Projectobj.project;
            if(this.detailform.id && this.sectionid)
            {
                this.getpageactionmessage("Project","open",this.detailform);
               await this.openSignalR(Projectobj)
            }
        //    await this.refresh();
        },

        pageCurrentChangedetail: function (val, row) {
            row.page = val;
            row.projecttemplatedetails = row.projecttemplatedetails.filter(
                (p) => {
                    return p;
                }
            );
        },

        pageCurrentChange: function (val) {
            this.page = val;
        },

        expandchange: function (row, expandedRows) {
            row.isevent = undefined;
            var expanded = expandedRows.map((v) => {
                return v.id;
            });
            this.expandedrows = expanded;
            var bigdash = new Option();
            bigdash.innerHTML = "&mdash;";
            this.expandcollapsetext = this.expandedrows.length
                ? bigdash.text
                : "+"; //not sure why bigger dash displays like this.
            this.showheader = this.expandcollapsetext == "+";
            //    if(this.$refs[row.id] != undefined){
            //         this.$refs[row.id].bodyWrapper.addEventListener('scroll', (parm) => { return this.onScroll2(parm,row)});
            //     }
        },

        expandcollapse: function () {
            if (this.expandedrows.length > 0) {
                this.expandedrows = [];
            } else {
                if (this.showschedules) {
                    var templates = this.detailform.projectschedules;
                } else {
                    var templates = this.detailform.projecttemplates;
                }
                this.expandedrows = templates.map((v) => {
                    return v.id;
                });
            }
            var bigdash = new Option();
            bigdash.innerHTML = "&mdash;";
            this.expandcollapsetext = this.expandedrows.length
                ? bigdash.text
                : "+";
            this.showheader = this.expandcollapsetext == "+";
            this.acdbtnexpandtext = bigdash.text;
        },

       async refresh() {
            if (this.detailform.id && this.sectionid) {
                this.getProject();
            } else {
                this.detail = false;
            }
        },

        //Detail form events start from here

        getProject: function () {
            var data = {
                id: this.detailform.id,
                section: { id: this.sectionid },
            };
            // this.loading = true;
            this.$store.state.isLoading = true;

            this.$http
                .post("project/getcurrent", data)
                .then((response) => {
                    //var json = JSON.parse(response.data);
                    var json = response.data;
                    json.section = json.sectionview;
                    json.sectionview = null;
                    if (json.section && !json.section.status) {
                        json.section.status = "";
                    }

                    this.openheader = json.section.description != null && json.section.description.trim() != '' ? true : false;
                    this.detailform = json;
                    this.detailform.projecttemplates.forEach((pt) => {
                        let squarebracket = this.checksquarebracket(pt.label);
                        pt.projecttemplatedetails.forEach((ptd) => {
                            if (squarebracket != null && squarebracket.length > 0) {
                                this.pickuptemplatedim(squarebracket, ptd);
                                this.calculatedim(ptd);
                                var dim = parseFloat(ptd.dim);
                                if (isNaN(dim)) {
                                    dim = 0;
                                    ptd.dim = 0;
                                }
                                var update = () => {
                                    if (ptd.unit == "item") {
                                        ptd.qty = 1;
                                    } else {
                                        ptd.qty = pt.qty * dim; //2 digits rounding
                                    }
                                };
                                update();
                            }
                            ptd.columnarr = this.decimaltobinary(ptd.editedfields);
                        });
                    });
                    // this.detailform.projecttemplates.forEach(projtmp => {
                    //     if(projtmp.origin == 'amos'){
                    //         projtmp.projecttemplatedetails.forEach((ptd,index) => {
                    //             if (ptd.category != null) {
                    //                 var i = index + 1;
                    //                 var c = ptd.category.split("::");
                    //                 if (c.length == 2) {
                    //                     ptd.category = c[0] +"::"+ c[1];
                    //                 }
                    //                 if(c.length == 1 && c[0] != ""){
                    //                     ptd.category = "wc-"+ i + "::"+ c[0];
                    //                 }
                    //                 if(c[0] == ""){
                    //                     ptd.category = "wc-"+ i +"::" + ptd.category;
                    //                 }
                    //             }
                    //         });
                    //     }
                    // });
                    this.attachments = [];
                    this.detail = json.section != "";

                    this.showschedules = json.projectschedules.length > 0;
                    this.showtemplates = json.projecttemplates.length > 0;
                    if (this.showtemplates) {
                        this.setdecimal();
                    }

                    // this.loading = false;
                    this.$store.state.isLoading = false;
                    if (this.detail == true) {
                        if (
                            this.expandedrows.length == 0 &&
                            this.expandedrows.length == 0
                        ) {
                            this.expandcollapse(); //expand all rows by default
                        }
                    }
                    //this.canedit(true, this.selectBillBQCode);
                    this.$bus.$emit("setscroll");
                    this.selectBillBQCode();
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                    // this.loading = false;
                    this.$store.state.isLoading = false;
                    //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                });
        },

        //for updating bqcode
        querySearchBQCode: function (query, done) {
            this.$http
                .post(
                    "bqcodelibrary/search?library=" +
                        this.detailform.library +
                        "&q=" +
                        query,
                    {}
                )
                .then((response) => {
                    this.bqcodes = JSON.parse(response.data);
                    done(this.bqcodes);
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },

        bqcodeblur: function (item, event) {
            if (!item.bqcodelibrary.bqcode) {
                item.bqcodelibraryid = 0;
                this.changed(null, item, null);
            }
        },

        handleSelectBQCode(item, value) {
            item.bqcodelibraryid = value.id;
            item.bqcodelibrary = value;
            this.changed(null, item, {index: 2});
        },

        bqcodechanged: function () {
            if (this.detailform.section.locked || !this.iseditornot) {
                this.$alert(
                    "You Do Not Currently Have Access Rights to Edit Work in this Section",
                    "View Only"
                );
                return;
            }
        },

        querySearchBCC: function (query, done) {
            this.$http
                .post("billcompilecode/search?q=" + query, {})
                .then((response) => {
                    var bcc = JSON.parse(response.data);
                    done(bcc);
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },

        //billcompilecode
        handleSelectBCC(item, value) {
            item.billcompilecodeid = value.id;
            item.billcompilecode = value;
            this.changed(null, item);
        },

        bccchanged: function () {
            if (this.detailform.section.locked && !iseditornot) {
                this.$alert(
                    "You Do Not Currently Have Access Rights to Edit Work in this Section",
                    "View Only"
                );
                return;
            }
        },

        updatestatus: function (status) {
            if (status) {
                this.detailform.section.status = status;
            }
            this.$http
                .post("project/updatestatus", this.detailform.section)
                .then((response) => {
                    this.$message({
                        showClose: true,
                        message: response.data,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },

        addRow: function (template, copy, row, rowindex) {
            var itm = {
                srno: parseInt(row.srno) + 1,
                dim: 1,
                isrequired: true,
                ptddimcalculations: [],
            };
            itm.bqcodelibrary = { bqcode: "" };
            if (copy) {
                if (template.projecttemplatedetails.length > 0) {
                    var last = template.projecttemplatedetails[rowindex];
                    var last = JSON.parse(JSON.stringify(last));
                    last.srno = itm.srno;
                    last.id = 0;
                    last.category += "-Copy";
                    last.ptddimcalculations.forEach((ptdd) => {
                        ptdd.id = 0;
                    });
                    itm = last;
                }
            }
            itm.edited = true;
            template.projecttemplatedetails.splice(rowindex + 1, 0, itm);
            var newsrno = 1;
            template.projecttemplatedetails.forEach(sn => {
                sn.srno = newsrno++;
            });
            this.changed(null, itm, null);
            this.dimchange(itm, template, null);
            itm.columnarr = this.decimaltobinary(0);
        },

        delRow: function (template, rowindex) {
            //(template, scope, row)
            //now logic changed to delete last row
            if (template.projecttemplatedetails.length > 0) {
                var del = () => {
                    template.edited = true;
                    template.projecttemplatedetails.splice( rowindex, 1 );
                    var srno = 1;
                    for (
                        var i = 0;
                        i < template.projecttemplatedetails.length;
                        i++
                    ) {
                        template.projecttemplatedetails[i].srno = srno++;
                    }
                    this.changed(template, null);
                };
                if (template.projecttemplatedetails.length == 1) {
                    this.$confirm(
                        "This is the last row of the template, are you sure delete?",
                        "Warning",
                        {
                            confirmButtonText: "OK",
                            cancelButtonText: "Cancel",
                        }
                    )
                        .then(() => {
                            if (template.qty == 0) {
                                this.deleteTemplateOnLastRow(template);
                            } else {
                                del();
                            }
                        })
                        .catch(() => {
                            /*Cancel clicked*/
                        });
                } else {
                    del();
                }
            }
        },

        pickuptemplatedim(squarebracket, row) {
            row.ptddimcalculations.forEach(x => {
                if (x.comment != null && x.comment.trim() != "") {
                    let splitedcode = x.comment.split("-").filter(Boolean);
                    for (let sb = 0; sb < squarebracket.length; sb++) {
                        const element = squarebracket[sb];
                        let labelcode = element.split(".").filter(Boolean);
                        if (splitedcode[0].toLowerCase() == labelcode[0].toLowerCase()) {
                            if (splitedcode[1] != undefined && labelcode[1] != undefined) {
                                labelcode[1] = `.${labelcode[1]}`;
                                let value = parseFloat(labelcode[1]);
                                if (!isNaN(value)) {
                                    switch (splitedcode[1].toUpperCase()) {
                                        case "N":
                                            x.nr = value;
                                            break;
                                        case "L":
                                            x.length = value;
                                            break;
                                        case "W":
                                            x.width = value;
                                            break;
                                        case "H":
                                            x.height = value;
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            }
                            break;
                        }
                    }
                }
            });
        },

        reloadTemplate: function (template) {
            var found = null;

            //found project base template
            found = this.detailform.projecttemplates.find(
                (t) => t.label.trim() == template.label.trim() && t.templateid == 0 && t.qty == 0
            );

            //found project template
            if (found == null) {
            found = this.detailform.projecttemplates.find(
                (t) => t.templateid == template.templateid && t.qty == 0
            );
            }
            var updateFromLocal = () => {
                if (found == null) {
                    updateFromServer();
                } else {
                    template.projecttemplatedetails = JSON.parse(
                        JSON.stringify(found.projecttemplatedetails)
                    );
                    template.matchedprojecttemplate = true;
                    template.matchedtemplate = false;
                    template.matchedtemplatethis = false;
                    updateIdQty();
                }
            };
            var updateFromServer = () => {
                this.$http
                    .post("template/getreload", { id: template.templateid })
                    .then((response) => {
                        var json = JSON.parse(response.data);
                        if(json == null)
                        {
                            var style = '<style> .el-message-box {width:32vw;} .el-message-box__message {max-height:60vh; overflow:auto} </style>'
                            this.$alert("The matching template does not exist in the original MC_Template library" + style, "Warning", {
                                dangerouslyUseHTMLString: true
                            });  
                            
                        }else{ 
                            //    template.label = json.templatename;                           
                           template.projecttemplatedetails = JSON.parse(
                               JSON.stringify(json.details)
                           );
                           template.projecttemplatedetails.forEach(ptd => {
                                ptd.columnarr = this.decimaltobinary(0);
                           });
                           template.matchedprojecttemplate = false;
                           template.matchedtemplate = true;
                           updateIdQty();
                        }
                    })
                    .catch((err) => {
                        this.$bus.$emit("error", err);
                    });
            };

            var updateIdQty = () => {
                let squarebracket = this.checksquarebracket(template.label);
                template.projecttemplatedetails.forEach((t, i) => {
                    var edited = t.edited;
                    t.id = i * -1;
                    if (t.qtydim) t.dim = t.qtydim;
                    if (t.tddimcalculations) {
                        t.ptddimcalculations = t.tddimcalculations;
                        if (squarebracket != null && squarebracket.length > 0) {
                            this.pickuptemplatedim(squarebracket, t);
                        }
                    }
                    t.ptddimcalculations.forEach((d, j) => {
                        d.id = j * -1;
                    });
                    this.dimchange(t, template, null);
                    t.edited = edited;
                });
                this.detailform.changed = true;
            };

            if (template.qty > 0) {
                var from =
                    found == null
                        ? " MC_Templates Library "
                        : " base template ";
                this.$confirm(
                    "This action will upload original template from" +
                        from +
                        ", All changes will be lost. Do you want to proceed?",
                    "Warning",
                    {
                        confirmButtonText: "OK",
                        cancelButtonText: "Cancel",
                    }
                )
                    .then(updateFromLocal)
                    .catch(() => {
                        /*Cancel clicked*/
                    });
            } else {
                this.$confirm(
                    "This action will upload original template from MC_Templates Library, All changes will be lost. Do you want to proceed?",
                    "Warning",
                    {
                        confirmButtonText: "OK",
                        cancelButtonText: "Cancel",
                    }
                )
                    .then(updateFromServer)
                    .catch(() => {
                        /*Cancel clicked*/
                    });
            }
        },

        confirmimportclose: function (value) {
            this.confirmimportdialog = false;
            if (value) {
                this.importconfirmed = true;
                this.save();
            }
        },

        showcreatebaseversiondialogue: function () {
            var base = {
                sectiondisplay:
                    this.detailform.section.sectiondisplay + " - Base",
                section: this.detailform.section.section,
            };

            var basecount = this.detailform.projectsections.filter((ps) => {
                return ps.section == base.section && ps.version != null;
            });

            base.version =
                "Base " + (basecount.length + 1).toString().padStart(2, 0);
            base.sectiondisplay =
                this.detailform.section.sectiondisplay + " - " + base.version;
            this.showcreatebaseversion = true;

            this.baseversion = base;
        },
        saveversion: function () {
            //check for duplicate

            var ps = JSON.parse(JSON.stringify(this.detailform.section));

            ps.copiedfrom = ps.id;
            ps.sectiondisplay = this.baseversion.sectiondisplay;
            ps.version = this.baseversion.version;
            ps.comment = this.baseversion.comment;
            ps.locked = true;
            ps.id = 0;
            if (!ps.comment) {
                this.$alert("Purpose must not be empty");
                return;
            }

            //if(!ps.version){
            //    this.$alert("Version must not be empty")
            //    return;
            //}

            this.$http
                .post("project/CopyProjectSection", ps)
                .then((response) => {
                    this.$message({
                        showClose: true,
                        message: response.data,
                        type: "success",
                    });
                    this.showcreatebaseversion = false;
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },

        confirmimport: function () {
            this.$http
                .post("project/importcheck", this.detailform)
                .then((response) => {
                    var json = JSON.parse(response.data);
                    var missingarrobject = json.missing.map(m => { return { label: m, selected: false, unit: null }});
                    json.missing = missingarrobject;

                    if (
                        json.toadd.length > 0 ||
                        json.todelete.length > 0 ||
                        json.toupdate.length > 0
                    ) {
                        this.importcheck = json;
                        this.confirmimportdialog = true;
                        this.importconfirmed = false;
                        this.issave = false;
                    } else {
                        //for schedule import no warning.
                        this.importconfirmed = true;
                        this.issave = false;
                        this.save();
                    }
                })
                .catch((err) => {
                    this.issave = false;
                    if (err.response.data != null) {
                        var projecterr = [];
                        projecterr.push(err.response.data);
                        this.errorobject = {
                            message: projecterr, type: "required", visible: true, sourcereq: ""
                        };
                        this.validationerror = true;
                    }
                    else {
                        this.$bus.$emit("error", err);
                    }
                    this.importconfirmed = false;
                    //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                    //this.$alert(err.data, err.response.status + ' ' + err.response.statusText);
                });
        },

        canedit: function (warn, callback) {
            var cb = function () {
                if (typeof callback == "function") {
                    callback();
                }
            };

            if (this.detailform) {
                if (this.detailform.section.locked || !this.iseditornot) {
                    if (warn) {
                        this.$alert(
                            "You Do Not Currently Have Access Rights to Edit Work in this Section",
                            "View Only"
                        ).then(cb);
                    }
                    return false;
                }
                if (
                    this.user.role == "B" &&
                    this.user.sections.findIndex(
                        (x) => x.section == this.detailform.section.section
                    ) == -1
                ) {
                    if (warn) {
                        this.$alert(
                            "This section is not in your allowed editing list, you wil not be able to save changes"
                        ).then(cb);
                    }
                    return false;
                }
                return true;
            }
            return false;
        },

        warnerror: function (warncheck) {
            this.warncheck = warncheck;
            if (this.warncheck) {
                this.save();
            }
        },

        async movecheckingpage(name) {
            let projectid = this.detailform.id;
            let partid = this.detailform.projectpart.id == null ? 0 : this.detailform.projectpart.id;
            let sectionid = this.detailform.section.id;

            let params = new URLSearchParams();
            params.append('projectid', projectid);
            params.append('partid', partid);
            params.append('sectionid', sectionid);
            let errormessage = "";

            if (name == 'checklistsection') {
                var response = await this.$training.checksectionforchecklist();
                let isexist = response.find(x => x.nrmelementid == this.detailform.section.section && x.formtype == this.detailform.library);
                if (isexist == undefined) {
                    errormessage = "This NRM section is not available on the Checklist-Sections page.";
                }
            }

            if (errormessage != "") {
                this.$notify({
                    type: 'warning',
                    title: 'Invalid Data',
                    message: errormessage,
                    duration: 15000
                });
            } else {
                let url = `/${name}?${params.toString()}`;
                window.open(url, '_blank');
            }
        },

        // validdatalength(data) {
        //     var errormsg = [];

        //     data.forEach((t1) => {
        //         t1.projecttemplatedetails.forEach((td1) => {
        //             var other = "";
        //             var specification = "";
        //             var work = "";
        //             var level1 = "";
        //             var level2 = "";
        //             var level3 = "";
        //             var asterisk = "";
        //             if (td1.bqcodelibrary == null) {
        //                 td1.bqcodelibrary = {};
        //             }
        //             other = td1.other;
        //             specification = td1.specification;
        //             asterisk = td1.asterisk;
        //             work = td1.bqcodelibrary.work;
        //             level1 = td1.bqcodelibrary.level1;
        //             level2 = td1.bqcodelibrary.level2;
        //             level3 = td1.bqcodelibrary.level3;
        //             if (asterisk == null) {
        //                 asterisk = "";
        //             }
        //             if (other == null) {
        //                 other = "";
        //             }
        //             if (specification == null) {
        //                 specification = "";
        //             }
        //             if (work == null) {
        //                 work = "";
        //             }
        //             if (level1 == null) {
        //                 level1 = "";
        //             }
        //             if (level2 == null) {
        //                 level2 = "";
        //             }
        //             if (level3 == null) {
        //                 level3 = "";
        //             }

        //             if (asterisk != null && asterisk != "") {
        //                 var asterisksEntries = asterisk.split("^");
        //                 // var desc = td1.bqcodelibrary.work + td1.bqcodelibrary.level1 + td1.bqcodelibrary.level2 + td1.bqcodelibrary.level3;
        //                 // var asterisks = desc.split('*');
        //                 asterisksEntries.forEach((as) => {
        //                     if (work != null && work.includes("*"))
        //                         work = work.replace("*", as);
        //                     else if (level1 != null && level1.includes("*"))
        //                         level1 = level1.replace("*", as);
        //                     else if (level2 != null && level2.includes("*"))
        //                         level2 = level2.replace("*", as);
        //                     else if (level3 != null && level3.includes("*"))
        //                         level3 = level3.replace("*", as);
        //                 });
        //             }
        //             if (specification != null) {
        //                 switch (td1.bqcodelibrary.specification) {
        //                     case 0:
        //                         work = work + specification;
        //                         break;
        //                     case 1:
        //                         level1 = level1 + specification;
        //                         break;
        //                     case 2:
        //                         level2 = level2 + specification;
        //                         break;
        //                     case 3:
        //                         level3 = level3 + specification;
        //                         break;
        //                 }
        //             } else {
        //                 specification = "";
        //             }
        //             if (other != null && other != "") {
        //                 if (level3 != null && level3 != "") {
        //                     level3 = level3 + other;
        //                 } else if (level2 != null && level2 != "") {
        //                     level2 = level2 + other;
        //                 } else {
        //                     level1 = level1 + other;
        //                 }
        //             } else {
        //                 other = "";
        //             }

        //             //     if(asterisk.length > 255){
        //             //     //   var newother =  other.substring(0, other.length/4) + '  </br>  ' + other.substring(other.length/4,other.length/2) + ' </br>  '+ other.substring(other.length/2,other.length)

        //             //      errormsg.push('Your data within '+ t1.label +','+td1.srno +', '+ td1.bqcodelibrary.bqcode +','+ asterisk +'  </br> exceed 255 characters limitation. Please, correct the Asterisk or specification or BQ_code within library')
        //             //   }
        //             if (other.length > 400) {
        //                 errormsg.push(
        //                     "Your data within " +
        //                         t1.label +
        //                         "," +
        //                         td1.srno +
        //                         ", " +
        //                         td1.bqcodelibrary.bqcode +
        //                         "," +
        //                         other +
        //                         "  </br> exceed 400 characters limitation. Please, correct the Asterisk or specification or BQ_code within library"
        //                 );
        //             }
        //             if (work.length > 400) {
        //                 errormsg.push(
        //                     "Your data within " +
        //                         t1.label +
        //                         "," +
        //                         td1.srno +
        //                         ", " +
        //                         td1.bqcodelibrary.bqcode +
        //                         "," +
        //                         work +
        //                         "  </br> exceed 400 characters limitation. Please, correct the Asterisk or specification or BQ_code within library"
        //                 );
        //             }
        //             if (level1.length > 400) {
        //                 errormsg.push(
        //                     "Your data within " +
        //                         t1.label +
        //                         "," +
        //                         td1.srno +
        //                         ", " +
        //                         td1.bqcodelibrary.bqcode +
        //                         "," +
        //                         level1 +
        //                         "</br> exceed 400 characters limitation. Please, correct the  Asterisk or specification or BQ_code within library"
        //                 );
        //             }
        //             if (level2.length > 400) {
        //                 errormsg.push(
        //                     "Your data within " +
        //                         t1.label +
        //                         "," +
        //                         td1.srno +
        //                         ", " +
        //                         td1.bqcodelibrary.bqcode +
        //                         "," +
        //                         level2 +
        //                         "</br> exceed 400 characters limitation. Please, correct the  Asterisk or specification or BQ_code within library"
        //                 );
        //             }
        //             if (level3.length > 400) {
        //                 errormsg.push(
        //                     "Your data within " +
        //                         t1.label +
        //                         "," +
        //                         td1.srno +
        //                         ", " +
        //                         td1.bqcodelibrary.bqcode +
        //                         "," +
        //                         level3 +
        //                         "</br> exceed 400 characters limitation. Please, correct the  Asterisk or specification or  BQ_code within library"
        //                 );
        //             }
        //         });
        //     });

        //     return errormsg;
        // },        

        validationwarning () {
            var msg = [];
            var reqmsg = [];
            var warnmsg = [];
            if (this.showtemplates) {
                var templates = this.detailform.projecttemplates;

                // var customtemplates = [];
                // customtemplates.push(...templates);
                // reqmsg.push(...this.validdatalength(customtemplates));
            //    var customtemplates = [];
                templates.forEach((pt) => {
                    var reqstr = "";

                    var str = pt.templatename || pt.label;
                    if(str && pt.subject){
                        str = str + ":    " + pt.subject
                        reqstr = str;
                    }
                    var wc = [];
                    pt.projecttemplatedetails.forEach((t, i) => {
                        var specification = t.specification ? t.specification : "";
                        var asterisk = t.asterisk ? t.asterisk : "";
                        var endnotes = t.other ? t.other : "";

                        if (specification.length + asterisk.length > 400) {
                            if (reqstr) {
                                reqmsg.push("<p style='font-weight: bold;'>" + reqstr + ": </p>");
                                reqstr = "";
                            }

                            reqmsg.push("<p style='word-wrap: break-word;'>Your data within 'Specification + Asterisk' column of row - " + t.srno + ".</br> exceed 400 characters limitation. Please, correct the Specification or Asterisk.</p>")
                        }

                        if (endnotes.length > 255) {
                            if (reqstr) {
                                reqmsg.push("<p style='font-weight: bold;'>" + reqstr + ": </p>");
                                reqstr = "";
                            }

                            reqmsg.push("<p style='word-wrap: break-word;'>Your data within 'End Notes' column of row - " + t.srno + ".</br> exceed 255 characters limitation. Please, correct the End Notes.</p>")
                        }

                        
                        wc.push({ value: t.category, srno: t.srno });
                        if (!t.unit || !t.bqcodelibraryid) {
                            if (str) {
                                warnmsg.push(str + ":");
                                str = "";
                            }
                            warnmsg.push(
                                "Check bqcode/unit for row " + (i + 1)
                            );
                        } else {
                            if (t.bqcodelibrary != null) {
                                if (t.unit != null) {
                                    if (t.bqcodelibrary.unit != t.unit) {
                                        if (str) {
                                            warnmsg.push(str + ":");
                                            str = "";
                                        }
                                        warnmsg.push(
                                            "in row " +
                                                (i + 1) +
                                                " unit assigned to BQ Code " +
                                                t.bqcodelibrary.bqcode +
                                                " does not match BQ Code Library unit for same BQ Code"
                                        );
                                    }
                                }
                            }
                        }
                        if (t.bqcodelibrary != null) {
                            if (t.bqcodelibrary.uppertolerance > 0) {
                                if (
                                    t.dim < t.bqcodelibrary.lowertolerance ||
                                    t.dim > t.bqcodelibrary.uppertolerance
                                ) {
                                    if (str) {
                                        warnmsg.push(str + ":");
                                        str = "";
                                    }
                                    warnmsg.push(
                                        str +
                                            " Dim should be between " +
                                            t.bqcodelibrary.lowertolerance +
                                            " and " +
                                            t.bqcodelibrary.uppertolerance +
                                            " for row " +
                                            (i + 1)
                                    );
                                }
                            }
                            //check number of asterisk match
                            var asterisksEntries = [];
                            if (t.asterisk) {
                                var asterisksEntries = t.asterisk.split("^");
                            }
                            var desc = "".concat(
                                t.bqcodelibrary.work,
                                t.bqcodelibrary.level1,
                                t.bqcodelibrary.level2,
                                t.bqcodelibrary.level3
                            );
                            var asterisks = desc.split("*");

                            if (
                                asterisksEntries.length !=
                                asterisks.length - 1
                            ) {
                                if (str) {
                                    warnmsg.push(str + ":");
                                    str = "";
                                }
                                warnmsg.push(
                                    "".concat(
                                        "Number of asterisks ",
                                        asterisks.length - 1,
                                        " does not match with number of asterisk values ",
                                        asterisksEntries.length,
                                        " for row " + (i + 1)
                                    )
                                );
                            }
                        }
                        if (!t.dim || t.dim <= 0) {
                            if (str) {
                                warnmsg.push(str + ":");
                                str = "";
                            }
                            warnmsg.push("Dim is required for row " + (i + 1));
                        }
                        if (
                            t.isrequired == true &&
                            this.detailform.section.locked == false
                        ) {
                            if (t.ptddimcalculations.length > 0) {
                                var Cells = [];
                                var cell = "";
                                var dimsarr = [];

                                if (t.ptddimcalculations.length < 2) {
                                    dimsarr = t.ptddimcalculations;
                                } else {
                                    var item = this.getdimsum(t);
                                    dimsarr.push(item);
                                }
                                dimsarr.forEach((dc) => {
                                    if (t.unit == "nr") {
                                        if (dc.nr == 0 || dc.nr == undefined) {
                                            Cells.push("Nr");
                                        }
                                        if (
                                            dc.length == 0 ||
                                            dc.nr == undefined
                                        ) {
                                            Cells.push("Length");
                                        }
                                        if (
                                            dc.width == 0 ||
                                            dc.nr == undefined
                                        ) {
                                            Cells.push("Width");
                                        }
                                        if (
                                            dc.height == 0 ||
                                            dc.nr == undefined
                                        ) {
                                            Cells.push("Height");
                                        }
                                    }
                                    if (t.unit == "m") {
                                        if (dc.nr == 0 || dc.nr == undefined) {
                                            Cells.push("Nr");
                                        }
                                        if (
                                            dc.width == 0 ||
                                            dc.nr == undefined
                                        ) {
                                            Cells.push("Width");
                                        }
                                        if (
                                            dc.height == 0 ||
                                            dc.nr == undefined
                                        ) {
                                            Cells.push("Height");
                                        }
                                    }
                                    if (t.unit == "m2") {
                                        if (dc.nr == 0 || dc.nr == undefined) {
                                            Cells.push("Nr");
                                        }
                                        if (
                                            dc.height == 0 ||
                                            dc.nr == undefined
                                        ) {
                                            Cells.push("Height");
                                        }
                                    }
                                    if (t.unit == "m3") {
                                        if (dc.nr == 0 || dc.nr == undefined) {
                                            Cells.push("Nr");
                                        }
                                    }
                                });
                                cell = Cells.join(", ");
                                Cells = [];
                                if (cell != "") {
                                     if (str) {
                                         warnmsg.push(str + ":");
                                        str = "";
                                    }
                                    warnmsg.push(
                                            "Template unit is " +
                                            pt.unit +
                                            " and Row No " +
                                            (i + 1) +
                                            " Bq code " +
                                            t.bqcodelibrary.bqcode +
                                            " unit is " +
                                            t.unit +
                                            ". <br/> Dim pad usage is mandatory. <br/> Cells " +
                                            cell +
                                            " should be filled with data.<br/>"
                                    );
                                    cell = "";
                                }
                            }
                        }
                    });
                    for (var j = 0; j < wc.length; j++) {
                        var wc1 = wc[j];
                        if (!wc1.value) {
                            if (str) {
                                warnmsg.push(str + ":");
                                str = "";
                            }
                            warnmsg.push(
                                "Check Work Classification entry for row " +
                                    wc1.srno +
                                    ". No empty cells allowed"
                            );
                        }
                        for (var k = 0; k < wc.length; k++) {
                            var wc2 = wc[k];
                            if (wc1.value) {
                                if (
                                    wc1.value == wc2.value &&
                                    wc1.srno != wc2.srno
                                ) {
                                    if (str) {
                                        warnmsg.push(str + ":");
                                        str = "";
                                    }
                                    warnmsg.push(
                                        "Check Work Classification entry for row " +
                                            wc1.srno +
                                            " and " +
                                            wc2.srno +
                                            ". No duplication cells allowed"
                                    );
                                }
                            } else {
                                break;
                            }
                        }
                    }
                });
            } else {
                var templates = this.detailform.projectschedules;
                templates.forEach((pt) => {
                    var str = pt.mcname;
                    pt.projectscheduledetails.forEach((t, i) => {
                        if (!t.unit || !t.bqcodelibraryid) {
                            if (str) {
                                warnmsg.push(str + ":");
                                str = "";
                            }
                            warnmsg.push(
                                str + " Check bqcode/unit for row " + (i + 1)
                            );
                        } else {
                            if (t.bqcodelibrary != null) {
                                if (t.unit != null) {
                                    if (t.bqcodelibrary.unit != t.unit) {
                                        if (str) {
                                            warnmsg.push(str + ":");
                                            str = "";
                                        }
                                        warnmsg.push(
                                            "in row " +
                                                (i + 1) +
                                                " unit assigned to BQ Code " +
                                                t.bqcodelibrary.bqcode +
                                                " does not match BQ Code Library unit for same BQ Code"
                                        );
                                    }
                                }
                                //check number of asterisk match
                                var asterisksEntries = [];
                                if (t.asterisk) {
                                    asterisksEntries = t.asterisk.split("^");
                                }
                                var desc = "".concat(
                                    t.bqcodelibrary.work,
                                    t.bqcodelibrary.level1,
                                    t.bqcodelibrary.level2,
                                    t.bqcodelibrary.level3
                                );
                                var asterisks = desc.split("*");

                                if (
                                    asterisksEntries.length !=
                                    asterisks.length - 1
                                ) {
                                    if (str) {
                                        warnmsg.push(str + ":");
                                        str = "";
                                    }
                                    warnmsg.push(
                                        "".concat(
                                            "Number of asterisks ",
                                            asterisks.length - 1,
                                            " does not match with number of asterisk values ",
                                            asterisksEntries.length,
                                            " for row " + (i + 1)
                                        )
                                    );
                                }
                            }
                        }
                    });
                });
            }
            //  warnmsg.push("Template unit is {C} and Row No {2 as exampled on snip} Bq code unit is t.Dim pad usage is mandatory. Cells Nr (D1) (and if required Length, Width, Height as conditions [1]-[4] ) should be filled with data.")
            //if (msg.length > 0) {

            //    this.detailform.section.errors = msg.join("<br/>");
            //    this.message = msg;
            //    this.validationerror = true;
            //    //var style = '<style> .el-message-box {width:80%;} </style>'
            //    //this.$alert(msg.join('<br />') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
            //}
            //else {
            //    this.detailform.section.errors = null;
            //}

            let headerbutton = true;
            let data = {
                id: this.detailform.section.id
            };

            return this.$training.verifycheckingpage(data).then(isverify => {
                if(this.detailform.projectschedules.length > 0 || this.detailform.projecttemplates.length > 0) {
                    if (!isverify.rwc || !isverify.cqc || !isverify.signoffsection || !isverify.checklistsection) {
                        headerbutton = false; // This method is created to check buttons located in the header of a custom dialog box.
                    }
                }
                this.detailform.section.errors = null;
                if (reqmsg.length > 0) {
                    this.detailform.section.errors = reqmsg.join("<br>");
                    this.errorobject = {
                        message: reqmsg,
                        type: "required",
                        visible: true,
                    };
                    this.validationerror = true;
                    return false;
                } else {
                    if (warnmsg.length > 0 || !headerbutton) {

                        if (warnmsg.length > 0) {
                            this.detailform.section.errors = warnmsg.join("<br>");
                        }

                        this.message = warnmsg;
                        this.errorobject = {
                            message: warnmsg,
                            type: "warningproject",
                            visible: true,
                        };
                        this.validationerror = true;
                    }
                }
                return true;
            });

        },

        getfilehistory() {
            this.showloading();
            let data = {
                id : this.detailform.id,
                projectname : this.detailform.projectname,
                projectpart : this.detailform.projectpart,
                section : this.detailform.section
            };
            let url = "project/getprojectfilehistory";
            this.$http.post(url, data)
                .then(response => {
                    this.filehistory = response.data;
                    this.visiblemedia = true;
                    this.hideloading();
                }).catch(err => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
        },

        handledialogclose() {
            this.visiblemedia = false;
        },

        getdimsum(row) {
            const sums = { height: 0, length: 0, nr: 0, width: 0 };
            row.ptddimcalculations.forEach((column) => {
                sums.height += column.height != undefined ? column.height : 0;
                sums.length += column.length != undefined ? column.length : 0;
                sums.nr += column.nr != undefined ? column.nr : 0;
                sums.width += column.width != undefined ? column.width : 0;
            });
            return sums;
        },
    // If you change in save function then you need to check autosave function also.
        save() {
             this.$nextTick(()=>{
                if(!this.issave){
                    
                this.issave = true;
                if (!this.canedit(true)) {
                    return;
                }

                this.validationwarning()
                .then((res) => {
                    if (res == false) {
                        this.issave = false;
                        return;
                    }

                    /*
                    if (!this.validationwarning()) {
                        this.issave = false;
                        return;
                    }
                    */

                    if (!this.importconfirmed && this.attachments.length > 0) {
                        this.confirmimport();
                        return;
                    }

                    this.saving = true;
                    this.getpageactionmessage("Project","Save",this.detailform);
                    this.$bus.$emit("savescroll");

                    this.clearElementsHeights();
                    
                    this.$http
                        .post("project/saveCurrent", this.detailform)
                        .then((response) => {
                            this.issave = false;
                            this.saving = false;
                            this.warncheck = false;

                            this.$message({
                                showClose: true,
                                message: response.data,
                                type: "success",
                            });

                            this.detail = false;
                            //this.$refs.detailform.resetFields();
                            this.refresh();
                            this.importconfirmed = false;
                            this.attachments = [];
                            //reset autosave timings
                            window.clearInterval(this.interval);
                            this.interval = window.setInterval(
                                this.autosave,
                                10 * 60000
                            );
                        })
                        .catch((err) => {
                            this.issave = false;
                            
                            if (err.response.data != null) {
                                var projecterr = [];
                                projecterr.push(err.response.data);
                                this.errorobject = {
                                    message: projecterr, type: "required", visible: true, sourcereq: ""
                                };
                                this.validationerror = true;
                            }

                            else {
                                this.$bus.$emit("error", err);
                            }
                            this.saving = false;
                            this.warncheck = false;
                            this.importconfirmed = false;
                            //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                            //this.$alert(err.data, err.response.status + ' ' + err.response.statusText);
                        });
                    });
                }
            });
        },

        addAttachment(file, fileList) {
            if (file.file.size > 10000 * 1024) {
                this.$alert(
                    "File size must be less than 10 mb",
                    "File Size exceeds the limit"
                );
                return;
            }

            if (file.file.name.length >= 49) {
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert("File name should not exceed 45 characters."+style, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
                return;
            }
            this.getpageactionmessage("Project","import",this.detailform);
            this.attachments = [file.file];
            var ext = file.file.name
                .substr(file.file.name.length - 4)
                .toLowerCase();
            if (ext == ".xml") {
                var projectxml = {
                    projectid: this.detailform.id,
                    xml: "",
                    filename: file.file.name,
                };
                this.detailform.projectxml = projectxml;
                var fileReader = new FileReader();
                fileReader.onload = function (e) {
                    projectxml.xml = fileReader.result;
                };
                fileReader.readAsText(this.attachments[0]);
                this.detailform.projectschedule = null;
            } else if (ext == "xlsx") {
                var projectschedule = {
                    projectid: this.detailform.id,
                    xml: "",
                };
                this.detailform.projectschedule = projectschedule;
                var fileReader = new FileReader();
                projectschedule.name = file.file.name;
                fileReader.onload = function (e) {
                    var arrayBuffer = fileReader.result;
                    //projectschedule.xlsx = new Uint8Array(arrayBuffer);
                    //projectschedule.xlsx = new Int8Array(arrayBuffer);
                    projectschedule.xlsx = arrayBuffer;
                };
                //fileReader.readAsArrayBuffer(this.attachments[0]);
                //fileReader.readAsText(this.attachments[0]);
                //fileReader.readAsBinaryString(this.attachments[0]);
                fileReader.readAsDataURL(this.attachments[0]);
                this.detailform.projectxml = null;
            }
            this.changed(null, null);
        },

        deleteAttachment() {
            this.attachments = [];
            this.detailform.projectxml = null;
            this.detailform.projectschedule = null;
            this.changed(null, null);
        },

        deldim: function (pt, ptd, index) {
            if (this.detailform.section.locked || !this.iseditornot) {
                this.$alert(
                    "You Do Not Currently Have Access Rights to Edit Work in this Section",
                    "View Only"
                );
                return;
            }
            ptd.ptddimcalculations.splice(index, 1);
            this.calculatedim(ptd);
            this.dimchange(ptd, pt, null);
        },

        adddim: function (ptd) {
            if (this.detailform.section.locked || !this.iseditornot) {
                this.$alert(
                    "You Do Not Currently Have Access Rights to Edit Work in this Section",
                    "View Only"
                );
                return;
            }
            var itm = {
                id: (ptd.ptddimcalculations.length + 1) * -1,
                projecttemplatedetailid: ptd.id,
                nr: 0,
                length: 0,
                width: 0,
                height: 0,
            };
            ptd.ptddimcalculations.push(itm);
        },

        calculatedim: function (row) {
            if (row.ptddimcalculations.length > 0) {
                var sum = { nr: 0.0, length: 0.0, width: 0.0, height: 0.0 };
                row.ptddimcalculations.forEach((a) => {
                    if (!isNaN(a.nr)) sum.nr += parseFloat(a.nr);
                    if (!isNaN(a.length)) sum.length += parseFloat(a.length);
                    if (!isNaN(a.width)) sum.width += parseFloat(a.width);
                    if (!isNaN(a.height)) sum.height += parseFloat(a.height);
                });
                row.dim = 1;
                if (sum.nr > 0) row.dim *= sum.nr;
                if (sum.length > 0) row.dim *= sum.length;
                if (sum.width > 0) row.dim *= sum.width;
                if (sum.height > 0) row.dim *= sum.height;

                var dim = row.dim;
            }
        },

        checksquarebracket(str) {
            const regex = /\[[^\[\]]*?\]/g;
            const matches = Array.from(str.matchAll(regex));
            if (matches.length > 0) {
                let ret = matches[matches.length - 1][0]; 
                return ret.substring(1, ret.length - 1).split(", ");
            }
            return [];
        },

        dimchange: function (row, pt, columnname) {
            //this will also be called when unit change to check item.
            if (this.detailform.section.locked || !this.iseditornot) {
                this.$alert(
                    "You Do Not Currently Have Access Rights to Edit Work in this Section",
                    "View Only"
                );
                return;
            }
            
            if (columnname != null && columnname == 'comment') {
                let squarebracket = this.checksquarebracket(pt.label);
                if (squarebracket != null && squarebracket.length > 0) {
                    this.pickuptemplatedim(squarebracket, row);
                }
            }

            this.calculatedim(row);
            var dim = parseFloat(row.dim);
            if (isNaN(dim)) {
                dim = 0;
                row.dim = 0;
            }
            var update = () => {
                if (row.unit == "item") {
                    row.qty = 1;
                } else {
                    row.qty = pt.qty * dim; //2 digits rounding
                }
                //row.dim = dim.toFixed(3) // 3 digits rounding
            };
            update();
            window.setTimeout(update, 100);
            this.changed(null, row, {index: 3});
        },

        getDimsTotal(row, param) {
            const sums = [];
            param.columns.forEach((column, index) => {
                if (column.property === "comment") {
                    sums[index] = "Total";
                    return;
                }
                if (column.property === "action") {
                    sums[index] = "";
                    return;
                }

                const values = param.data.map((item) =>
                    Number(item[column.property])
                );
                if (!values.every((value) => isNaN(value))) {
                    sums[index] =
                        "" +
                        values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                } else {
                    sums[index] = "0.000";
                }
            });

            sums.forEach((v, i) => {
                if (!isNaN(v) && v != "") {
                    sums[i] = parseFloat(v).toFixed(3);
                }
            });
            return sums;
        },

        autosave: function () {
            //auto save every 10 minutes
            if (this.$route.path != "/projectpage") {
                window.clearInterval(this.interval);
                this.interval = null;
                return;
            }
            if (this.detailform.changed) {
                if (!this.canedit(false)) {
                    this.issave = false;
                    this.save();
                }
            }
        },

        srnochanged: function (template, index, row) {
            if (this.detailform.section.locked || !this.iseditornot) {
                this.$alert(
                    "You Do Not Currently Have Access Rights to Edit Work in this Section",
                    "View Only"
                );
                return;
            }
            var compare = function (a, b) {
                return a.srno > b.srno ? 1 : b.srno > a.srno ? -1 : 0;
            };

            if (this.showschedules) var tds = template.projectscheduledetails;
            else var tds = template.projecttemplatedetails;

            template.projecttemplatedetails =
                template.projecttemplatedetails.sort(compare);

            var srno = 1;
            template.projecttemplatedetails.forEach((v, i) => {
                v.srno = srno++;
            });
            this.changed(null, row, {index: 0});
        },

        changed: function (template, row, column) {
            if (this.detailform.section.locked || !this.iseditornot) {
                this.$alert(
                    "You Do Not Currently Have Access Rights to Edit Work in this Section",
                    "View Only"
                );
                return;
            }
            if (row != null) {
                row.edited = true;
                if (column != null) {
                    this.columneditchange(row, column.index);
                }
            }
            this.detailform.changed = true;
        },

        columneditchange: function (row, colindex){
            if (row.columnarr != undefined) {
                row.columnarr[colindex] = `1`;
                row.editedfields = this.binarytodecimal(row);
            }
        },

        decimaltobinary: function(dn){
            var dbn = parseInt(dn);
            var result = dbn.toString(2);

            var arr = [...result];
            if(arr.length < 9){
                var newarr = [];
                for(var i = 0; i < 9 - arr.length ; i++) {
                    newarr.push(`0`);
                }

                var ts = [...newarr];
                ts.push(...arr);
                arr = ts
            }
            return arr;
        },

        binarytodecimal: function(row){
            var s1 = "";
            row.columnarr.forEach(e => {
                s1 = s1 + e;
            });
           return parseInt(s1,2);
        },

        beforewindowunload: function (e) {
            //Prevent accidently close/reload window
            if (this.detailform.changed) {
                //return window.confirm('Do you really want to leave? you have unsaved changes!');
                return "Do you really want to leave ? you have unsaved changes!";
            }
        },

        // tableRowClassName({ row, rowIndex }) {
        //     var rs = parseInt(row.srno);
        //     row.srno = rs > 9 ? rs : "0" + rs;

            // if (row.edited) {
            //     return "lightblue";
            // }
            // return "";
        // },

        tablecellclassname: function({row, column, rowIndex, columnIndex}){
            var rs = parseInt(row.srno);
            row.srno = rs > 9 ? rs : "0" + rs;

            column.index = columnIndex;
            if (row.edited && row.columnarr[columnIndex] == '1') {
                return "editedcolumncolor";
            }
            return "";
        },

        headerrowstyleborder({row, column, rowIndex, columnIndex}){
            // if (columnIndex == 8){
            //     return {"color":"white","background-color": "#BBBBBB","border-right": "none"}
            // }

            if (columnIndex == 7)
            {
                return { 
                    "background-color": "white", 
                    "border-right": "none !important",
                    "border-top": "none !important",
                    "border-bottom": "none !important"
                }
            }
            
            if (rowIndex == 0 && columnIndex != 7){
                return {"color":"white","background-color": "#BBBBBB","border-right":"1px solid #878787","padding": "3px"}
            }

        },

      async closedialogue(basetemplates) {
            this.projectbqcode = false;
            this.projecttemplate = false;
            if (basetemplates.length > 0) {
                basetemplates.forEach(bt => {
                   this.detailform.projecttemplates.find((pt) => {
                        if (bt.label.trim() == pt.label.trim() && pt.templateid == 0) {
                            pt.templateid = bt.templateid;
                            pt.unit = bt.unit;
                            pt.measuredunit = bt.unit;
                            pt.matchedtemplate = bt.matchedtemplate;
                        }
                    });
                });
            }
            if (this.detailform.changed) {
                this.save();
            } else {
               await this.refresh();
            }
        },

        to2decimalformatter: function (row, column, value, index) {
            var result = this.to2decimal(value);
            return result;
        },

        to3decimalformatter: function (row, column, value, index) {
            var result = this.to3decimal(value);
            return result;
        },

        to2decimal: function (value) {
            if (value) {
                return value.toFixed(2);
            }
            return value;
        },

        to3decimal: function (value) {
            if (value) {
                return value.toFixed(3);
            }
            return value;
        },

        setdecimal: function () {
            return; //now this is not required
            var templates = this.detailform.projecttemplates;
            templates.forEach((pt) => {
                pt.projecttemplatedetails.forEach((t, i) => {
                    t.dim = t.dim.toFixed(3);
                });
            });
        },

        appendTip: function (h, { column, $index }) {
            // Function(h, { column, $index })
            var content = "";
            if (column.property == "isrequired") {
                content = "Dimension is Required (in Qty Dim box)";
            } else if (column.property == "srno") {
                content = "Renumber rows to change order";
            } else if (column.property == "bqcodelibrary.specification") {
                content = "Specification Insert Level - within Library";
            } else {
                return column.label;
            }

            return h(
                "el-popover",
                {
                    props: {
                        placement: "top",
                        // title: "hello",
                        // width: "200",
                        trigger: "hover",
                    },
                },
                [
                    content,
                    h(
                        "span",
                        {
                            slot: "reference",
                        },
                        column.label
                    ),
                ]
            );
        },

        selectBillBQCode: function () {
            this.bqcodesfound = [];
            if (this.$store.state.billbqcode) {
                var viewbqcode = this.$store.state.billbqcode;
                var viewasterisk = this.$store.state.billasterisk;
                this.$store.state.billbqcode = "";
                this.$store.state.billasterisk = "";
                this.searchbqcode = viewbqcode;
                this.searchastspeother = viewasterisk;
                var found = 0;
                var t = { id: 0 };
                if (this.showtemplates) {
                    this.detailform.projecttemplates.forEach((pt, i) => {
                        pt.projecttemplatedetails.forEach((ptd, j) => {
                            if (
                                ptd.bqcodelibrary &&
                                ptd.bqcodelibrary.bqcode == viewbqcode &&
                                ptd.asterisk == viewasterisk
                            ) {
                                this.bqcodesfound.push({
                                    id: ptd.id,
                                    template: pt.label + " " + pt.subject,
                                    bqcode: viewbqcode,
                                });
                                if (found == 0) {
                                    t = JSON.parse(JSON.stringify(ptd));
                                    found = 1;
                                } else {
                                    found++;
                                }
                            }
                        });
                    });
                } else {
                    this.detailform.projectschedules.forEach((pt, i) => {
                        pt.projectscheduledetails.forEach((ptd, j) => {
                            if (
                                ptd.bqcodelibrary &&
                                ptd.bqcodelibrary.bqcode == viewbqcode &&
                                ptd.asterisk == viewasterisk
                            ) {
                                this.bqcodesfound.push({
                                    id: ptd.id,
                                    template: pt.mcname,
                                    bqcode: viewbqcode,
                                });
                                if (found == 0) {
                                    t = JSON.parse(JSON.stringify(ptd));
                                    found = 1;
                                } else {
                                    found++;
                                }
                            }
                        });
                    });
                }
                this.selectrow = t.id;

                if (found > 1) {
                    /*
                        this.$alert("There are " + found + " of same activities. it will go back to first of them.","",
                          {callback: () => {this.$nextTick(this.selectViewRow)}});
                        */
                    this.showbillbqcode = true;
                    this.$nextTick(this.makeDraggable);
                } else {
                    this.$nextTick(this.selectViewRow);
                }
            }else{
                 this.searchbqcode = "";
                this.searchastspeother = "";
            }
        },

        hidebillbqcode: function () {
            this.showbillbqcode = false;
            this.$nextTick(this.selectViewRow);
        },

        handleCurrentChange: function (val) {
            this.selectrow = val.id;
            this.$nextTick(this.selectViewRow);
        },
        selectViewRow: function () {
            //this.$refs.templatetable.setCurrentRow(5);
            //this.$refs.templatedetailtable.setCurrentRow(5);
            //try 20 time every 100 miliseconds until ref found
            function sleep(ms) {
                return new Promise((resolve) => setTimeout(resolve, ms));
            }

            if (this.selectrow) {
                window.setTimeout(() => {
                    //wait for some time until $ref is set
                    //var times = 0;
                    //while (this.$refs[this.selectrow] == null) {
                    //    sleep(100).then(() => {times++});
                    //    if (times > 20)
                    //        break;
                    //}
                    if (this.$refs[this.selectrow] != null) {
                        this.$refs[this.selectrow].focus();
                        this.$store.state.billbqcode = "";
                    }
                }, 500);
            }
            //this.$refs.status.focus();
        },

        drag_start(event) {
            var style = window.getComputedStyle(event.target, null);
            event.dataTransfer.setData(
                "text/plain",
                parseInt(style.getPropertyValue("left"), 10) -
                    event.clientX +
                    "," +
                    (parseInt(style.getPropertyValue("top"), 10) -
                        event.clientY)
            );
        },

        drag_over(event) {
            event.preventDefault();
            return false;
        },

        drop(event) {
            var offset = event.dataTransfer.getData("text/plain").split(",");
            var dm = document.getElementById("dragme");
            dm.style.left = event.clientX + parseInt(offset[0], 10) + "px";
            dm.style.top = event.clientY + parseInt(offset[1], 10) + "px";
            event.preventDefault();
            return false;
        },

        makeDraggable() {
            var dm = document.getElementById("dragme");
            if (dm) {
                dm.addEventListener("dragstart", this.drag_start, false);
                document.body.addEventListener(
                    "dragover",
                    this.drag_over,
                    false
                );
                document.body.addEventListener("drop", this.drop, false);
            }
        },

        getprojecttemplatedetails: function (ptd, row) {
            if(this.$refs.col1 != undefined && this.resizecolwidth.isaddedwidth == undefined){
                this.resizecolumnswidth(null, null, null, null);
                this.resizecolwidth.isaddedwidth = true;
            }
            if (row.projecttemplatedetails.length == 0) {
                this.addRow(row, false, {srno: 0}, -1);
            }
            if (this.searchbqcode) {
                row.isevent = undefined;
                ptd = ptd.filter((ptd1) => {
                    ptd1.bit = 0;
                    if (!ptd1.bqcodelibrary) {
                        return false;
                    }

                    if (!this.ppFiltersEnabled && this.ptDetailsFilteredIds.includes(ptd1.id)) {
                        return true;
                    }

                    /*
                    if (ptd1.bqcodelibrary.bqcode.toLowerCase().includes(this.searchbqcode.toLowerCase()) && !this.ptDetailsFilteredIds.includes(ptd1.id)) {
                        this.ptDetailsFilteredIds.push(ptd1.id);
                    }*/

                    return ptd1.bqcodelibrary.bqcode
                        .toLowerCase()
                        .includes(this.searchbqcode.toLowerCase());
                });
            }

            if (this.searchastspeother) {
                row.isevent = undefined;
                ptd = ptd.filter((ptd1) => {
                    var str =
                        "" + ptd1.asterisk + ptd1.specification + ptd1.other;
                    str = str.toLowerCase();

                    if (!this.ppFiltersEnabled && this.ptDetailsFilteredIds.includes(ptd1.id)) {
                        /*
                        if (this.searchbqcode && this.searchastspeother) {
                            if (ptd1.bqcodelibrary && ptd1.bqcodelibrary.bqcode && ptd1.bqcodelibrary.bqcode.toLowerCase().includes(this.searchbqcode.toLowerCase()) && 
                                str.includes(this.searchastspeother.toLowerCase())) {
                                return true;
                            } else if ((!ptd1.bqcodelibrary || !ptd1.bqcodelibrary.bqcode) && str.includes(this.searchastspeother.toLowerCase())) {
                                return true;
                            } else {
                                return false;
                            }
                        }*/

                        return true;
                    }

                    /*
                    if (str.includes(this.searchastspeother.toLowerCase()) && !this.ptDetailsFilteredIds.includes(ptd1.id)) {
                        this.ptDetailsFilteredIds.push(ptd1.id);
                    }*/

                    return str.includes(this.searchastspeother.toLowerCase());
                });
            }
            if (ptd.length <= 15) {
                row.start = 0;
                row.end = 15;
            }
            if (row.start == undefined && row.end == undefined) {
                row.start = 0;
                row.end = 15;
            }
            if (row.start < 0) {
                row.start = 0;
                row.end = 15;
            }
            if (ptd.length <= 10) {
              row.height = 51 * ptd.length;
            //   row.height = ptd.length == 1 ? 80 : 40 * (ptd.length + 1);
                // row.height = 50 * ptd.length <= 100 ? 120 : 50 * ptd.length;
            } else {
                row.height = 450;
            }
            var result = ptd.slice(row.start, row.end);
            return result;

        },

        getprojectscheduledetails: function (psd) {
            if (this.searchbqcode) {
                return psd.filter((psd1) => {
                    if (!psd1.bqcodelibrary) {
                        return false;
                    }
                    return psd1.bqcodelibrary.bqcode
                        .toLowerCase()
                        .includes(this.searchbqcode.toLowerCase());
                });
            } else {
                return psd;
            }
        },

        addbasetemplatetoproject: function() {
            let basetemplates = this.importcheck.missing.filter(x => x.selected);
            basetemplates.forEach(bpt => {
                bpt.projectid = this.detailform.id;
                bpt.projectsectionid = this.sectionid;
                bpt.measuredunit = bpt.unit;
            });
            this.confirmimportdialog = false;
            this.isaddtoproject = false;
            this.post("project/saveprojectbasetemplatelist", basetemplates).then(response => {
                this.$message({
                    showClose: true,
                    message: response.data,
                    type: "success",
                });
                this.refresh();
            });
        },

        checkmissingtemplate: function() {
            this.isaddtoproject = this.importcheck.missing.some(x => x.selected );
        },

        deleteTemplateOnLastRow: function(projecttemplate) {
            let ptindex = this.detailform.projecttemplates.findIndex(x => x.id == projecttemplate.id);
            this.detailform.projecttemplates.splice(ptindex, 1);

            return this.$http
            .post("project/deletetemplateonlastrow?ptid=" + projecttemplate.id, {})
            .then((response) => {
                this.$message({
                    showClose: true,
                    message: response.data,
                    type: "success",
                });
            })
            .catch((err) => {
                this.$bus.$emit("error", err);
            });
        },

        handleFilterChanges() {
            this.ptDetailsFilteredIds = [];
            this.search = this.ppFilters.searchTemplateName;
            this.searchsubject = this.ppFilters.searchSubject;
            this.searchbqcode = this.ppFilters.searchBQCode;
            this.searchastspeother = this.ppFilters.searchAstSpeOther;

            this.ppFiltersEnabled = true;
        },

        clearFiltersValue() {
            this.ppFilters.searchTemplateName = "";
            this.ppFilters.searchSubject = "";
            this.ppFilters.searchBQCode = "";
            this.ppFilters.searchAstSpeOther = "";
            this.search = "";
            this.searchsubject = "";
            this.searchbqcode = "";
            this.searchastspeother = "";
        },

        // adjustTAHeights() {
        //     this.$nextTick(() => {
        //         var ptdtableelements = document.querySelectorAll(`[id^="projecttemplatedetailtable"]`);
        //         const id1 = "project_project_table_specification";
        //         const id2 = "project_project_table_asterisk";
        //         const id3 = "project_project_table_endnotes";

        //             for (let index = 0; index < ptdtableelements.length; index++) {
        //                 const ptdtelement = ptdtableelements[index];
        //                 const textareaElements = ptdtelement.querySelectorAll(`[id^="${id1}"], [id^="${id2}"], [id^="${id3}"]`);

        //                 if (textareaElements.length == 1) {
        //                 let taelement = textareaElements[0];

        //                 if (itemindex !== -1) {
        //                     ptdtelement.style.height = 'auto';
        //                     ptdtelement.style.height = taelement.scrollHeight + 'px';
        //                 } else {
        //                     ptdtelement.style.height = '51px';
        //                 }
        //             }

        //             for (let i = 0; i < textareaElements.length; i++) {
        //                 const textareaElement = textareaElements[i];

        //                 if (itemindex !== -1) {
        //                     textareaElement.style.height = 'auto';
        //                     textareaElement.style.height = textareaElement.scrollHeight + 'px';
        //                 } else {
        //                     textareaElement.style.height = 'auto';
        //                 }
        //             }
        //         }
        //     });
        // },

        adjusttextareaheight(cellidvalue){
            var ptdtableelements = document.querySelectorAll(`[id^="projecttemplatedetailtable"]`);
            let itemindex = this.heightchangedcellids.indexOf(cellidvalue);
            
            for (let index = 0; index < ptdtableelements.length; index++) {
                const ptdtelement = ptdtableelements[index];
                const textareaElements = ptdtelement.querySelectorAll(`[id^="${cellidvalue}"]`);

                if (textareaElements.length == 1) {
                    let taelement = textareaElements[0];

                    if (itemindex !== -1) {
                        ptdtelement.style.height = 'auto';

                        if (taelement.scrollHeight <= 56) {
                            ptdtelement.style.height = 56 + 'px';
                        } else {
                            ptdtelement.style.height = taelement.scrollHeight + 'px';
                        }
                    } else {
                        ptdtelement.style.height = '56px';
                    }
                }

                for (let i = 0; i < textareaElements.length; i++) {
                    const textareaElement = textareaElements[i];

                    if (itemindex !== -1) {
                        textareaElement.style.height = 'auto';
                        textareaElement.style.height = textareaElement.scrollHeight + 'px';
                    } else {
                        textareaElement.style.height = 'auto';
                    }
                }
            }

            if (itemindex !== -1) {
                this.heightchangedcellids.splice(itemindex, 1);
            } else {
                this.heightchangedcellids.push(cellidvalue);
            }



            // if (row) {
            //     var cc = this.$refs[row.id];
                
            //     if (cc) {
            //         const textarea = cc.$el.querySelector('textarea');
            //         textarea.style.height = 'auto';
            //         textarea.style.height = textarea.scrollHeight + 'px';
                    
            //         if (ptrow.projecttemplatedetails == 1) {
            //             ptrow.height = textarea.scrollHeight;
            //         }
            //     }
            // }
            // for (let i = 0; i < array.length; i++) {
            //     const element = document.queryselect;
            //     if (element) {
                    
            //     }
            // }

            // var checkvalues = document.querySelectorAll(`[id^="project_project_table_asterisk"]`);
            // for (let index = 0; index < checkvalues.length; index++) {
            //     const element = checkvalues[index]; 
            //     element.style.color = "red";
            // }
                
            // this.getprojecttemplates.forEach(e => {
            //     e.projecttemplatedetails.forEach(eptd => {
            //         var cc = this.$refs[eptd.id];
                
            //         if (cc) {
            //             const textarea = cc.$el.querySelector('textarea');
            //             textarea.style.height = 'auto';
            //             textarea.style.height = textarea.scrollHeight + 'px';
            //         }   
            //     });
            // });

        },

        columnsASEWidth(colvalue) {
            if (this.$refs[colvalue] && this.$refs[colvalue].columnConfig) {
            let cc = this.$refs[colvalue].columnConfig;
            let col8width = 0;

            if (colvalue == "col7" && this.showacdbtn == false) {
                col8width = this.$refs.col8.columnConfig.width;
            }

            if (cc.width) {
                return cc.width + col8width;
            } else {
                return cc.realWidth + col8width;
                }
            }
        },


        multicelllocationrulesmatch(celldirections, direction, errors, datarow) {
            let count = 0;
            for (let i = 0; i < celldirections.length; i++) {
                let celldir = celldirections[i];
                let celldirectiondata = null;
                let cellvalue = null;
                
                if (celldir.cellname == "sr" && celldir.srdr) {
                    celldirectiondata = celldir.srdr;
                    cellvalue = datarow.srno;
                }

                else if (celldir.cellname == "wc" && celldir.wcdr) {
                    celldirectiondata = celldir.wcdr;
                    cellvalue = datarow.category;
                }

                else if (celldir.cellname == "bqcode" && celldir.cbqcodedr) {
                    celldirectiondata = celldir.cbqcodedr;
                    cellvalue = datarow.bqcodelibrary.bqcode;
                }

                else if (celldir.cellname == "dim" && celldir.dimdr) {
                    celldirectiondata = celldir.dimdr;
                    cellvalue = datarow.dim;
                }

                else if (celldir.cellname == "unit" && celldir.unitdr) {
                    celldirectiondata = celldir.unitdr;
                    cellvalue = datarow.unit;
                }

                else if (celldir.cellname == "specification" && celldir.specdr) {
                    celldirectiondata = celldir.specdr;
                    cellvalue = datarow.specification;
                }

                else if (celldir.cellname == "asterisk" && celldir.astdr) {
                    celldirectiondata = celldir.astdr;
                    cellvalue = datarow.asterisk;
                }

                else if (celldir.cellname == "endnotes" && celldir.enddr) {
                    celldirectiondata = celldir.enddr;
                    cellvalue = datarow.other
                }

                if (celldirectiondata) {
                    // const  dr = this.getformat(celldirectiondata);
                    let ddata = this.getCellValueAndRegexRules(celldirectiondata, cellvalue);
                    
                    if (cellvalue && this.$directionutility.directionvaluematch(ddata) == false) {
                        count++;
                    }
                }
            }

            if (direction.directioncelldetails.length == count) {
                errors.forEach(x => {
                    x.ismatchedrulecell = true;
                });
                return true;
            }
            else {
                return false;
            }
        },

        AstSpeEndInputChanges(row) {
            if (!this.ptDetailsFilteredIds.includes(row.id)) {
                this.ptDetailsFilteredIds.push(row.id)
            }

            if (this.ppFiltersEnabled == true) {
                this.ppFiltersEnabled = false;
            }
        },
    },

    beforeRouteLeave(to, from, next) {
        var answer = true;
        if (this.detailform.changed) {
            var answer = window.confirm(
                "Do you really want to leave? you have unsaved changes!"
            );
        }
        if (answer == true) {
            next();
            window.onbeforeunload = null;
        } else {
            next(false);
        }
        if (this.detailform.projecttemplates) {
            this.detailform.projecttemplates.forEach((x) => {
                if (this.$refs[x.id] != undefined) {
                    this.$refs[x.id].bodyWrapper.removeEventListener(
                        "scroll",
                        (parm) => {
                            return this.onScroll2(parm, x);
                        }
                    );
                }
            });
        }
        window.clearInterval(this.interval);
    },

    // watch: {
    //     getprojecttemplates(newData, oldData){
    //         this.$nextTick(() => {
    //             // Call the function to adjust the heights of textarea elements and containers
    //             this.changeheightstextareaandtable();
    //         });
    //     }
    // },

    computed: {
        getamosstring(){
            return function(detailform){
                 var str = "";
                 if(detailform && detailform.section){
                     if( detailform.section.amosbyloginname != null
                        && detailform.section.amosbyloginname != undefined){
                         str += "by" + " " +detailform.section.amosbyloginname;
                     }
                     if(detailform.section.amossavedtoprojecton != undefined &&
                        detailform.section.amossavedtoprojecton != null){

                          var dt = new Date(detailform.section.amossavedtoprojecton)
                             str += " " +  dt.toLocaleString();
                     }
                 }
                 return str;
            }
        },
        getxmlstring(){
            return function(detailform){
                var str = "";
                if(detailform && detailform.section){
                    if( detailform.section.xmlbyloginname != null && detailform.section.xmlbyloginname != undefined){
                        str += "by" + " " + detailform.section.xmlbyloginname;
                    }
                    if((detailform.section.lastimportfilename != null && detailform.section.lastimportfilename != undefined) ||
                       (detailform.section.xmlimportedon != null && detailform.section.xmlimportedon != undefined)){
                           var t = detailform.section.lastimportfilename == null ? "" : detailform.section.lastimportfilename;
                        str += " " + t + " ";
                         var dt = new Date(detailform.section.xmlimportedon)
                         str += " " +  dt.toLocaleString();
                    }
                }
                return str;
            }
        },
        getprojecttemplates: function () {
            /*
            if (this.ppFiltersEnabled == true) {
                this.ppFiltersEnabled = false;
            } else{
                this.search = "";
                this.searchsubject = "";
                this.searchbqcode = "";
                this.searchastspeother = "";
            }
            */

            if (this.$route.params.bqcodeusageproject != undefined) {
                this.searchbqcode = this.$route.params.bqcodeusageproject;
                this.$route.params.bqcodeusageproject = undefined;
            }
            if (!this.detailform.projecttemplates) return [];

            var totalpt = [];
            totalpt = this.detailform.projecttemplates.filter((pt) => {
                var show = true;
                pt.isevent = undefined;
                if (this.search) {
                    show = pt.label
                        .toLowerCase()
                        .includes(this.search.toLowerCase());
                }
                if (show && this.amosxml != "all") {
                    show = (this.amosxml == 'amos' && pt.origin == 1) ||
                           (this.amosxml == 'xml' && pt.origin == 2);

                    /*
                    show = pt.origin
                        .toLowerCase()
                        .includes(this.amosxml.toLowerCase());
                    */
                }
                if (show && this.completept != "all") {
                    if (this.completept)
                        show = pt.readytobill == this.completept;
                    else show = pt.readytobill == this.completept;
                }
                if (show && this.searchbqcode) {
                    show =
                        this.getprojecttemplatedetails(
                            pt.projecttemplatedetails,
                            pt
                        ).length > 0;
                }
                if (show && this.searchsubject) {
                    if (pt.subject != null) {
                        show = pt.subject
                            .toLowerCase()
                            .includes(this.searchsubject.toLowerCase());
                    } else {
                        show = false;
                    }
                }
                if (show && this.searchastspeother) {
                    show =
                        this.getprojecttemplatedetails(
                            pt.projecttemplatedetails,
                            pt
                        ).length > 0;
                }
                return show;
            });
            this.total = totalpt.length;
            this.total == 0 ? this.istemplateheader = false : this.istemplateheader = true;
            if (this.total == 0) {
                return [];
            }
            if (!(Math.ceil(this.total / this.pagesize) >= this.page)) {
                this.page = Math.ceil(this.total / this.pagesize);
            }
            return totalpt.slice(
                this.pagesize * this.page - this.pagesize,
                this.pagesize * this.page
            );
        },

        getprojectschedules: function () {
            if (!this.detailform.projectschedules) return [];
            return this.detailform.projectschedules.filter((pt) => {
                this.istemplateheader = false;
                var show = true;
                if (this.search) {
                    show = pt.mcname
                        .toLowerCase()
                        .includes(this.search.toLowerCase());
                }
                if (show) {
                    show =
                        this.getprojectscheduledetails(
                            pt.projectscheduledetails
                        ).length > 0;
                }
                return show;
            });
        },
    },

   async mounted() {
      var thisVue = this;
    await thisVue.$signalr.client.on('ReceiveSectionOpen', function(obj) {
          if(obj.sectionid == thisVue.sectionid){
             obj.userid = thisVue.user.id;
             obj.username = thisVue.user.fullname;
             thisVue.miSectionList = [];
             thisVue.otherSectionList = [];
            return thisVue.$signalr.client.invoke('SentSectioMatchOpen',obj).then((data)=>{}).catch(function (err) {
                    return console.error(err.toSting());
                });
          }
       });
       await thisVue.$signalr.client.on('ReceiveSectioMatchOpen', function(obj) {
        if(obj.sectionid == thisVue.sectionid){
            if(obj.userid == thisVue.user.id){
                if(thisVue.miSectionList.length == 1){
                     thisVue.$nextTick(()=>{
                         if(thisVue.warningdilog == true ){
                                 thisVue.warningdilogstring +=  " </br> </br> You opened this section already in another browser window.";

                         }else{
                                 thisVue.warningdilogstring +=  "You opened this section already in another browser window.";
                                 thisVue.warningdilog = true;
                                 thisVue.iseditornot = false;
                         }
                         window.clearInterval(thisVue.interval);
                     });
                }
                thisVue.miSectionList.push(obj);
            }
               if(obj.userid != thisVue.user.id){
                 var t = thisVue.otherSectionList.find(x=> x.userid == obj.userid && x.sectionid == obj.sectionid);
                 if(!t){
                   thisVue.$nextTick(()=>{
                       if(thisVue.warningdilog == true){
                               thisVue.warningdilogstring += " </br> </br> "+ "<b>"+ obj.username +'</b> working on this section now, you will access this section but will not be able to make any edits.';

                       }else{
                               thisVue.warningdilogstring += "<b>" + obj.username +'</b> working on this section now, you will access this section but will not be able to make any edits.';
                               thisVue.warningdilog = true;
                               thisVue.iseditornot = false;
                       }
                        window.clearInterval(thisVue.interval);
                        thisVue.otherSectionList.push(obj);
                   });
                  }
               }
            }
       });


        // Change project page filters append search button
        const ppFiltersSearchBtn = document.querySelectorAll('.el-input-group__append, .el-input-group__prepend');
        ppFiltersSearchBtn.forEach(element => {
            element.style.padding = '0px 12px';
       });
    },
 destroyed() {
    this.sectionid = null;
   },
   async created() {
       this.issave = false;
       this.$bus.$on("setuser", (user) => {
           this.user = user;
        });
        const params = new URLSearchParams(window.location.search);
        if (params.size == 3) {
            this.$training.getprojectusingparam(params);
        }
        if (
            window.File &&
            window.FileReader &&
            window.FileList &&
            window.Blob
        ) {
            // Great success! All the File APIs are supported.
        } else {
            alert("The File APIs are not fully supported in this browser.");
        }
        //window.addEventListener('beforeunload', this.beforeunload);
        window.onbeforeunload = this.beforewindowunload;
        //setInterval(this.autosave, 10 * 60000);
        this.interval = window.setInterval(this.autosave, 10 * 60000);

        // window.addEventListener('resize', this.setprjpageheight);
    },
    components: {
        projecttemplate: projecttemplate,
        projectbqcode: projectbqcode,
        projectselectpart: projectselectpart,
        customdialog: customdialogbox,
        intimechecklist:intimechecklist,
        CustomPageDraggable:CustomPageDraggable,
        rfientryform:rfientryform,
        displaydropboxmedia: displaydropboxmedia,
    },
};
</script>

<style itemscope>
.editedcolumncolor {
    border-bottom: 4px solid rgba(0, 100, 255, 0.25) !important;
}

/* .lightblue {
    border-bottom: 4px solid rgba(0, 100, 255, 0.25) !important;
    background-color: rgba(0, 100, 255, 0.25) !important;
} */

.el-table .cell {
    /*make more space for textboxes inside el-table*/
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.red {
    color: red;
}

.green {
    color: green;
}

.blue {
    color: blue;
}

.centermiddle {
    position: fixed;
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%);
    z-index: 5;
    border: 1px solid grey;
}

.el-input-number--mini {
    width: 100%;
}

.el-input-number .el-input__inner {
    text-align: right;
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 0px;
}

.el-form-item {
    margin-bottom: 0px;
}

/*Remove the bottom border*/
.el-table::before {
    height: 0;
}

.el-row {
    margin-bottom: 0px;
}

.amositalic {
    font-style: italic;
    color: red;
}


.acdhideshowbtnstyle {
    padding: 4px 7px !important; 
    border-width: 1px !important; 
    font-weight: bold !important;
}
</style>
